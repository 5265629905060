.paragraphe,
ul > li {
  /* font-size: 13.5pt !important; */
  color: black !important;
}

address {
  font-size: 13.5pt !important;
  font-family: Roboto !important;
  color: black !important;
}

b {
  margin-top: 5vh !important;
}
