.accordion {
    
    margin-bottom: 0px;
    padding-inline: 10px;
  }
  
  .accordion-header {
    border-top: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer;
    background-color: #f0f0f0;
  }
  
  .accordion-header h1 {
    margin: 0;
    font-size: medium;
    font-weight: 500;
  }
  
  .accordion-content {
    padding-block: 10px;
    /* background-color: #ffffffa8; */
  }
  