

.tableau {
    width: 80%;
    margin: 20px auto;
    border-collapse: collapse;
}

.lignes, .colonnes {
    border: 1px solid #000;
    padding: 8px;
    text-align: center;
}

.lignes {
    background-color: #f2f2f2;
}

/* tfoot td {
    font-weight: bold;
} */

.s20{
    text-align: right;
    align-content: end;
}

.lh1{
    line-height: 1px;
}
