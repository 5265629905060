
.boutton,
.boutton:hover {
    background-color: #c3c3c3 !important;
    font-size: 14px;
    border-width: 2px !important;
    /* padding-inline: 25px;
    padding-block: 10px; */
    border-radius: 8px !important;
    /* color:#fff; */
}

.selection {
    font-size: 14px !important;
    font-weight: normal !important;
}