@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: 'Calibri';
  src: local('Calibri'),
       url("./assets/fonts/calibri/calibri-regular.ttf"),
       url("./assets/fonts/calibri/calibri-bold.ttf"),
       url("./assets/fonts/calibri/calibri-italic.ttf");
}


body{
  font-family: Arial, Helvetica, sans-serif !important;
  font-size : 12px !important;
}

.font-calibri {
  font-family: "Calibri" !important;
}

.textearea {
  resize: none !important;
}

.z-9999 {
  z-index: 9999 !important;
}

#affiche {
  display: inline-flex !important;
  width: 100%;
}

.Toastify__toast-body {
  font-size: 0.75rem !important;
}

td {
  padding: 3px !important;
  width: auto !important;
  white-space: normal !important;
  /* Permet au contenu de se casser sur plusieurs lignes */
  word-wrap: break-word !important;
  /* Permet le retour à la ligne des mots longs */
}

/* th {
      text-align: center !important;
      justify-content: center;
      column-width: fit-content !important;
  } */

/* Style pour la pagination */

/* Style pour les points de suspension */
.break-me {
  cursor: default;
}



.pointer:hover {
  cursor: pointer;
}

input[type='text'],
input[type='email'],
input[type='number'],
input[type='search'],
input[type='password'],
input[placeholder="VILLE *"],
input[type='type'],
input[type='date'],
input[type='time']
/* select,
.css-b62m3t-container  */
{
  /* border-color: black !important; */
  border-width: 1px !important;
  border-radius: 10px
}

.tableRow {
  border-bottom: 1px solid black !important
}

table {
  /* add uppercase style */
  text-transform: uppercase !important;
}

.DateColor {
  color: #40E0D0 !important
}


.titre-detail,
.data-detail {
  margin-bottom: 5px;
  text-transform: uppercase;
}

.titre-detail {
  color: #000;
  font-weight: 600;
  font-size: 13px;
}

.data-detail {
  font-weight: 400;
  font-size: 14px;
}

.sidebar-menu>ul>li>a {
  padding: 10px 20px !important;

}


.account-box,.auth-bg {
  background: linear-gradient(51.64deg, rgb(255 255 255 ) 34.2%, rgba(255, 255, 255, 0.5) 97.35%, rgba(255, 255, 255, 0.5) 160.66%), rgba(255, 255, 255, 0.5) !important;
  box-shadow: 0 4px 9px #e1ecff, inset 0 0 15px rgba(255, 255, 255, .35) !important;

}


.form-controle {
  height: 35px !important;
  border-radius: 8px !important;
  border: 0.5px !important;
  border: 1px solid #c3c3c3 !important;
  padding-block: 10px !important;
  width: 100% !important;
}

.form-controle-error {
  height: 35px !important;
  border-radius: 8px !important;
  border: 0.5px !important;
  border: 1px solid red !important;
  padding-block: 10px !important;
  width: 100% !important;
}

.react-tel-input{
  border-radius: 8px !important;
}

.filepond--credits{
  display:none !important;
}
.form-label {
  text-transform: uppercase !important;
}

.css-3w2yfm-ValueContainer{
  max-height: 100px;
  overflow-y: scroll !important;
}

#header-container #header {
  width: 100%!important;
}

#footer-container {
  bottom: 100px;
}

 #header {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    margin-top: 250px;
    padding-inline: 25px;
}

#total_course {
  width: 80%!important;
}

#total_course_value {
  width: 19.5%!important;
}

 #main-container {
  margin-top: 80px !important;
}

#bbc {
  width: 100%!important;
  display: flex!important;
  justify-content: space-between!important;
}

#bbc .metadata-table {
  min-width: 30%!important;
}