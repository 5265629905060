/* @import '../../../../../../css2-1'; */

html {
    height: 100%
}

body {
    zoom: 90%;
    font-family: source sans pro, sans-serif;
    font-size: 10px;
    color: #171717;
    height: 100%;
    background-image: url(../img/crypto-bg.jpg);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    z-index: 1
}

#root, .main-wrapper, .page-wrapper{
    height: 100%;
}

.sidebar-inner, .slimScrollDiv{
    height: 100% !important;
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0
}

h1, .h1 {
    font-size: 1.50rem
}

h2, .h2 {
    font-size: 1.25rem
}

h3, .h3 {
    font-size: 1rem
}

h4, .h4 {
    font-size: .75rem
}

h5, .h5 {
    font-size: .50rem
}

h6, .h6 {
    font-size: .25rem
}

li {
    list-style: none
}

/* a:hover, a:active, a:focus {
    text-decoration: none;
    color: #7815c6;
    outline: none
} */

.form-control {
    box-shadow: none;
    padding: .469rem .75rem;
    font-size: 14px;
    min-height: 40px;
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), rgba(255, 255, 255, 0.2);
    border: 2px solid #fff;
    border-radius: 8px
}

.form-control:focus {
    border-color: #444;
    box-shadow: none;
    outline: 0
}

a {
    color: #444;
    font-weight: 600;
    text-decoration: none
}

input, button, a {
    transition: all .4s ease;
    -moz-transition: all .4s ease;
    -o-transition: all .4s ease;
    -ms-transition: all .4s ease;
    -webkit-transition: all .4s ease
}

input, button {
    outline: none
}

input[type=file] {
    height: auto;
    padding: 6px .75rem
}

/* input[type=text], input[type=password] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
} */

/* textarea.form-control {
    resize: vertical
} */

.navbar-nav > li {
    float: left
}

.form-group {
    margin-bottom: 20px
}

.display-block {
    display: block
}

.btn.rounded {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .1)
}

.btn.focus, .btn:focus {
    box-shadow: inherit !important
}

.list-group-item {
    border: 1px solid #eaeaea;
    background: rgba(255, 255, 255, .4)
}

.table {
    color: #000;
    border-top: 0
}

.table.table-white {
    background-color: #fff
}

.table > tbody > tr > td {
    font-weight: 300
}

.table-striped > tbody > tr:nth-of-type(2n+1) {
    background-color: #f6f6f6
}

table.table td .avatar {
    margin: 0 5px 0 0
}

table.table td h2 {
    display: inline-block;
    font-size: inherit;
    font-weight: 400;
    margin: 0;
    padding: 0;
    vertical-align: middle
}

table.table td h2 a {
    color: #757575
}

table.table td h2 a:hover {
    color: #444
}

table.table td h2 span {
    color: #9e9e9e;
    display: block;
    font-size: 12px;
    margin-top: 3px
}

.graph-price i {
    margin-right: 10px;
    color: #444;
    font-size: 26px;
    background: -moz-linear-gradient( to top, #444 0%, #6713d2 100%);
    background: -webkit-linear-gradient( to top, #444 0%, #6713d2 100%);
    background-image: linear-gradient(to right, #444 0%, #6713d2 100%);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent
}

#chartdiv {
    width: 100%;
    max-height: 500px;
    height: 100vh
}

#chartdiv1 {
    width: 100%;
    max-height: 370px;
    height: 100vh;
    background: #fff
}

.amChartsPeriodSelector {
    display: none
}

.amChartsDataSetSelector {
    display: none
}

.m-r-5 {
    margin-right: 5px !important
}

.m-r-10 {
    margin-right: 10px !important
}

.m-l-5 {
    margin-left: 5px !important
}

.m-t-0 {
    margin-top: 0 !important
}

.m-t-10 {
    margin-top: 10px !important
}

.m-t-20 {
    margin-top: 20px !important
}

.m-t-30 {
    margin-top: 30px !important
}

.m-t-50 {
    margin-top: 50px !important
}

.m-b-10 {
    margin-bottom: 10px !important
}

.m-b-20 {
    margin-bottom: 20px !important
}

.m-b-30 {
    margin-bottom: 30px !important
}

.w-40 {
    width: 40px
}

.block {
    display: block !important
}

.text-ellipsis {
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.text-muted-light {
    color: #aaa
}

.card-box {
    margin-bottom: 30px;
    padding: 20px;
    position: relative;
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 9px #e1ecff, inset 0 0 15px rgb(255 255 255/35%);
    border-radius: 5px
}

.card-title {
    color: #333;
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: 600;
    text-transform: capitalize
}

.page-title {
    color: #35446f;
    font-size: 21px;
    font-weight: 700;
    margin-bottom: 30px
}

.page-sub-title {
    color: #565656;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 20px
}

.cal-icon:after {
    background: transparent url(../img/calander.png) no-repeat scroll 0 0;
    bottom: 0;
    content: "";
    display: block;
    height: 19px;
    margin: auto;
    position: absolute;
    right: 15px;
    top: 0;
    width: 17px
}

.cal-icon {
    position: relative;
    width: 100%
}

.time-icon:after {
    background: transparent url(../img/clock.png) no-repeat scroll 0 0;
    bottom: 0;
    content: "";
    display: block;
    height: 19px;
    margin: auto;
    position: absolute;
    right: 15px;
    top: 0;
    width: 19px
}

.time-icon {
    position: relative;
    width: 100%
}

.badge-success-border {
    border: 1px solid #7014cc;
    color: #7014cc;
    background-color: #fff;
    display: inline-block;
    min-width: 80px
}

.badge-danger-border {
    border: 1px solid #f62d51;
    color: #f62d51;
    background-color: #fff;
    display: inline-block;
    min-width: 80px
}

.badge-warning-border {
    border: 1px solid #ffbc34;
    color: #ffbc34;
    background-color: #fff;
    display: inline-block;
    min-width: 80px
}

.badge-info-border {
    border: 1px solid #444;
    color: #444;
    background-color: #fff;
    display: inline-block;
    min-width: 80px
}

.modal-footer.text-start {
    text-align: left
}

.modal-footer.text-center {
    text-align: center
}

.font-18 {
    font-size: 18px
}

.border-right {
    border-right: 1px solid #e7e7e7
}

.blur {
    -webkit-filter: blur(3px);
    -moz-filter: blur(3px);
    -o-filter: blur(3px);
    -ms-filter: blur(3px);
    filter: blur(3px)
}

.btn {
    font-size: .875rem;
    margin-bottom: 10px
}

.btn-white {
    background-color: #fff;
    border: 1px solid #ccc;
    color: #333
}

.rounded, .circle {
    border-radius: 500px
}

.bg-primary, .badge-primary {
    background-color: #444 !important
}

.bg-success, .badge-success {
    background-color: #2750e4 !important
}

.bg-info, .badge-info {
    background-color: #00d3c7 !important
}

.bg-warning, .badge-warning {
    background-color: #ffbc34 !important
}

.bg-danger, .badge-danger {
    background-color: #f62d51 !important
}

.bg-white {
    background-color: #fff
}

.text-primary, .dropdown-menu > li > a.text-primary {
    color: #00b2ff !important
}

.text-success, .dropdown-menu > li > a.text-success {
    color: #7014cc
}

.text-danger, .dropdown-menu > li > a.text-danger {
    color: #fe3f51
}

.text-info, .dropdown-menu > li > a.text-info {
    color: #444
}

.text-warning, .dropdown-menu > li > a.text-warning {
    color: #ffbc34
}

.text-purple, .dropdown-menu > li > a.text-purple {
    color: #7460ee
}

.btn-primary {
    border-color: transparent;
    background-color: #444;
    border: 1px solid transparent
}

.btn-primary:hover, .btn-primary:focus, .btn-primary.active, .btn-primary:active, .open > .dropdown-toggle.btn-primary {
    background-color: #fe3f51;
    border: 1px solid #fe3f51;
    color: #fff
}

.btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary.focus:active, .btn-primary:active:focus, .btn-primary:active:hover, .open > .dropdown-toggle.btn-primary.focus, .open > .dropdown-toggle.btn-primary:focus, .open > .dropdown-toggle.btn-primary:hover {
    background-color: #444;
    border: 1px solid #444
}

.btn-primary.active:not(:disabled):not(.disabled), .btn-primary:active:not(:disabled):not(.disabled), .show > .btn-primary.dropdown-toggle {
    background-color: #1837a9;
    border-color: #1837a9;
    color: #fff
}

.btn-outline-primary {
    border-color: #444;
    color: #444
}

.btn-outline-primary:hover {
    background-color: #444;
    border-color: #444;
    color: #fff
}

.btn-outline-primary.active:not(:disabled):not(.disabled), .btn-outline-primary:active:not(:disabled):not(.disabled), .show > .btn-outline-primary.dropdown-toggle {
    background-color: #444;
    border-color: #444;
    color: #fff
}

.btn-success {
    background-color: #444;
    border: 1px solid #444
}

.btn-success:hover, .btn-success:focus, .btn-success.active, .btn-success:active, .open > .dropdown-toggle.btn-success {
    background: #fe3f51;
    border: 1px solid #fe3f51
}

.btn-success.active.focus, .btn-success.active:focus, .btn-success.active:hover, .btn-success.focus:active, .btn-success:active:focus, .btn-success:active:hover, .open > .dropdown-toggle.btn-success.focus, .open > .dropdown-toggle.btn-success:focus, .open > .dropdown-toggle.btn-success:hover {
    background: #aa1ca5 !important;
    border: 1px solid #aa1ca5 !important
}

.btn-info {
    background-color: #444;
    border: 1px solid #444
}

.btn-info:hover, .btn-info:focus, .btn-info.active, .btn-info:active, .open > .dropdown-toggle.btn-info {
    background-color: #028ee1;
    border: 1px solid #028ee1
}

.btn-info.active.focus, .btn-info.active:focus, .btn-info.active:hover, .btn-info.focus:active, .btn-info:active:focus, .btn-info:active:hover, .open > .dropdown-toggle.btn-info.focus, .open > .dropdown-toggle.btn-info:focus, .open > .dropdown-toggle.btn-info:hover {
    background-color: #028ee1;
    border: 1px solid #028ee1
}

.btn-warning {
    background: #ffbc34;
    border: 1px solid #ffbc34
}

.btn-warning:hover, .btn-warning:focus, .btn-warning.active, .btn-warning:active, .open > .dropdown-toggle.btn-warning {
    background: #e9ab2e;
    border: 1px solid #e9ab2e
}

.btn-warning.active.focus, .btn-warning.active:focus, .btn-warning.active:hover, .btn-warning.focus:active, .btn-warning:active:focus, .btn-warning:active:hover, .open > .dropdown-toggle.btn-warning.focus, .open > .dropdown-toggle.btn-warning:focus, .open > .dropdown-toggle.btn-warning:hover {
    background: #e9ab2e;
    border: 1px solid #e9ab2e
}

.btn-danger {
    background: #f62d51;
    border: 1px solid #f62d51
}

.btn-danger:hover, .btn-danger:focus {
    background-color: #e6294b;
    border: 1px solid #e6294b
}

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
    /* background-color: #444; */
    border-color: #444
}

.pagination > li > a, .pagination > li > span {
    /* color: #444; */
    padding: .5rem .75rem !important
}

.page-item.active .page-link {
    background-color: #35446f;
    border-color: #35446f
}

.dropdown-menu {
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 3px;
    transform-origin: left top 0;
    box-shadow: inherit;
    background-color: #fff
}

.navbar-nav .open .dropdown-menu {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .1)
}

.btn-group-xs > .btn, .btn-xs {
    min-height: 22px;
    min-width: 22px
}

.dropdown-menu {
    font-size: 13px
}

.btn-link, .btn-link:hover, .btn-link:focus, .btn-link:active {
    box-shadow: none
}

.btn-link {
    color: #333
}

.nav .open > a, .nav .open > a:focus, .nav .open > a:hover {
    background-color: rgba(0, 0, 0, .2)
}

.card {
    margin-bottom: 30px;
    border: 0;
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 9px #e1ecff, inset 0 0 15px rgba(255, 255, 255, .35);
    border-radius: 5px
}

.card-title {
    margin-bottom: 1.125rem
}

.dropdown-action .dropdown-toggle:after {
    display: none
}

.badge.badge-pill {
    border-radius: 10rem
}

.badge-primary {
    color: #fff
}

.btn.btn-rounded {
    border-radius: 50px
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    background-color: #eee;
    border-color: transparent
}

.nav-tabs .nav-link {
    border-radius: 0
}

.table.dataTable {
    border-collapse: collapse !important
}

/* textarea.form-control {
    height: auto
} */

.progress-example .progress {
    margin-bottom: 1.5rem
}

.progress-xs {
    height: 4px
}

.progress-sm {
    height: 15px
}

.progress.progress-sm {
    height: 6px
}

.progress.progress-md {
    height: 8px
}

.progress.progress-lg {
    height: 18px
}

.form-control.form-control-sm {
    padding: .25rem .9rem
}

.card .card-header {
    background-color: rgba(255, 255, 255, .1);
    border: 0;
    font-size: 16px;
    line-height: 30px;
    padding: 15px
}

.card .card-header .card-title {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    padding: 5px 0
}

.table h5 {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 0;
    vertical-align: middle
}

.table h5 + p {
    color: #9e9e9e;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 0
}

.page-link:focus {
    box-shadow: unset
}

.header {
    background: #f0f5ff;
    box-shadow: 0 4px 9px #e1ecff, inset 0 0 15px rgba(255, 255, 255, .35);
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1039;
    height: 60px;
    padding: 5px 0
}

.header .header-left {
    float: left;
    height: 50px;
    padding: 0 20px;
    position: relative;
    text-align: center;
    width: 250px;
    z-index: 1
}

.logo {
    align-items: center;
    display: flex
}

.logo img {
    max-width: 130px
}

.logo span {
    color: #161616;
    font-size: 24px;
    font-weight: 700;
    margin-left: 10px;
    text-transform: uppercase
}

.header .navbar-nav .badge {
    position: absolute;
    right: 7px;
    top: 4px
}

.header .dropdown-menu > li > a {
    position: relative
}

.header .dropdown-toggle:after {
    display: none
}

.header .has-arrow .dropdown-toggle:after {
    border-top: 0;
    border-left: 0;
    border-bottom: 2px solid #171717;
    border-right: 2px solid #171717;
    content: '';
    height: 8px;
    display: inline-block;
    pointer-events: none;
    -webkit-transform-origin: 66% 66%;
    -ms-transform-origin: 66% 66%;
    transform-origin: 66% 66%;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all .15s ease-in-out;
    transition: all .15s ease-in-out;
    width: 8px;
    vertical-align: 0
}

.page-title-box {
    border-radius: 0;
    height: 50px;
    margin-bottom: 0;
    padding: 14px 20px
}

.page-title-box h3 {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    margin: 0
}

.user-menu {
    margin: 0;
    position: relative;
    z-index: 99;
}

.user-menu.nav > li > a {
    color: #fff;
    font-size: 16px;
    padding: 0;
    height: 50px;
    line-height: 1
}

.user-menu.nav > li > a:hover, .user-menu.nav > li > a:focus {
    background-color: transparent
}

.user-img {
    display: inline-block;
    position: relative;
    width: 24px;
}

.header .user-img img {
    width: 33px
}

.user-img .status {
    border: 2px solid #fff;
    bottom: 0;
    height: 10px;
    position: absolute;
    right: 0;
    width: 10px
}

.user-menu .user-img .status {
    bottom: 0
}

.user-menu .dropdown-menu {
    min-width: 136px;
    padding: 0;
    visibility: hidden;
    opacity: 0;
    display: block;
    transform: translate(0px, 100px)
}

.user-menu .dropdown-menu.show {
    visibility: visible;
    opacity: 1;
    transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -webkit-transition: all .5s ease
}

.user-menu .bg-primary {
    background-color: #444 !important
}

.status {
    background-color: #ccc;
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    width: 10px
}

.status.online {
    background-color: #0fe40a
}

.status.offline {
    background-color: #f62d51
}

.status.away {
    background-color: #faa937
}

.user-profile-list .user-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 10px !important
}

.user-names {
    padding-left: 10px
}

.user-names h5 {
    color: #000;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0
}

.user-names span {
    color: #ff808c;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0
}

.bg-box {
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), rgba(255, 255, 255, 0.2);
    border: 1px solid #fff;
    border-radius: 8px;
    width: 40px;
    height: 40px !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center
}

.user-menu-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.user-menu.nav .exchange-btn .btn-primary {
    background: #00b2ff;
    border-radius: 8px;
    height: inherit;
    padding: 11px 15px;
    min-width: 164px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400
}

.user-menu.nav .exchange-btn .btn-primary:focus {
    border: 1px solid #00b2ff
}

.user-menu.nav .exchange-btn .btn-primary:hover {
    background: #0d96d2;
    border: 1px solid #0d96d2
}

.user-menu.nav li {
    margin-right: 12px;
    position: relative
}

.top-liv-search .btn {
    background-color: transparent;
    border-color: transparent;
    color: rgba(17, 17, 17, .32);
    min-height: 40px;
    padding: 7px 15px;
    position: absolute;
    right: 0;
    top: 0;
    margin-bottom: 0
}

.top-liv-search .form-control {
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), rgba(255, 255, 255, 0.2);
    border: 2px solid #fff;
    border-radius: 8px
}

.live-dark {
    background: rgba(254, 63, 81, .1);
    width: 12px;
    height: 12px;
    border-radius: 50px;
    position: relative;
    margin-left: 6px
}

.live-dark:before {
    content: "";
    position: absolute;
    width: 3px;
    height: 3px;
    background: #fe3f51;
    border-radius: 50px;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.live-grp h5 {
    color: #171717;
    font-size: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0
}

.header-nav-blk {
    display: inline-block;
    margin-left: 30px
}

.header-nav-blk h4 {
    margin-bottom: 0;
    color: #000;
    font-size: 15px;
    font-weight: 700
}

.header-nav-blk span {
    margin-bottom: 0;
    color: #818181;
    font-size: 14px;
    font-weight: 400
}

.sidebar {
    top: 80px;
    width: 240px;
    z-index: 1039;
    bottom: 0;
    margin-top: 0;
    position: fixed;
    left: 20px;
    transition: all .2s ease-in-out;
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 9px #e1ecff, inset 0 0 15px rgba(255, 255, 255, .35);
}

.sidebar.opened {
    -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    transition: all .4s ease
}

.sidebar-inner {
    height: 100%;
    transition: all .2s ease-in-out 0s
}

.sidebar-menu .submenu.active-bg {
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 9px #e1ecff, inset 0 0 15px rgba(255, 255, 255, .35);
    border-radius: 8px
}

.sidebar-menu ul {
    font-size: 14px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin: 0 10px;
    padding-top: 20px
}

.sidebar-menu li a {
    color: #171717;
    display: block;
    font-size: 15px;
    height: auto;
    padding: 0 20px
}

.sidebar-menu li a:hover {
    color: #444
}

.sidebar-menu li.active a {
    color: #444;
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 9px #e1ecff, inset 0 0 15px rgba(255, 255, 255, .35);
    border-radius: 8px
}

/* .sidebar-menu li.active a img {
    filter: invert(13%) sepia(85%) saturate(7478%) hue-rotate(266deg) brightness(76%) contrast(340%)
} */

.sidebar-menu li a img {
    transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -webkit-transition: all .5s ease
}

.sidebar-menu li:hover a img {
    /* filter: invert(13%) sepia(85%) saturate(7478%) hue-rotate(266deg) brightness(76%) contrast(340%); */
    transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -webkit-transition: all .5s ease
}

.menu-title {
    color: #818181;
    font-size: 15px;
    font-weight: 500;
    padding: 12px 20px
}

.menu-title > i {
    float: right;
    line-height: 40px
}

.sidebar-menu li.menu-title a {
    color: #444;
    display: inline-block;
    float: right;
    padding: 0
}

.sidebar-menu li.menu-title a.btn {
    color: #fff;
    display: block;
    float: none;
    font-size: 15px;
    line-height: inherit;
    margin-bottom: 15px
}

.sidebar-menu ul ul a.active {
    color: #444;
    text-decoration: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.sidebar-menu ul ul a i {
    margin-right: 10px
}

.mobile-user-menu {
    color: #fff;
    display: none;
    font-size: 24px;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
    width: 50px;
    z-index: 10
}

.mobile-user-menu > a {
    color: #171717;
    padding: 0
}

.mobile-user-menu > a:hover {
    color: #fff
}

.mobile-user-menu > .dropdown-menu > a {
    line-height: 23px
}

.profile-rightbar {
    display: none !important;
    color: #444;
    font-size: 26px;
    margin-left: 15px
}

.fixed-sidebar-right {
    position: fixed;
    top: 60px;
    right: 0;
    width: 300px;
    margin-right: -300px;
    bottom: 0;
    z-index: 101;
    -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    transition: all .4s ease
}

.mobile_btn {
    display: none
}

.sidebar .sidebar-menu > ul > li > a span {
    transition: all .2s ease-in-out 0s;
    display: inline-block;
    margin-left: 10px;
    white-space: nowrap
}

.sidebar .sidebar-menu > ul > li > a span.badge {
    color: #fff;
    margin-left: auto
}

.sidebar-menu ul ul a {
    display: block;
    font-size: 14px;
    padding: 9px 10px 9px 9px;
    position: relative;
    display: flex;
    align-items: center
}

.sidebar-menu ul ul {
    display: none;
    padding-top: 0
}

.sidebar-menu ul ul ul a {
    padding-left: 40px
}

.sidebar-menu ul ul ul ul a {
    padding-left: 90px
}

.sidebar-menu > ul > li {
    position: relative
}

.sidebar-menu .menu-arrow {
    -webkit-transition: -webkit-transform .15s;
    -o-transition: -o-transform .15s;
    transition: transform .15s;
    position: absolute;
    right: 15px;
    display: inline-block;
    font-family: 'font awesome 5 free';
    font-weight: 900;
    text-rendering: auto;
    line-height: 40px;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    line-height: 18px;
    top: 15px
}

.sidebar-menu .menu-arrow:before {
    content: "\f105"
}

.sidebar-menu li a.subdrop .menu-arrow {
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg)
}

.sidebar-menu ul ul a .menu-arrow {
    top: 12px
}

.sidebar-menu a {
    transition: unset;
    -moz-transition: unset;
    -o-transition: unset;
    -ms-transition: unset;
    -webkit-transition: unset
}

.sidebar-menu > ul > li > a {
    padding: 12px 20px 12px 18px;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    position: relative;
    transition: all .2s ease-in-out 0s
}

.mini-sidebar .sidebar-menu > ul > li > a {
    padding: 12px
}

.sidebar-menu ul li a i {
    align-items: center;
    display: inline-flex;
    font-size: 18px;
    min-height: 24px;
    line-height: 18px;
    width: 20px;
    transition: all .2s ease-in-out 0s
}

.sidebar-menu ul li.menu-title a i {
    font-size: 16px;
    margin-right: 0;
    text-align: right;
    width: auto
}

.track-portfolio {
    background: linear-gradient(321.86deg, #FE3F51 4.09%, #444 81.26%);
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    margin: 40px 20px 25px
}

.track-portfolio h4 {
    font-size: 16px;
    color: #fff;
    margin-bottom: 5px;
    font-weight: 700
}

.track-portfolio p {
    font-size: 12px;
    color: #fff;
    font-weight: 400
}

.track-portfolio .btn-primary {
    background: rgba(23, 23, 23, .1);
    border-radius: 100px;
    padding: 2px 24px;
    margin-bottom: 0;
    font-size: 12px
}

.track-portfolio .btn-primary:hover {
    background-color: #444;
    border: 1px solid #444
}

.need-btn {
    margin: 40px 20px 25px
}

.need-btn .btn-primary {
    width: 100%;
    padding: 11px 15px
}

.logout-btn {
    margin: 0 20px 25px
}

.logout-btn .btn-primary {
    width: 100%;
    padding: 11px 15px;
    background: #171717;
    border-radius: 5px;
    margin-bottom: 0
}

.logout-btn .btn-primary:hover {
    background: #434242;
    border: 1px solid #434242
}

/*  .select2-container .select2-selection--single */
.currency-list {
    border: 1px solid #fff
}

/* .select2-container--default .select2-selection--single .select2-selection__arrow */
.currency-list  {
    right: 4px;
    top: 0
}

.exchange-currency-bg .modal-content {
    background: rgba(255, 255, 255, .4);
    border: 1px solid #c5d9ff;
    box-shadow: 0 4px 44px rgba(208, 226, 255, .25);
    border-radius: 8px
}

.exchange-currency-bg .modal-header {
    padding: 0;
    border-bottom: 0
}

.exchange-currency-bg .btn-closes {
    position: absolute;
    z-index: 99;
    right: 20px;
    top: 10px;
    color: #fe3f51;
    cursor: pointer
}

.select2-container {
    z-index: 9999
}

.canvasjs-chart-credit {
    display: none !important
}

.page-wrapper > .content {
    padding: 30px;
    width: 100%
}

.exchange-form {
    padding: 15px
}

.crypto-exchange .card-title h4 {
    margin-bottom: 0;
    padding: 30px 30px 10px;
    font-size: 24px
}

.crypto-exchange .card-title {
    margin-bottom: 0
}

.crypto-exchange .card-title .page-title {
    padding: 15px
}

.crypto-exchange p {
    margin-bottom: 0;
    color: #818181;
    font-size: 14px
}

.page-wrapper {
    left: 0;
    margin-left: 280px;
    padding-top: 85px;
    position: relative;
    -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    transition: all .4s ease
}

.bg-wrapper::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0
}

.buy-form {
    padding: 15px;
    margin-bottom: 30px;
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 9px #e1ecff, inset 0 0 15px rgba(255, 255, 255, .35);
    border-radius: 5px;
    width: 100%
}

.ticker__viewport {
    background-color: rgba(255, 255, 255, .8);
    color: #333;
    overflow: hidden
}

.ticker__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex
}

.ticker__item {
    display: inline-block;
    white-space: nowrap;
    padding-right: 40px;
    padding-top: 10px;
    padding-bottom: 10px
}

.represh-btn {
    width: 23px;
    height: 23px;
    background: #0dbf0a;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    padding: 0;
    border-radius: 50px;
    margin: auto
}

.represh-btn:hover {
    background: #10970e;
    border: 1px solid #10970e
}

.represh-btn i {
    font-size: 11px
}

.watch-head h4 {
    margin-bottom: 0;
    color: #35446f;
    font-size: 20px;
    font-weight: 600
}

.watch-view-all .btn-primary {
    min-width: 100px;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 3px;
    color: #171717;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    padding: 4px 10px;
    margin-bottom: 0
}

.watch-view-all .btn-primary i {
    font-size: 10px
}

.activity-group {
    margin-top: 20px
}

.activity-awards {
    margin-bottom: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.award-boxs {
    background: #fff;
    width: 50px;
    height: 50px;
    border-radius: 8px;
    margin-right: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    filter: drop-shadow(0px 4px 4px rgba(200, 200, 200, 0.25))
}

.award-list-outs h4 {
    color: #171717;
    font-weight: 600;
    font-size: 14px
}

.award-list-outs h5 {
    color: #0dbf0a;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 0
}

.award-list-outs h5.recoust-low {
    color: #fe3f51
}

.award-time-list {
    margin-left: auto
}

.award-time-list span {
    color: #35446f;
    font-weight: 400;
    font-size: 16px
}

.border-watch {
    border-bottom: 1px solid #efecf1;
    padding-bottom: 20px
}

.custom-table thead tr {
    background: 0 0;
    border-top: 0;
    box-shadow: none
}

.custom-table thead tr th {
    color: #35446f;
    font-weight: 600
}

.credit-balance-group {
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 9px #e1ecff, inset 0 0 15px rgba(255, 255, 255, .35);
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.credit-balance-group.new-users {
    display: block
}

.credit-balance h4 {
    font-size: 14px;
    font-weight: 400;
    color: #35446f;
    margin-bottom: 5px
}

.credit-balance h2 {
    font-size: 24px;
    font-weight: 600;
    color: #171717;
    margin-bottom: 0
}

.credit-btn .btn-primary {
    min-width: 132px;
    padding: 10px 15px
}

.send-credit {
    background: #444
}

.receive-credit {
    background: #fe3f51;
    margin-bottom: 0
}

.credit-btn {
    text-align: right
}

.credit-balance {
    width: 100%
}

.market-amount {
    background: rgba(35, 76, 227, .03);
    border-radius: 3px;
    color: #444;
    min-width: 81px;
    padding: 5px 10px;
    display: inline-block;
    font-weight: 600
}

.custom-status {
    position: relative
}

.custom-status .live-dark {
    display: inline-block
}

.active-orange {
    color: #ffb600
}

.active-orange .live-dark:before {
    background: #ffb600
}

.active-orange .live-dark {
    background: rgba(255, 182, 0, .1)
}

.active-green {
    color: #0dbf0a
}

.active-green .live-dark:before {
    background: #0dbf0a
}

.active-green .live-dark {
    background: rgba(13, 191, 10, .1)
}

.buy-sell li .nav-link {
    padding: 10px;
    color: #171717;
    font-weight: 600
}

.buy-sell li .nav-link.active {
    background-color: #fe3f51
}

.buy-sell .nav-pills {
    background: #fff;
    border-radius: 8px;
    padding: 2px
}

.buy-form .dataTables_wrapper div.dataTables_length label, .buy-form .dataTables_wrapper div.dataTables_paginate ul.pagination, .buy-form .dataTables_wrapper div.dataTables_info {
    display: none
}

.btn-book:after {
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
    position: absolute;
    top: 15px;
    right: 6px
}

.btn-book {
    color: #344357;
    font-size: 14px;
    padding: 7px 20px 7px 13px;
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), rgba(255, 255, 255, 0.2);
    border: 2px solid #fff;
    border-radius: 8px;
    position: relative;
    cursor: pointer
}

.down-range {
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), rgba(255, 255, 255, 0.2);
    border: 2px solid #fff;
    border-radius: 8px;
    width: 40px;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center
}

.flow-time-blk {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.flow-times {
    margin-right: 15px
}

.flow-times h5 {
    color: #818181
}

.flow-times span {
    color: #818181
}

.flow-time-blk h4 {
    margin-right: 20px
}

.flow-times span {
    font-weight: 600
}

.flow-times .flow-blue {
    color: #444
}

.flow-times .flow-green {
    color: #0dbf0a
}

.flow-times .flow-light-blue {
    color: #ab18ff
}

.flow-times .flow-red {
    color: #fe3f51
}

.time-variant {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0
}

.time-variant li {
    width: 50px;
    height: 40px;
    background: #e3e9ff;
    border-radius: 8px;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    margin-right: 5px
}

.time-variant li:hover {
    background: #444
}

.time-variant li:hover a {
    color: #fff
}

.time-variant li a {
    color: #171717
}

.time-variant li:last-child {
    margin-right: 0
}

.time-variant li.time-range {
    background-color: transparent
}

.time-variant li.active {
    background: #444
}

.time-variant li.active a {
    color: #fff
}

.exchange-blk label {
    margin-bottom: 10px
}

.ex-forms {
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), rgba(255, 255, 255, 0.2);
    border: 2px solid #fff;
    border-radius: 8px;
    padding: 3px
}

/* .exchange-bg .select2-container--default .select2-selection--single .select2-selection__rendered {
    background: #fff
} */

.side-input .form-control {
    border: 0
}

.account-page {
    display: table-row;
    height: 100%;
    padding: 40px 0
}

.account-title {
    font-size: 32px;
    font-weight: 400;
    margin: 0 0 40px;
    text-align: center
}

.account-wrapper {
    display: table;
    table-layout: fixed;
    height: 100%;
    padding: 30px;
    width: 100%
}

.account-center {
    display: table-cell;
    vertical-align: middle
}

.account-box {
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 9px #e1ecff, inset 0 0 15px rgba(255, 255, 255, .35);
    margin: 0 auto;
    padding: 30px;
    width: 500px;
    border-radius: 5px
}

.account-logo {
    margin-bottom: 25px;
    text-align: center
}

.account-box .form-group {
    margin-bottom: 20px
}

.account-box a {
    color: #333;
    font-size: 14px
}

.account-box a:hover {
    color: #444
}

.account-box .form-control {
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), rgba(255, 255, 255, 0.2);
    border: 2px solid #fff;
    border-radius: 8px;
    box-shadow: inherit;
    font-size: 14px;
    height: 45px
}

.account-box .form-control:focus {
    border: 2px solid #444
}

/* .account-box textarea.form-control {
    height: 130px
} */

.account-box label {
    color: #323232;
    font-size: 14px;
    font-weight: 400
}

.account-logo img {
    max-height: 60px;
    width: auto
}

.account-box .account-btn {
    font-size: 16px;
    min-width: 100%;
    padding: 8px 30px;
    background: #444;
    border-radius: 8px;
    font-weight: 600
}

.account-box .account-btn i {
    font-size: 10px
}

.account-box .account-btn:hover {
    border: 1px solid #1837a9;
    background: #1837a9
}

.login-header h3 {
    color: #35446f;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px
}

.login-header p {
    color: #818181;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 25px
}

.register-link a, .login-link a {
    font-size: 14px;
    color: #444;
    font-weight: 600
}

.forgotpass {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 20px 0 30px
}

.forgotpass a {
    margin-left: auto;
    color: #fe3f51;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.term-register label a {
    color: #444
}

.remember-me .custom_check {
    color: #35446f;
    font-size: 14px;
    font-weight: 400
}

.custom_check input {
    position: absolute;
    opacity: 0;
    cursor: pointer
}

.remember-me .custom_check .checkmark {
    width: 15px;
    height: 15px;
    top: 3px;
    background: #cccedd;
    box-shadow: inset 0 1px 1px rgba(119, 126, 165, .25);
    border-radius: 1px
}

.custom_check {
    color: #1b2559;
    display: inline-block;
    position: relative;
    font-size: 14px;
    margin-bottom: 15px;
    padding-left: 22px;
    cursor: pointer;
    font-weight: 400;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.custom_check input {
    position: absolute;
    opacity: 0;
    cursor: pointer
}

.custom_check input:checked ~ .checkmark {
    background-color: #3d5ee1;
    border-color: #3d5ee1
}

.custom_check .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 19px;
    width: 21px;
    border: 1px solid #c4c4c4;
    background-color: #fff;
    border-radius: 6px;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.custom_check .checkmark::after {
    content: "\f00c";
    font-family: "font awesome 5 free";
    font-weight: 900;
    position: absolute;
    display: none;
    left: 3px;
    top: 0;
    color: #fff;
    font-size: 9px
}

.custom_check input:checked ~ .checkmark:after {
    display: block
}

.form-signin .form-group {
    position: relative;
    margin-bottom: 12px
}

.form-signin .form-group .form-control::placeholder {
    color: #35446f
}

.profile-views {
    position: absolute;
    right: 17px;
    top: 50%;
    transform: translateY(-50%)
}

.dash-widget {
    background-color: #fff;
    background-color: rgba(255, 255, 255, .8);
    border-radius: 4px;
    margin-bottom: 30px;
    padding: 20px;
    position: relative;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .1)
}

.dash-widget-bg1 {
    width: 55px;
    float: left;
    color: #fff;
    display: block;
    text-align: center;
    line-height: 55px;
    background: #444;
    border-radius: 50%;
    font-size: 30px;
    height: 55px
}

.dash-widget-bg2 {
    width: 55px;
    float: left;
    color: #fff;
    display: block;
    text-align: center;
    line-height: 55px;
    background: #7014cc;
    border-radius: 50%;
    font-size: 30px;
    height: 55px
}

.dash-widget-bg3 {
    width: 55px;
    float: left;
    color: #fff;
    display: block;
    text-align: center;
    line-height: 55px;
    background: #7a92a3;
    border-radius: 50%;
    font-size: 30px;
    height: 55px
}

.dash-widget-bg4 {
    width: 55px;
    float: left;
    color: #fff;
    display: block;
    text-align: center;
    line-height: 55px;
    background: #ffbc35;
    border-radius: 50%;
    font-size: 30px;
    height: 55px
}

.card-table.card-table-top .card-body {
    height: 175px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch
}

.chart-title {
    margin-bottom: 25px
}

.chart-title h4 {
    display: inline-block;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    color: #333
}

.chart-title span {
    color: #444
}

.card-title > a.btn {
    color: #fff
}

ul.chat-user-total li {
    display: inline-block;
    color: #999
}

ul.chat-user-total li i.current-users {
    color: #444;
    padding-right: 5px
}

ul.chat-user-total li i.old-users {
    color: #eaeaea;
    padding-right: 5px
}

.new-patient-table {
    display: table
}

.new-patient-table td, .new-patient-table th {
    vertical-align: middle
}

.new-patient-table tr td img {
    margin-right: 5px
}

.btn-primary-one {
    border: 1px solid #ffbc35;
    border-radius: 25px;
    width: 100px;
    background: 0 0;
    color: #000
}

.btn-primary-two {
    border: 1px solid #444;
    border-radius: 25px;
    width: 100px;
    background: 0 0;
    color: #000
}

.btn-primary-three {
    border: 1px solid #7014cc;
    border-radius: 25px;
    width: 100px;
    background: 0 0;
    color: #000
}

.btn-primary-four {
    border: 1px solid #ffbc35;
    border-radius: 25px;
    width: 100px;
    background: 0 0;
    color: #000
}

.bar-chart {
    position: relative;
    width: 100%;
    margin-top: 15px
}

.bar-chart > .legend {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    margin-bottom: -45px;
    border-top: 1px solid #000
}

.bar-chart > .legend > .item {
    position: relative;
    display: inline-block;
    float: left;
    width: 25%
}

.bar-chart > .legend > .item:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 1px;
    height: 8px;
    background-color: #000;
    margin-top: -8px
}

.bar-chart > .legend > .item.text-end:before {
    right: 0;
    left: auto
}

.bar-chart > .legend > .item h4 {
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding-top: 5px
}

.bar-chart > .chart {
    position: relative;
    width: 100%
}

.bar-chart > .chart > .item {
    position: relative;
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
    color: #fff;
    text-transform: uppercase
}

.bar-chart > .chart > .item > .bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffbc35;
    z-index: 5
}

.bar-chart > .chart > .item > .bar > .percent {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: 40px;
    line-height: 40px;
    padding-right: 12px;
    z-index: 15
}

.bar-chart > .chart > .item > .bar > .item-progress {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #444;
    z-index: 10
}

.bar-chart > .chart > .item > .bar > .item-progress > .title {
    display: block;
    position: absolute;
    height: 40px;
    line-height: 40px;
    padding-left: 12px;
    letter-spacing: 2px;
    z-index: 15
}

.hospital-barchart h4 {
    display: inline-block;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    color: #333
}

.custom-table tr {
    background: rgba(255, 255, 255, .4);
    box-shadow: 0 0 3px #e5e5e5;
    border: 1px solid transparent
}

.table.custom-table > tbody > tr > td, .table.custom-table > tbody > tr > th, .table.custom-table > tfoot > tr > td, .table.custom-table > tfoot > tr > th, .table.custom-table > thead > tr > td, .table.custom-table > thead > tr > th {
    padding: 14px 8px;
    vertical-align: middle
}

.dash-widget-info > h3 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: .5rem
}

.dash-widget-info span i {
    width: 16px;
    background: #fff;
    border-radius: 50%;
    color: #666;
    font-size: 9px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    margin-left: 5px
}

.dash-widget-info > span.widget-title1 {
    background: #444;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 13px
}

.dash-widget-info > span.widget-title2 {
    background: #7014cc;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 11px
}

.dash-widget-info > span.widget-title3 {
    background: #7a92a3;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 11px
}

.dash-widget-info > span.widget-title4 {
    background: #ffbc35;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 11px
}

.member-panel .contact-list > li:last-child {
    border-bottom: 0
}

.member-panel .card-body {
    overflow-y: auto;
    height: 275px;
    -webkit-overflow-scrolling: touch;
    padding: 0
}

.contact-list {
    list-style: none;
    padding-left: 0;
    margin: 0
}

.contact-list > li {
    border-bottom: 1px solid #eaeaea;
    border-radius: inherit;
    padding: 10px;
    background-color: #fff
}

.contact-cont {
    position: relative
}

.contact-info {
    padding: 0 30px 0 50px
}

.contact-date {
    color: #888;
    font-size: 12px
}

.take-btn {
    min-width: 80px
}

.welcome-header {
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 9px #e1ecff, inset 0 0 15px rgba(255, 255, 255, .35);
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 25px
}

.wel-come-name h4, .wel-come-name span {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0
}

.wel-come-name h4 {
    color: #000
}

.wel-come-name span {
    color: #444
}

.wallet-list span {
    font-size: 16px;
    font-weight: 600;
    background: #fe3f51;
    border-radius: 10px;
    min-width: 180px;
    display: inline-block;
    text-align: center;
    padding: 8px 15px;
    color: #fff
}

.bit-coins {
    position: relative
}

/* .bit-coins .select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 30px
} */

.bit-coins img {
    position: absolute;
    top: 10px;
    left: 10px
}

.welcome-wallet {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end
}

.welcome-wallet .form-group {
    margin-bottom: 0;
    margin-left: 20px
}

.dash-widget-blk {
    margin-bottom: 10px
}

.dash-widget-blk ul {
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between
}

.dash-widget-blk ul li {
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 9px #e1ecff, inset 0 0 15px rgba(255, 255, 255, .35);
    border-radius: 15px;
    list-style: none;
    padding: 10px;
    margin: 0 10px 10px 0
}

.dash-widget-blk ul li:last-child {
    margin-right: 0
}

.premium-box {
    width: 70px;
    height: 70px;
    border-radius: 20px;
    padding: 10px;
    margin: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    box-shadow: 0 4px 9px rgba(35, 76, 227, .3), inset 0 0 15px rgba(255, 255, 255, .35)
}

.premium-name-blk {
    text-align: center;
    margin: 25px 0
}

.premium-name-blk h4 {
    color: #171717;
    font-size: 14px;
    font-weight: 600
}

.premium-name-blk h3 {
    font-size: 20px;
    font-weight: 700
}

.premium-img img {
    width: 100%
}

.box-blue {
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), #444
}

.box-pink {
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), #FE3F51
}

.box-green {
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), #0DBF0A
}

.box-orange {
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), #FFBC13
}

.box-light {
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), #AB18FF
}

.box-dark {
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), #4055A3
}

.box-sky {
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), #00B2FF
}

.box-litcoin {
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), #FF7A00
}

.title-blue h3 {
    color: #3b60e6
}

.title-pink h3 {
    color: #fe5061
}

.title-green h3 {
    color: #11c00e
}

.title-orange h3 {
    color: #ffbc13
}

.title-light h3 {
    color: #ad1dff
}

.title-dark h3 {
    color: #4e62aa
}

.title-sky h3 {
    color: #00b2ff
}

.title-litcoin h3 {
    color: #ff7a00
}

ul {
    padding: 0
}

.bit-coin-blk {
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 9px #e1ecff, inset 0 0 15px rgba(255, 255, 255, .35);
    backdrop-filter: blur(5px);
    border-radius: 5px;
    padding: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between
}

.bit-coin-blk li {
    list-style: none;
    border-right: 1px solid #efecf1;
    padding-right: 25px
}

.bit-coin-blk li:last-child {
    border: 0
}

.bit-coin-blk li h4 {
    font-size: 16px;
    font-weight: 600;
    color: #35446f
}

.bit-coin-blk li h3 {
    font-size: 20px;
    font-weight: 400;
    color: #171717
}

.bit-coin-blk li p {
    font-size: 14px;
    font-weight: 600;
    color: #0dbf0a;
    margin-bottom: 0
}

.bit-coin-blk li p.low-range {
    color: #fe3f51
}

/* .bg-hover .select2-container {
    z-index: 9
} */

.bg-hover {
    width: 100%
}

.sell-buy-list ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.sell-buy-list ul li {
    padding: 0 15px
}

.sell-buy-list ul li:hover a {
    color: #444
}

.sell-buy-list ul li.active a {
    color: #444
}

.sell-buy-list ul li.active {
    position: relative
}

.sell-buy-list ul li.active:before {
    content: "";
    position: absolute;
    background: #444;
    width: 100%;
    height: 3px;
    left: 0;
    bottom: -3px
}

.sell-buy-list ul li a {
    color: #171717;
    font-weight: 600;
    font-size: 16px
}

.sell-buy-list p {
    color: #818181
}

.buy-crypto-group {
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 9px #e1ecff, inset 0 0 15px rgba(255, 255, 255, .35);
    border-radius: 5px;
    padding: 30px;
    margin-bottom: 40px
}

.buy-crypto-group label {
    color: #35446f;
    font-weight: 600;
    margin-bottom: 5px
}

.buy-crpto-btn .btn-primary {
    min-width: 242px;
    padding: 10px 15px
}

.comman-head h3 {
    font-size: 20px;
    color: #35446f;
    font-weight: 600;
    margin-bottom: 5px
}

.comman-head p {
    color: #818181;
    font-weight: 400;
    font-size: 14px
}

.action-head h4 {
    font-size: 14px;
    color: #35446f;
    font-weight: 600;
    margin-bottom: 0
}

.action-head span {
    font-size: 14px;
    color: #35446f;
    font-weight: 600
}

.sort-by {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.sort-by label {
    margin-right: 10px
}

/* .date-border .form-control, .down-range.date-border, .date-border .select2-container .select2-selection--single {
    border: 1px solid #6586bf;
    background: 0 0
} */

.down-range.date-border {
    flex: none
}

.date-border .form-control {
    padding-left: 30px
}

.date-border.form-group {
    margin-bottom: 0
}

.date-border.cal-icon:after {
    left: 10px;
    right: inherit
}

.dark-clr {
    color: #35446f;
    font-weight: 600 !important
}

.pay-clr {
    color: #444;
    font-weight: 600 !important
}

#chartdiv2 {
    height: 500px;
    overflow: visible
}

.trad-book-grp, .trad-book-grp ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.trad-book-grp ul {
    margin-bottom: 0
}

.trad-book-grp ul li {
    padding: 0 10px
}

.bit-same {
    width: 7px;
    height: 7px;
    display: inline-block;
    border-radius: 50px;
    margin-right: 5px
}

.green-dot {
    background: #0dbf0a
}

.red-dot {
    background: #fe3f51
}

.table.custom-table .currency-type {
    color: #515151;
    font-weight: 600;
    text-transform: uppercase
}

.market-cap-list ul {
    margin-bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.market-cap-list ul li {
    width: 40px;
    height: 40px;
    background: #e3e9ff;
    border-radius: 4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    margin-right: 5px
}

.market-cap-list ul li.active {
    background: #444
}

.market-cap-list ul li:hover {
    background: #444
}

.market-cap-list ul li:hover img {
    filter: invert(1) brightness(100)
}

.market-cap-list ul li.active img {
    filter: invert(1) brightness(100)
}

.transaction-grp {
    margin-bottom: 15px
}

.transaction-record {
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 9px #e1ecff, inset 0 0 15px rgba(255, 255, 255, .35);
    border-radius: 5px;
    text-align: center;
    padding: 25px;
    margin-bottom: 25px
}

.transaction-record h2 {
    font-size: 28px;
    font-weight: 600;
    display: inline-block;
    padding-bottom: 7px;
    margin-bottom: 7px
}

.transaction-record p {
    color: #000;
    font-size: 14px;
    margin-bottom: 0
}

.blue-view h2 {
    color: #444;
    border-bottom: 2px solid #444
}

.red-view h2 {
    color: #fe3f51;
    border-bottom: 2px solid #fe3f51
}

.green-view h2 {
    color: #0dbf0a;
    border-bottom: 2px solid #0dbf0a
}

.black-view h2 {
    color: #35446f;
    border-bottom: 2px solid #35446f
}

.transactions-search-blk h2 {
    font-size: 20px;
    font-weight: 600;
    color: #35446f;
    margin-bottom: 20px
}

.transaction-confirmation {
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 9px #e1ecff, inset 0 0 15px rgba(255, 255, 255, .35);
    border-radius: 5px;
    padding: 25px;
    width: 100%;
    margin-bottom: 20px
}

.transaction-confirmation p {
    font-size: 16px;
    font-weight: 600;
    color: #171717
}

.transaction-confirmation ul {
    margin-bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 25px
}

.transaction-confirmation ul li:first-child {
    padding-left: 0
}

.transaction-confirmation ul li {
    padding: 0 10px;
    color: #444;
    font-weight: 600;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.transaction-confirmation ul li i {
    color: #35446f
}

.transaction-btns {
    margin-bottom: 25px
}

.transaction-btns .btn-primary {
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    padding: 10px 15px;
    border-radius: 4px
}

.transaction-btns .confirm-blue {
    background: #00b2ff
}

.transaction-btns .confirm-blue:hover {
    background: #fe3f51
}

.transaction-btns .confirm-red {
    background: #fe3f51
}

.transaction-btns .confirm-red:hover {
    background: #00b2ff;
    border: 1px solid #00b2ff
}

.card.detail-blk {
    margin-bottom: 20px;
    width: 100%
}

.detail-blk .card-title h3 {
    font-size: 20px;
    color: #35446f;
    font-weight: 600;
    padding: 15px;
    border-bottom: 1px solid #efecf1;
    margin-bottom: 0
}

.detail-blk .card-body {
    padding: 15px
}

.transaction-details h4 {
    color: #35446f;
    font-size: 15px
}

.transaction-details h5 {
    color: #171717;
    font-size: 15px
}

.transaction-details {
    margin-bottom: 30px
}

.transaction-details a {
    text-decoration: underline;
    color: #444
}

.table-striped > tbody > tr:nth-of-type(2n+1) {
    background: rgba(255, 255, 255, .2)
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
    --bs-table-accent-bg: transparent
}

.table-striped > tbody > tr:nth-of-type(even) > * {
    background: rgba(255, 255, 255, .4)
}

.table.transaction-sumary tr td:first-child {
    color: #35446f;
    font-weight: 500
}

.table.transaction-sumary tr td:last-child {
    color: #171717;
    font-weight: 500;
    font-size: 15px
}

.visualize a {
    color: #444
}

.summary-blk h3 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%
}

.summary-view {
    margin-left: auto;
    font-size: 15px
}

.summary-view a {
    text-decoration: underline
}

.confirm-id span {
    color: #0dbf0a;
    font-size: 16px;
    font-weight: 600
}

.back-transaction {
    margin-bottom: 20px
}

.back-transaction a {
    font-size: 14px;
    color: #fe3f51;
    font-weight: 600;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.address-detail-blk h5 {
    font-size: 15px;
    color: #35446f
}

.address-detail-blk p {
    font-size: 15px;
    color: #444;
    margin-bottom: 0
}

.card.recent-trans-blk {
    background: rgba(255, 255, 255, .5);
    box-shadow: 0 4px 24px #dfe6ff;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 25px
}

.recent-date .btn-primary {
    min-width: 145px;
    background: #fe3f51;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    padding: 3px 15px;
    margin-bottom: 0
}

.recent-date {
    margin-bottom: 15px
}

.transaction-key {
    margin-bottom: 25px
}

.transaction-key h5 {
    color: #35446f;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 3px
}

.transaction-key p {
    font-size: 14px;
    font-weight: 400;
    color: #171717;
    margin-bottom: 0
}

.key-trans-list {
    background: rgba(227, 233, 255, .4);
    padding: 5px;
    margin-bottom: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center
}

.key-trans-list li {
    color: #444;
    font-weight: 600;
    padding-right: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.confirm-btn-grp {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.confirm-btn-grp span {
    margin-left: auto;
    font-size: 14px;
    font-weight: 600;
    color: #35446f
}

.confirm-btn-grp .btn-primary {
    background: #00b2ff;
    border-radius: 4px;
    min-width: 150px;
    padding: 10px 15px;
    margin-bottom: 0
}

.confirm-btn-grp .confirm-blue {
    background: #00b2ff;
    margin-right: 10px
}

.confirm-btn-grp .confirm-blue:hover {
    background: #0dbf0a;
    border: 1px solid #0dbf0a
}

.confirm-btn-grp .confirm-green {
    background: #0dbf0a
}

.confirm-btn-grp .confirm-green:hover {
    background: #00b2ff;
    border: 1px solid #00b2ff
}

.trans-pagenation .pagination {
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end
}

.pagination .page-link {
    background: 0 0;
    border: 0;
    /* color: #6586bf; */
    font-weight: 600
}

.page-item:first-child .page-link, .page-item:last-child .page-link {
    color: #35446f
}

.page-item.active .page-link {
    color: #fff
}

.wallet-header {
    margin-bottom: 30px
}

.wallet-header .wel-come-name h4 {
    color: #35446f;
    font-size: 20px;
    font-weight: 600
}

.wallet-header .wallet-list span {
    background: #444;
    border-radius: 4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center
}

.wallet-header .wallet-list span:hover {
    background: #fe3f51
}

.card-holder {
    box-shadow: 0 4px 9px rgba(35, 76, 227, .3), inset 0 0 15px rgba(255, 255, 255, .35);
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 25px;
    width: 100%
}

.holder-blue {
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.05) 97.35%, rgba(255, 255, 255, 0) 160.66%), #444
}

.holder-light {
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.05) 97.35%, rgba(255, 255, 255, 0) 160.66%), #AB18FF
}

.main-balance-blk {
    margin-bottom: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between
}

.main-balance h4 {
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    margin-bottom: 5px
}

.main-balance h3 {
    font-size: 24px;
    color: #fff;
    font-weight: 600;
    margin-bottom: 0
}

.balance-bit-img {
    width: 43px;
    height: 43px;
    background: #fff;
    border-radius: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center
}

.card-valid-blk {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.valid-holder p {
    color: #e3e9ff;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 5px;
    text-transform: uppercase
}

.valid-holder h5 {
    color: #e3e9ff;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0
}

.card.wallet-widget {
    margin-bottom: 25px;
    padding: 15px;
    width: 100%
}

.main-limit {
    text-align: center
}

.main-limit p {
    font-size: 14px;
    font-weight: 400;
    color: #171717;
    margin-bottom: 0
}

.main-limit h4 {
    font-size: 16px;
    font-weight: 600;
    color: #0dbf0a;
    margin-bottom: 0
}

.main-limit.main-second h4 {
    color: #fe3f51
}

.wallet-widget .circle-bar {
    text-align: center
}

.wallet-widget .circle-bar > div {
    display: inline-block;
    position: relative;
    text-align: center
}

.wallet-widget .circle-bar > div b {
    font-size: 20px;
    font-weight: 600;
    color: #0dbf0a;
    left: 0;
    position: absolute;
    top: 50%;
    right: 0;
    text-align: center;
    margin: 0 auto;
    transform: translateY(-50%)
}

.wallet-widget .circle-bar.circle-bar3 > div b {
    color: #fe3f51
}

.wallet-widget .circle-bar > div canvas {
    width: 90px !important;
    height: 90px !important
}

.transfer-blk.card {
    padding: 20px
}

.account-grp.card {
    width: 100%
}

.security-head h3 {
    font-size: 16px;
    color: #171717;
    font-weight: 700;
    padding: 20px;
    margin-bottom: 0
}

.security-group {
    padding: 20px;
    border-top: 1px solid rgb(23 23 23/10%)
}

.security-box {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    margin-right: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center
}

.lock-clr .security-box {
    background: #ffe8ec
}

.phone-clr .security-box {
    background: #ecfbe6
}

.mail-clr .security-box {
    background: #fff6e8
}

.security-name-blk h4 {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 3px
}

.security-name-blk .secure-disable {
    color: #fe3f51;
    font-size: 14px
}

.security-name-blk .secure-active {
    color: #0dbf0a
}

.security-name-blk .secure-complete {
    color: #ffbc13
}

.verification-blk {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.security-switch {
    margin-left: auto
}

.security-switch.material-switch > label:before {
    height: 8px;
    width: 33px;
    background: #cccedd !important;
    opacity: .5;
    box-shadow: inset 0 1px 1px rgba(119, 126, 165, .25);
    border-radius: 6px
}

.security-switch.material-switch > label:after {
    height: 18px;
    width: 18px;
    background: #1a75ff
}

.badge-active {
    background: #f6f8fb
}

.transfer-quick {
    margin-bottom: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between
}

.transfer-quick .quick-cont p {
    margin-bottom: 0
}

/* .ex-forms .select2-container .select2-selection--single {
    height: 30px
} */

/* .ex-forms .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 26px;
    border-radius: 8px
} */

.ex-forms .form-control {
    min-height: 30px;
    height: 30px
}

/* .ex-forms .select2-container--default .select2-selection--single .select2-selection__arrow b, .ex-forms .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    margin-top: -8px
} */

.transfer-now-btn .btn-primary {
    width: 100%;
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 600
}

.settings-navs ul {
    padding-bottom: 25px;
    margin-bottom: 25px;
    border-bottom: 1px solid #efecf1
}

.company-setting {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 25px
}

.sell-buy-list.settings-navs ul li a {
    font-weight: 600;
    font-size: 14px
}

.sell-buy-list.settings-navs ul li.active a {
    color: #444
}

.form-group label {
    color: #35446f;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 14px
}

.setting-btn .submit-btn {
    min-width: 200px
}

.theme-form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.theme-form .image-upbtn {
    min-width: 177px;
    background: #6586bf;
    color: #fff;
    padding: 8px 15px;
    font-size: 16px;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    text-align: center;
    transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -webkit-transition: all .5s ease
}

.theme-form .image-upbtn:hover {
    background: #fe3f51;
    transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -webkit-transition: all .5s ease
}

.theme-form label.file-upload input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 177px;
    height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: #fff;
    cursor: inherit;
    display: block
}

.logo-details p {
    color: #35446f;
    font-size: 14px
}

.configure-setting ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.configure-setting ul li.active a, .configure-setting ul li a:hover {
    background: #444;
    color: #fff
}

.configure-setting ul li a {
    background: rgba(255, 255, 255, .2);
    box-shadow: 0 4px 14px rgba(224, 224, 224, .25);
    border-radius: 10px;
    min-width: 163px;
    padding: 10px 15px;
    display: block;
    text-align: center;
    color: #000
}

.email-set-head h5 {
    color: #818181;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px
}

.form-check-input {
    background: rgb(204 206 221/50%);
    box-shadow: inset 0 1px 1px rgba(119, 126, 165, .25)
}

.check-email .form-check label {
    font-weight: 400
}

.form-check-input:checked {
    box-shadow: none
}

.setting-notify-blk h5 {
    font-size: 16px;
    color: #818181;
    font-weight: 600;
    margin-bottom: 0;
    padding: 20px
}

.permission-list {
    padding: 0 20px
}

.permission-list ul li {
    background: rgba(255, 255, 255, .2);
    padding: 10px;
    color: #000;
    font-weight: 600;
    font-size: 16px
}

.permission-list ul li:nth-child(even) {
    background: rgba(255, 255, 255, .5)
}

.permission-role ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 20px
}

.permission-role ul li {
    padding: 25px;
    margin-right: 15px;
    background: #fff;
    border-radius: 4px;
    min-width: 153px;
    text-align: center;
    color: #171717;
    font-size: 14px
}

.permission-role ul li.add-roles {
    background: rgba(255, 255, 255, .4);
    border: 1px dashed #6586bf;
    padding: 0
}

.permission-role ul li.add-roles a {
    padding: 25px;
    color: #000;
    display: block;
    font-weight: 400
}

.password-strength {
    display: flex;
    margin-top: 15px
}

.password-strength span {
    width: 100%;
    height: 5px;
    background: #e3e3e3;
    margin-right: 10px;
    border-radius: 5px
}

.password-strength span:last-child {
    margin-right: 0
}

.weak-pass .password-strength span {
    background: red
}

#passwordInfo, #passwordInfos {
    font-size: 14px;
    font-weight: 400;
    margin-top: 15px
}

.poor-active span.active#poor, .poor-active span.active#poors {
    background: red
}

.avg-active span.active#poor, .avg-active span.active#weak, .avg-active span.active#poors, .avg-active span.active#weaks {
    background: #ffb54a
}

.strong-active span.active#poor, .strong-active span.active#weak, .strong-active span.active#strong, .strong-active span.active#poors, .strong-active span.active#weaks, .strong-active span.active#strongs {
    background: #1d9cfd
}

.heavy-active span.active#poor, .heavy-active span.active#weak, .heavy-active span.active#strong, .heavy-active span.active#heavy, .heavy-active span.active#poors, .heavy-active span.active#weaks, .heavy-active span.active#strongs, .heavy-active span.active#heavys {
    background: #159f46
}

input[type=password]::-ms-reveal, input[type=password]::-ms-clear {
    display: none
}

.activity-box {
    position: relative
}

.activity-list {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative
}

.activity .activity-list > li .activity-user {
    height: 32px;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 12px;
    left: 8px;
    width: 32px;
    border-radius: 50px
}

.activity .activity-list > li .activity-content {
    background-color: transparent;
    margin: 0 0 0 40px;
    padding: 0;
    position: relative
}

.activity-list > li {
    margin-bottom: 10px;
    padding: 10px;
    position: relative;
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 9px #e1ecff, inset 0 0 15px rgb(255 255 255/35%);
    border-radius: 5px
}

.activity-list > li:last-child .activity-content {
    margin-bottom: 0
}

.activity-user .avatar {
    height: 32px;
    line-height: 32px;
    margin: 0;
    width: 32px
}

.activity-list > li .activity-user {
    background: #fff;
    height: 32px;
    left: -7px;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 3px;
    width: 32px
}

.activity-list > li .activity-content {
    background-color: #fff;
    margin: 0 0 20px 40px;
    padding: 0;
    position: relative
}

.activity-list > li .activity-content .timeline-content {
    color: #9e9e9e
}

.activity-list > li .activity-content .timeline-content a.name {
    color: #000
}

.activity-list > li .time {
    color: #bdbdbd;
    display: block;
    font-size: 12px;
    line-height: 1.35
}

.activity .timeline-content > a {
    color: #444
}

.activity-delete {
    background-color: #ffe5e6;
    border: 1px solid #fe0000;
    color: #fe0000;
    display: none;
    font-size: 20px;
    height: 20px;
    line-height: 15px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 20px
}

.activity-delete:hover {
    background-color: #fe0000;
    color: #fff
}

.activity-list > li:hover .activity-delete {
    display: inline-block
}

/* .select2-container .select2-selection--single {
    height: 40px;
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), rgba(255, 255, 255, 0.2);
    border: 2px solid #fff;
    border-radius: 8px
} */

/* .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 38px;
    right: 7px
} */
/* 
.select2-container--default .select2-selection--single .select2-selection__arrow b {
    left: 50%;
    margin-left: -10px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    border-top: 0;
    border-left: 0;
    border-bottom: 1px solid #171717;
    border-right: 1px solid #171717;
    content: '';
    height: 6px;
    display: inline-block;
    pointer-events: none;
    -webkit-transform-origin: 66% 66%;
    -ms-transform-origin: 66% 66%;
    transform-origin: 66% 66%;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all .15s ease-in-out;
    transition: all .15s ease-in-out;
    width: 6px;
    vertical-align: 0
} */

/* .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    left: 50%;
    margin-left: -10px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    border-top: 0;
    border-left: 0;
    border-bottom: 1px solid #171717;
    border-right: 1px solid #171717;
    content: '';
    height: 6px;
    display: inline-block;
    pointer-events: none;
    -webkit-transform-origin: 66% 66%;
    -ms-transform-origin: 66% 66%;
    transform-origin: 66% 66%;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all .15s ease-in-out;
    transition: all .15s ease-in-out;
    width: 6px;
    vertical-align: 0
} */

/* .select2-container .select2-selection--single .select2-selection__rendered {
    padding-right: 30px;
    padding-left: 15px
} */

/* .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #676767;
    font-size: 14px;
    font-weight: 400;
    line-height: 38px
} */

/* .select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #444
} */

/* .select2-container--default.select2-container--focus .select2-selection--multiple {
    border: 1px solid #eaeaea;
    border-radius: 0
} */

/* .select2-container--default .select2-selection--multiple {
    border: 1px solid #eaeaea;
    border-radius: 0
} */

/* .select2-container .select2-selection--multiple {
    min-height: 40px
} */

.nav-tabs > li > a {
    margin-right: 0;
    color: #888;
    border-radius: 0
}

.nav-tabs > li > a:hover, .nav-tabs > li > a:focus {
    border-color: transparent;
    color: #333
}

.nav-tabs.nav-justified > li > a {
    border-radius: 0;
    margin-bottom: 0
}

.nav-tabs.nav-justified.nav-tabs-solid > li > a {
    border-color: transparent
}

.nav-tabs.nav-tabs-solid > li > a {
    color: #333
}

.nav-tabs.nav-tabs-solid > li > a.active, .nav-tabs.nav-tabs-solid > li > a.active:hover, .nav-tabs.nav-tabs-solid > li > a.active:focus {
    background-color: #444;
    border-color: #444;
    color: #fff
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
    border-radius: 50px
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a {
    border-radius: 50px
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active, .nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:hover, .nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:focus {
    border-radius: 50px
}

.nav-tabs-justified > li > a {
    border-radius: 0;
    margin-bottom: 0
}

.nav-tabs-justified > li > a:hover, .nav-tabs-justified > li > a:focus {
    border-bottom-color: #ddd
}

.nav-tabs-justified.nav-tabs-solid > li > a {
    border-color: transparent
}

.custom-table tr.holiday-completed {
    color: #aaa
}

.modal-header.bg-danger .modal-title {
    color: #fff
}

.dropdown.action-label {
    display: inline-block
}

.action-label > a {
    display: inline-block;
    min-width: 103px
}

.action-label .btn-sm {
    padding: 5px
}

.profile-img-wrap {
    height: 120px;
    position: absolute;
    width: 120px;
    overflow: hidden
}

.profile-basic {
    margin-left: 140px
}

.profile-img-wrap img {
    width: 120px;
    height: 120px
}

.fileupload.btn {
    position: absolute;
    right: 0;
    bottom: 0;
    background: rgba(33, 33, 33, .5);
    border-radius: 0;
    padding: 3px 10px;
    border: none
}

.fileupload input.upload {
    cursor: pointer;
    filter: alpha(opacity=0);
    font-size: 20px;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: -3px;
    top: -3px;
    padding: 5px
}

.btn-text {
    color: #fff
}

.card.chat-box {
    padding: 20px;
    border: 2px solid #fff;
    border-radius: 8px;
    width: 100%
}

.chat-user-group {
    margin-bottom: 30px
}

.chat-users {
    width: 100%
}

.chat-users.user-main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between
}

.user-dot-list {
    width: 40px;
    height: 40px;
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), rgba(255, 255, 255, 0.2);
    border: 2px solid #fff;
    border-radius: 8px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center
}

.user-dot-list a {
    color: #171717;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.chat-user-time, .chat-user-count {
    margin-left: auto
}

.user-titles h5 {
    font-size: 15px;
    font-weight: 600;
    color: #000;
    margin-bottom: 3px
}

.chat-user-time p, .user-text p {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0;
    color: #6586bf
}

.chat-user-count span {
    background: #0dbf0a;
    border-radius: 50px;
    width: 15px;
    height: 15px;
    font-size: 10px;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center
}

.chat-user-count .user-check {
    background: 0 0;
    color: #838383;
    font-size: 14px
}

.chat-user-group .img-users {
    width: 45px;
    height: 45px;
    margin-right: 10px
}

.user-check.active {
    color: #399e8d
}

.chat-user-group .active-users {
    width: 8px;
    height: 8px;
    right: 5px;
    top: 0
}

.img-users {
    position: relative
}

.active-users {
    width: 10px;
    height: 10px;
    background: green;
    border-radius: 50px;
    position: absolute;
    right: 0;
    border: 1px solid #fff
}

.call-user img {
    width: 45px;
    height: 45px;
    border-radius: 50px
}

.chat-search .form-group {
    position: relative;
    width: 100%
}

.chat-search {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    margin-bottom: 30px
}

.add-search a {
    background: #444;
    border-radius: 8px;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    color: #fff
}

.add-search a:hover {
    background: #223d9f
}

.top-liv-search.top-chat-search .btn {
    left: 0;
    right: inherit
}

.top-liv-search.top-chat-search .form-control {
    padding-left: 40px
}

.chat-search-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between
}

.chat-search-list ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    margin-bottom: 0
}

.chat-search-list ul li {
    padding: 10px 0;
    width: 40px;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    margin: 0 10px;
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 9px #e1ecff, inset 0 0 15px rgba(255, 255, 255, .35);
    border-radius: 8px
}

.chat-search-list ul li:last-child {
    margin-right: 0
}

.card.chat-message-box {
    padding: 20px
}

.message-sub-box h4 {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px
}

.message-sub-box p {
    color: #35446f;
    font-size: 14px;
    font-weight: 400;
    background: #e3e9ff;
    border-radius: 0 15px 15px 15px;
    padding: 10px;
    margin-bottom: 5px;
    max-width: 270px
}

.message-sub-box span {
    color: #6586bf;
    font-size: 10px;
    font-weight: 500
}

.media.sent .media-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end
}

.media.sent .message-sub-box {
    text-align: right
}

.media.sent .message-sub-box p {
    background: #6586bf;
    border-radius: 0 15px 15px 15px;
    color: #fff
}

.msg-sub-list {
    margin-bottom: 0
}

.msg-sub-list li {
    background: #e3e9ff;
    border-radius: 8px;
    margin-bottom: 5px;
    padding: 5px;
    max-width: 270px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 14px
}

.msg-sub-list li:last-child {
    margin-bottom: 0
}

.footer-discussion {
    display: inline-flex;
    width: 100%
}

.footer-discussion .inputgroups {
    width: 100%;
    margin-top: 30px;
    position: relative
}

.inputgroups input[type=text] {
    width: 100%;
    height: 52px;
    padding-left: 32px;
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), rgba(255, 255, 255, 0.2);
    border: 2px solid #fff;
    border-radius: 8px
}

.chat-footer-box {
    border-top: 2px solid #fff;
    border-radius: 0 0 10px 10px
}

.position-icon {
    position: absolute
}

.micro-text {
    top: 15px;
    left: 10px
}

.send-chat {
    background: #444;
    border-radius: 8px;
    width: 52px;
    height: 52px;
    top: 0;
    right: 0
}

.comman-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center
}

.symple-text ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0
}

.symple-text {
    top: 15px;
    right: 65px
}

.chat-heading h3 {
    margin-bottom: 20px
}

.chat-users.chat-user-blk {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between
}

.comman-space-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between
}

.chat-user-blk .user-titles p {
    color: #6586bf;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0
}

.chat-user-blk .user-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.chat-user-icon {
    margin-left: 15px
}

.call-all {
    margin-bottom: 25px
}

.call-all h4 {
    color: #35446f;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.call-all h4 span {
    background: #0dbf0a;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    color: #fff;
    font-size: 10px;
    margin-left: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center
}

.call-all h5 {
    margin-bottom: 0
}

.call-all h5 a {
    color: #2e37a4;
    font-size: 14px;
    font-weight: 600
}

.voice-call-user {
    margin-bottom: 30px
}

.voice-call-user img {
    width: 80px;
    height: 80px;
    border-radius: 50px;
    margin-bottom: 10px
}

.voice-call-user h3 {
    color: #333448;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0
}

.voice-call-user p {
    color: rgba(51, 53, 72, .5);
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0
}

.voice-time {
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), rgba(255, 255, 255, 0.2);
    border: 1px solid #fff;
    border-radius: 8px;
    max-width: 191px;
    margin: auto
}

.voice-time h3 {
    font-size: 28px;
    color: #35446f;
    font-weight: 600;
    margin-bottom: 0;
    padding: 10px
}

.voice-menu ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    margin: 30px 0
}

.voice-menu ul li {
    margin-right: 20px
}

.voice-menu ul li:last-child {
    margin-right: 0
}

.voice-menu ul li.active a {
    background: #444;
    border: 1px solid #444
}

.voice-menu ul li:hover a {
    background: #444;
    border: 1px solid #444
}

.voice-menu ul li:hover img {
    filter: invert(1) brightness(100)
}

.voice-menu ul li a {
    width: 50px;
    height: 50px;
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), rgba(255, 255, 255, 0.2);
    border: 1px solid #fff;
    border-radius: 8px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center
}

.end-call-voice .btn-primary {
    background: #fe3f51;
    border-radius: 8px;
    min-width: 136px;
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 600
}

.end-call-voice .btn-primary:hover {
    background: #444;
    border: 1px solid #444
}

.video-call-blk.chat-user-group:hover {
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 9px #e1ecff, inset 0 0 15px rgba(255, 255, 255, .35);
    backdrop-filter: blur(5px);
    border-radius: 8px
}

.video-call-blk.chat-user-group {
    padding: 10px;
    margin-bottom: 15px
}

.chat-user-group .user-titles h5 {
    color: #35446f
}

.message-sub-box h5 {
    color: #6586bf;
    font-size: 10px;
    font-weight: 500
}

.video-chat-show .message-sub-box p {
    font-size: 12px
}

.video-chat-show ul li {
    margin-bottom: 15px
}

.video-chat-show ul {
    margin-bottom: 0
}

.video-chat-show ul li:last-child {
    margin-bottom: 0
}

.calling-income h4 {
    color: #35446f;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 30px
}

.crypto-income-blk .voice-call-user h3 {
    color: #35446f;
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 5px
}

.crypto-income-blk .voice-call-user p {
    color: #444;
    font-size: 16px;
    font-weight: 600
}

.voice-menu-income .btn-primary {
    width: 60px;
    height: 60px;
    border-radius: 12px
}

.voice-menu-income .call-remove {
    background: #fe3f51
}

.voice-menu-income .call-received {
    background: #0dbf0a
}

.compose-mail .btn-primary {
    font-size: 16px;
    font-weight: 600;
    min-width: 100%;
    padding: 10px 15px;
    margin-bottom: 30px
}

.email-menu-blk ul li a {
    color: rgba(51, 52, 72, .5);
    font-size: 18px;
    font-weight: 600;
    padding: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 8px;
    margin-bottom: 5px
}

.email-menu-blk ul li a:hover {
    background: rgba(51, 52, 72, .05);
    color: #333448
}

.email-menu-blk ul li.active a {
    background: rgba(51, 52, 72, .05);
    color: #333448
}

.email-menu-blk ul li.active span {
    background: #0dbf0a;
    border-radius: 20px;
    color: #fff;
    font-weight: 600
}

.email-menu-blk ul li span {
    font-size: 14px;
    margin-left: auto;
    width: 45px;
    height: 26px
}

.label-blk {
    margin-bottom: 20px
}

.label-blk h4 {
    margin-bottom: 0;
    color: #333448;
    font-size: 16px;
    font-weight: 600
}

.label-add-list li .add-list-btn {
    width: 30px;
    height: 30px;
    background: rgba(46, 55, 164, .05);
    border-radius: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center
}

.label-add-list li .add-list-btn:hover {
    background: #444;
    color: #fff
}

.top-action-right ul li {
    margin-right: 20px
}

.top-action-right ul li:last-child {
    margin-right: 0
}

.top-liv-search.top-action-search .form-control {
    background: rgba(51, 52, 72, .05);
    border-radius: 12px;
    border: 0
}

.top-inbox-blk {
    background: rgba(51, 52, 72, .05);
    border-radius: 10px;
    width: 40px;
    height: 40px
}

.top-inbox-blk .dropdown-menu {
    text-align: center;
    padding: 0;
    border: 0;
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.5) 97.35%, rgba(255, 255, 255, 0) 160.66%), rgba(255, 255, 255, 0.8);
    box-shadow: 0 4px 9px #e1ecff, inset 0 0 15px rgba(255, 255, 255, .35);
    backdrop-filter: blur(5px);
    border-radius: 5px
}

.top-inbox-blk .dropdown-menu .dropdown-item {
    border-bottom: 1px solid #ebebf6;
    font-size: 14px;
    font-weight: 600;
    color: #333448;
    padding: 10px
}

.top-inbox-blk .dropdown-menu .dropdown-item:last-child {
    border-bottom: 0
}

.top-recheck .custom_check input {
    position: relative
}

.top-check .form-check-input {
    border: 2px solid rgba(51, 52, 72, .2);
    border-radius: 4px;
    background: rgb(245 248 255);
    width: 20px;
    height: 20px
}

.top-check .form-check-input:checked[type=checkbox] {
    background-color: #444
}

.top-check .form-check-input:focus {
    box-shadow: none
}

.email-content .table-inbox tr {
    background: rgba(255, 255, 255, .4);
    border-radius: 5px
}

.email-content .table-inbox tr td {
    vertical-align: middle;
    padding: 15px;
    color: #6586bf
}

.email-img img {
    border-radius: 50px;
    width: 48px;
    height: 48px;
    margin-right: 10px
}

.send-user h4 {
    color: #35446f;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px
}

.send-user p {
    color: #6586bf;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0
}

.email-img-blk {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.send-user h4 span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 5px;
    margin-left: 10px;
    font-size: 10px;
    border-radius: 8px
}

.send-user .email-market {
    background: rgba(13, 191, 10, .15);
    color: #0dbf0a
}

.email-office {
    background: rgba(13, 191, 10, .15);
    color: #0dbf0a
}

.email-bills {
    background: rgba(254, 63, 81, .15);
    color: #fe3f51
}

.email-work {
    background: rgba(255, 182, 39, .15);
    color: #ffb627
}

.send-user h4 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.compose-mail h3 {
    color: #333448;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 30px
}

.mail-send {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end
}

.mail-send .btn-primary {
    margin-bottom: 0;
    min-width: 111px;
    border-radius: 12px
}

.mail-reports ul li .ford-angle {
    background: rgba(51, 52, 72, .05);
    border-radius: 10px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    width: 40px;
    height: 40px
}

.mail-reports ul li .ford-angle:hover {
    background: rgb(35 76 227/50%)
}

.mail-reports ul li .ford-angle:hover img, .mail-reports ul li .ford-angle:hover i {
    filter: invert(1) brightness(100)
}

.mail-reports ul li {
    margin-right: 10px
}

.mail-reports ul li:last-child {
    margin-right: 0
}

.mail-view-list {
    margin-bottom: 25px
}

.mail-view-list li {
    margin-right: 10px
}

.mailview-inner p {
    color: rgba(51, 52, 72, .5);
    font-size: 16px;
    font-weight: 600
}

.thank-mail {
    margin-bottom: 40px
}

.thank-mail h5 {
    color: #35446f;
    font-size: 14px;
    margin-bottom: 0
}

.thank-mail h4 {
    color: #35446f;
    font-size: 16px;
    font-weight: 600
}

.forward-send .btn-primary {
    padding: 10px 15px;
    padding: 7px 23px;
    margin-bottom: 0;
    border: 2px solid transparent
}

.forward-send .forwrd-btn {
    background: 0 0;
    border: 2px solid #444;
    color: #444;
    font-weight: 600
}

.forward-send .forwrd-btn:hover {
    background: #444;
    border: 2px solid #444;
    color: #fff
}

.forward-send .forwrd-btn:hover img {
    filter: invert(1) brightness(100)
}

.custom-file-upload {
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), rgba(255, 255, 255, 0.2);
    border: 2px solid #fff;
    border-radius: 8px;
    display: inline-flex;
    transition: border-color .3s;
    width: 100%;
    font-size: 14px;
    cursor: pointer;
    margin-top: 0;
    height: 53px;
    color: #000;
    position: relative
}

.custom-file-upload input {
    display: none
}

.custom-file-upload .file-upload-text {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    padding-left: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.change-user {
    padding: 10px 18px;
    background: #6586bf;
    border-radius: 8px;
    font-size: 16px;
    color: #fff;
    position: absolute;
    right: 0;
    margin: 2px;
    min-width: 177px;
    text-align: center
}

.sender-img img {
    width: 48px;
    height: 48px
}

.send-user.send-user-name h4 {
    font-size: 16px
}

.send-user.send-user-name h4 span {
    padding: 5px 15px
}

.send-user p span {
    color: #35446f;
    font-weight: 600
}

.mail-reports ul li .ford-angle.star-bg {
    background: 0 0;
    margin-right: 0
}

.ford-angle.star-bg i {
    color: #ffb627
}

.mail-attach .msg-sub-list li {
    max-width: 100%;
    margin-bottom: 0;
    margin-right: 10px
}

.mail-attach .msg-sub-list li i {
    color: #fe3f51
}

.mail-attach {
    margin-bottom: 40px
}

.view-content-bottom {
    border-bottom: 2px solid #fff;
    padding-bottom: 40px;
    margin-bottom: 40px
}

.mail-attach h2 {
    color: #35446f;
    font-size: 18px;
    font-weight: 600;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between
}

.mail-attach h2 a {
    color: #444
}

.setting-title p {
    margin-bottom: 0
}

.page-content {
    width: 100%
}

.join-call {
    max-width: 950px;
    width: 100%;
    margin: 0 auto;
    padding-top: 50px
}

.join-video {
    position: relative;
    margin-bottom: 30px
}

.join-video img {
    border-radius: 10px;
    object-fit: cover
}

.join-video.video-hide > img, .video-wrap.video-hide > video {
    display: none
}

.video-avatar {
    color: #fff;
    background: #5d5d5d;
    font-size: 50px;
    border-radius: 10px;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.video-avatar.active {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.video-avatar h4 {
    font-weight: 700;
    font-size: 24px;
    color: #fff
}

.join-content {
    padding-bottom: 25px
}

.join-content h3 {
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 37px
}

.join-content label {
    font-size: 20px;
    margin-bottom: 1.25rem
}

.icon-mic {
    background: #31aa52;
    border-radius: 10px;
    width: 40px;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff
}

.icon-mic i {
    color: #fff
}

.btn-join {
    font-weight: 700;
    font-size: 20px;
    padding: 14px 20px;
    min-width: 192px;
    border-radius: 10px
}

.btn-infos {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.btn-infos .btn-join {
    margin-right: 18px
}

.btn-infos .icon-mic {
    margin-right: 15px
}

.btn-infos span {
    font-size: 18px;
    color: #808184
}

.call-icons {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0
}

.call-icons .call-items {
    border-radius: 5px;
    padding: 0;
    margin: 0;
    list-style: none;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translate(-50%, 0)
}

.call-icons .call-items .call-item {
    display: inline-block;
    text-align: center;
    margin-right: 15px
}

.call-icons .call-items .call-item:last-child {
    margin-right: 0
}

.call-icons .call-items .call-item a {
    color: #fff;
    border: 1px solid #fff;
    background: rgba(255, 255, 255, .07);
    box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    border-radius: 10px;
    width: 60px;
    height: 60px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 20px;
    white-space: nowrap
}

.call-icons .call-items .call-item a:hover {
    opacity: .8
}

.call-icons .call-items .call-item .mute-video.stop, .call-icons .call-items .call-item .mute-bt.stop {
    background: #eb4132;
    border: 1px solid #eb4132
}

.set-icon {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 1
}

.vert-icon, a.vert-icon {
    width: 40px;
    height: 40px;
    background: #fff;
    box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    border-radius: 10px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 18px;
    color: #000
}

.voice-sec1 {
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute
}

.voice-sec {
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 1
}

.voice-icon, a.voice-icon {
    width: 40px;
    height: 40px;
    background: #31aa52;
    box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    border-radius: 10px;
    font-size: 18px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    color: #fff
}

.voice-icon:hover, a.voice-icon:hover, .vert-icon:hover, a.vert-icon:hover {
    opacity: .8
}

.meet-icons {
    padding: 40px 0 20px;
    margin-top: 40px;
    border-top: 2px solid rgba(46, 55, 164, .05);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: center
}

.meet-icons .meet-items, .action-items {
    padding: 0;
    margin: 0;
    list-style: none;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex
}

.meet-icons .meet-items .meet-item, .action-items .action-item {
    display: inline-block;
    text-align: center;
    margin-right: 15px
}

.meet-icons .meet-items .meet-item:last-child, .action-items .action-item:last-child {
    margin-right: 0
}

.meet-icons .meet-items .meet-item > a {
    color: #636e8e;
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), rgba(255, 255, 255, 0.2);
    border: 1px solid #fff;
    border-radius: 8px;
    width: 50px;
    height: 50px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 20px
}

.meet-icons .meet-items .meet-item > a:hover img {
    filter: invert(1) brightness(100)
}

.meet-icons .meet-items .meet-item > a:hover, .action-items .action-item a:hover {
    opacity: .8;
    background: #35446f
}

.meet-icons .meet-items .meet-item > a.showChatList.active, .meet-icons .meet-items .meet-item > a.showInviteList.active, .meet-icons .meet-items .meet-item > a.hand-raise.active, .action-items .action-item a.record-icon.active {
    background: #0074e0
}

.meet-icons .meet-items .meet-item > a.mute-bt.stop, .meet-icons .meet-items .meet-item > a.mute-video.stop {
    background: #636e8e
}

.meet-icons .meet-items .meet-item > a.mute-video.stop .feather-video-off {
    filter: invert(1) brightness(100)
}

.meet-icons .meet-items .meet-item > a.show {
    background: #0074e0
}

.action-items .action-item a {
    width: 80px;
    height: 50px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 20px;
    box-shadow: 0 15px 15px rgba(229, 229, 229, .32);
    border-radius: 10px;
    color: #fff
}

.action-items .action-item a.share-icon {
    background: #eb4132
}

.action-items .action-item a.callend-icon {
    background: #0074e0
}

.action-items .action-item a.record-icon {
    background: #31aa52
}

.end-calls {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.action-icons {
    text-align: center
}

.part-name {
    position: absolute;
    top: 20px;
    left: 20px;
    text-align: left
}

.part-name h4 {
    font-weight: 0;
    font-size: 10px;
    color: #fff;
    margin-bottom: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background: #35446f;
    opacity: .75;
    backdrop-filter: blur(2.5px);
    border-radius: 8px;
    padding: 10px;
    display: inline-block
}

.more-icon {
    position: absolute;
    top: 20px;
    right: 20px
}

.more-icon a {
    color: #fff;
    background: #35446f;
    opacity: .75;
    border-radius: 8px;
    width: 38px;
    height: 38px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center
}

.single-user .more-icon a {
    width: 30px;
    height: 30px
}

.more-icon a:first-child {
    padding-left: 0
}

.more-icon a:hover {
    opacity: .8
}

.meeting .part-name {
    top: inherit;
    left: 15px;
    bottom: 15px
}

.meeting .more-icon {
    top: 15px;
    right: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.meeting-wrapper, .meeting {
    width: inherit;
    max-width: inherit
}

.show-chat-list, .show-invite-list, .show-chat-list .user-sidebar-right, .show-invite-list .invite-sidebar-right {
    transition: all .8s ease
}

.user-sidebar-right, .invite-sidebar-right {
    background: #dedede;
    border-radius: 10px;
    width: 410px;
    position: absolute;
    top: 105px;
    bottom: 117px;
    right: 0;
    margin-right: -410px;
    transition: all .8s ease;
    overflow: hidden;
    font-size: 13px;
    z-index: 3
}

.join-contents {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    box-sizing: border-box;
    width: 100%;
    max-width: 950px;
    margin: 0 auto;
    overflow: hidden
}

.join-contents.grid-view {
    align-content: center
}

.ripple {
    width: 114px;
    height: 114px;
    background: rgba(0, 0, 0, .2);
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.multigrid-view .text-box {
    height: 61px;
    width: 61px
}

.multigrid-view .ripple {
    width: 80px;
    height: 80px;
    background: rgba(0, 0, 0, .2);
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.meeting .join-video {
    margin: 0 5px 10px;
    width: 100%;
    height: 309px
}

.join-video.user-active {
    background: #415b6a;
    border-radius: 10px
}

.join-video.single-user {
    width: 23%;
    flex-grow: 1;
    box-sizing: border-box;
    margin: 0 5px 10px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    text-align: center;
    height: auto;
    background: #415b6a;
    border-radius: 10px;
    padding: 0
}

.multigrid-view .join-video.single-user {
    width: calc(20% - 10px);
    flex-grow: 0
}

.join-video.single-user img {
    height: auto
}

.text-avatar h4 {
    color: #fff
}

.text-avatar {
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.text-box {
    height: 86px;
    width: 86px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, .2);
    font-size: 36px;
    font-weight: 700;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.settings-menu {
    background: #e4eeff;
    box-shadow: 0 4px 4px rgba(0, 0, 0, .13);
    border-radius: 10px;
    padding: 0 15px;
    margin: 25px 0 25px -16px !important
}

.settings-menu li a {
    padding: 12px 0;
    border-bottom: 1px solid #fff;
    width: auto;
    height: auto;
    background: #e4eeff;
    font-weight: 600;
    font-size: 12px;
    color: #000
}

.settings-menu li a:focus, .settings-menu li a:hover {
    color: #000;
    background-color: #e4eeff
}

.settings-menu li:last-child a {
    border: 0
}

.settings-menu .user-img {
    font-size: 18px
}

.settings-menu .user-img img {
    width: 36px;
    border-radius: 10px
}

.overlay-icon {
    background: rgba(0, 0, 0, .7);
    border-radius: 10px;
    width: 44px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 42px;
    left: 15px;
    opacity: 0;
    transition: opacity .3s linear
}

.overlay-icon a {
    color: #fff;
    padding: 3px 0;
    font-size: 14px;
    text-align: center
}

.overlay-icon a:hover {
    opacity: .8
}

.join-video.single-user::before, .meeting .join-video::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .7);
    content: "";
    border-radius: 10px;
    opacity: 0;
    transition: opacity .3s linear
}

.join-video.single-user:hover::before, .meeting .join-video:hover::before {
    opacity: 1
}

.join-video.single-user:hover .overlay-icon, .join-video:hover .overlay-icon {
    opacity: 1
}

.more-icon.mic-view .mic-off {
    display: block
}

.grid-view .join-video.single-user {
    width: 48%
}

.join-contents.multigrid-view {
    max-width: 100%;
    padding: 25px 100px 0;
    justify-content: center
}

.meeting-list {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative
}

.user-active .more-icon .handraise-on.active {
    display: block;
    animation: mymove 5s
}

.user-active .more-icon .handraise-on.active .meeting .join-video.user-active::before {
    animation: mymove 5s infinite;
    opacity: 1
}

@keyframes mymove {
    from {
        color: #0074e0
    }
    to {
        color: transparent
    }
}

.join-contents.vertical-view {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    align-content: center;
    max-width: 1300px
}

.join-contents.vertical-view .join-video.user-active {
    flex: 1;
    margin-bottom: 5px
}

.join-contents.vertical {
    flex-direction: column;
    height: 200px
}

.join-contents.vertical-view .join-video.single-user {
    width: 97%;
    flex-grow: 1
}

.meeting .join-contents.vertical .join-video.user-active {
    flex: 1 0 100%
}

.meeting .join-contents.vertical .join-video.single-user {
    flex: 0
}

.meeting .join-contents.vertical .join-video {
    width: auto
}

.ptro-holder-wrapper {
    top: 80px !important;
    position: relative !important;
    height: auto !important;
    bottom: 300px;
    z-index: 4
}

.chat-footer .btn:hover {
    background: #0066c5;
    color: #fff
}

.person-view {
    width: 40px;
    height: 40px;
    background: #415b6a;
    border-radius: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 16px;
    color: #fff;
    font-weight: 700;
    font-size: 14px
}

.end-call-chat .btn-primary {
    min-width: 136px;
    background: #fe3f51;
    border-radius: 8px;
    margin: 0 20px;
    padding: 12px 15px;
    font-size: 16px
}

.chat-main-row {
    bottom: 0;
    left: 0;
    overflow: auto;
    padding-bottom: inherit;
    padding-top: inherit;
    position: absolute;
    right: 0;
    top: 0
}

.chat-main-wrapper {
    display: table;
    height: 100%;
    table-layout: fixed;
    width: 100%
}

.message-view {
    display: table-cell;
    height: 100%;
    float: none;
    padding: 0;
    position: static;
    vertical-align: top;
    width: 75%
}

.chat-window {
    display: table;
    height: 100%;
    table-layout: fixed;
    width: 100%;
    background-color: #f5f5f5
}

.fixed-header {
    background-color: #fff;
    border-bottom: 1px solid #eaeaea;
    padding: 10px 15px
}

.fixed-header .navbar {
    border: 0;
    margin: 0;
    min-height: auto;
    padding: 0
}

.fixed-header .user-info a {
    color: #76838f;
    text-transform: uppercase
}

.typing-text {
    color: #444;
    font-size: 12px;
    text-transform: lowercase
}

.last-seen {
    color: #a3afb7;
    display: block;
    font-size: 12px
}

.custom-menu.nav > li > a {
    color: #444;
    font-size: 26px;
    margin-left: 15px;
    padding: 0
}

.custom-menu.navbar-nav > li > a:hover, .custom-menu.navbar-nav > li > a:focus {
    background-color: transparent
}

.custom-menu .dropdown-menu {
    left: auto;
    right: 0
}

.search-box .input-group {
    background-color: #e6e6e6;
    border: 1px solid #ddd;
    border-radius: 50px
}

.search-box .input-group .form-control {
    background: 0 0;
    border: none
}

.search-box .btn {
    background-color: transparent
}

.chat-contents {
    display: table-row;
    height: 100%
}

.chat-content-wrap {
    height: 100%;
    position: relative;
    width: 100%
}

.chat-wrap-inner {
    bottom: 0;
    left: 0;
    overflow: auto;
    position: absolute;
    right: 0;
    top: 0
}

.chats {
    padding: 30px 15px
}

.chat-body {
    display: block;
    margin: 10px 0 0;
    overflow: hidden
}

.chat-body:first-child {
    margin-top: 0
}

.chat-right .chat-content {
    position: relative;
    display: block;
    float: right;
    padding: 8px 15px;
    margin: 0;
    clear: both;
    background-color: #e6e6e6;
    border-radius: 20px 2px 2px 20px;
    max-width: 60%
}

.chat-right .chat-body {
    padding-left: 48px;
    padding-right: 10px
}

.chat-bubble {
    display: block;
    width: 100%;
    float: left;
    margin-bottom: 10px
}

.chat.chat-right .chat-bubble:last-child .chat-content {
    border-bottom-right-radius: 20px
}

.chat.chat-right .chat-bubble:first-child .chat-content {
    border-top-right-radius: 20px
}

.chat-content > p {
    margin-bottom: 0;
    word-wrap: break-word
}

.chat-time {
    color: rgba(0, 0, 0, .5);
    display: block;
    font-size: 12px
}

.chat-right .chat-time {
    text-align: right
}

.chat-line {
    border-bottom: 1px solid #eaeaea;
    height: 12px;
    margin: 7px 0 20px;
    position: relative;
    text-align: center
}

.chat-date {
    background-color: #f5f5f5;
    color: #000;
    font-size: 12px;
    padding: 0 11px
}

.chat-avatar {
    float: right
}

.chat-avatar .avatar {
    line-height: 30px;
    height: 30px;
    width: 30px
}

.chat-left .chat-avatar {
    float: left
}

.chat-left .chat-body {
    margin-right: 0;
    margin-left: 30px;
    padding-right: 20px
}

.chat-left .chat-content {
    background-color: #fff;
    border: 1px solid #eaeaea;
    color: #888;
    float: left;
    position: relative;
    padding: 8px 15px;
    border-radius: 2px 20px 20px 2px;
    max-width: 60%
}

.avatar {
    background-color: #aaa;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    font-weight: 500;
    height: 38px;
    line-height: 38px;
    margin: 0 10px 0 0;
    overflow: hidden;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
    width: 38px;
    position: relative;
    white-space: nowrap
}

.avatar:hover {
    color: #fff
}

.avatar > img {
    width: 100%;
    display: block
}

.chat.chat-left .chat-bubble:first-child .chat-content {
    border-top-left-radius: 20px;
    margin-top: 0
}

.chat.chat-left .chat-bubble:last-child .chat-content {
    border-bottom-left-radius: 20px
}

.chat-left .chat-time {
    color: #a3afb7
}

.attach-list {
    color: #adb7be;
    padding: 5px 0 0;
    line-height: 24px;
    margin: 0;
    list-style: none
}

.attach-list i {
    margin-right: 3px;
    font-size: 16px
}

.attach-list a {
    word-wrap: break-word
}

.attach-img {
    margin-bottom: 20px
}

.attach-img > img {
    border: 1px solid #eaeaea;
    max-width: 100%
}

.attach-img-download {
    margin-bottom: 5px
}

.chat-user {
    color: #333;
    font-size: 13px
}

.chat-footer {
    background-color: #fff;
    border-top: 1px solid #eaeaea;
    padding: 15px
}

.message-bar {
    display: table;
    height: 44px;
    position: relative;
    width: 100%
}

.message-bar .message-inner {
    display: table-row;
    height: 100%;
    padding: 0 8px;
    width: 100%
}

.message-bar .link {
    color: #777;
    display: table-cell;
    font-size: 20px;
    padding: 0 10px;
    position: relative;
    vertical-align: middle;
    width: 30px
}

.message-bar .message-area {
    display: table-cell
}

.message-area .input-group .form-control {
    background-color: #fff;
    border: 1px solid #eaeaea;
    box-shadow: none;
    color: #555;
    display: block;
    font-size: 14px;
    height: 44px;
    margin: 0;
    padding: 6px 12px;
    resize: none
}

.message-area .input-group .form-control:focus {
    height: 150px;
    transition: all .5s ease 0s
}

.message-area .btn {
    height: 44px;
    width: 50px
}

.profile-right {
    display: table-cell;
    height: 100%;
    float: none;
    padding: 0;
    position: static;
    vertical-align: top
}

.display-table {
    display: table;
    table-layout: fixed;
    border-spacing: 0;
    width: 100%;
    height: 100%
}

.table-row {
    display: table-row;
    height: 100%
}

.table-body {
    position: relative;
    height: 100%;
    width: 100%
}

.table-content {
    bottom: 0;
    left: 0;
    overflow: auto;
    position: absolute;
    right: 0;
    top: 0
}

.profile-right-inner {
    border-left: 1px solid #eaeaea;
    background-color: #fff
}

.chat-profile-img {
    padding: 30px;
    position: relative;
    text-align: center
}

.edit-profile-img {
    height: 120px;
    margin: 0 auto;
    position: relative;
    width: 120px;
    cursor: pointer
}

.change-img {
    background-color: rgba(0, 0, 0, .3);
    border-radius: 50%;
    color: #fff;
    display: none;
    height: 100%;
    left: 0;
    line-height: 120px;
    position: absolute;
    top: 0;
    width: 100%
}

.edit-profile-img:hover .change-img {
    display: block
}

.edit-profile-img img {
    height: auto;
    margin: 0;
    width: 120px;
    border-radius: 50%
}

.user-name {
    color: #333
}

.edit-btn {
    border-radius: 40px;
    height: 36px;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 36px
}

.chat-profile-info {
    padding: 15px
}

.user-det-list {
    list-style: none;
    padding: 0
}

.user-det-list > li {
    padding: 6px 15px
}

.user-det-list .text-muted {
    color: #888
}

.transfer-files .tab-content {
    padding-top: 0
}

.files-list {
    list-style: none;
    padding-left: 0
}

.files-list > li {
    border-bottom: 1px solid #eaeaea;
    border-radius: inherit;
    margin: 2px 0 0;
    padding: 10px
}

.files-cont {
    position: relative
}

.file-type {
    height: 48px;
    position: absolute;
    width: 48px
}

.files-icon i {
    color: #76838f;
    font-size: 20px
}

.files-info {
    padding: 0 30px 0 50px
}

.file-name a {
    color: #333
}

.files-action {
    display: none;
    height: 30px;
    list-style: none;
    padding-left: 0;
    position: absolute;
    right: 0;
    text-align: right;
    top: 10px;
    width: 30px
}

.files-list > li:hover .files-action {
    display: block
}

.file-date {
    color: #888;
    font-size: 12px
}

.file-author a {
    color: #444;
    font-size: 12px;
    text-decoration: underline
}

.files-action .dropdown-menu {
    left: auto;
    right: 0
}

.chat-img-attach {
    border-radius: 4px;
    margin-bottom: 12px;
    margin-left: 12px;
    position: relative;
    float: right
}

.chat-img-attach img {
    border-radius: 4px;
    display: inline-block;
    height: auto;
    max-width: 100%
}

.chat-placeholder {
    background: rgba(69, 81, 97, .6);
    border-radius: 4px;
    color: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
    left: 0;
    padding: 13px;
    position: absolute;
    top: 0;
    width: 100%
}

.chat-img-name {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px
}

.chat-file-desc {
    font-size: 11px
}

.chat-right .chat-content.img-content {
    background-color: transparent;
    color: #76838f;
    padding: 0;
    text-align: right
}

.chat-right .img-content .chat-time {
    color: #a3afb7
}

.chat-left .chat-img-attach {
    float: left
}

.chat-left .chat-img-attach {
    float: left;
    margin-left: 0;
    margin-right: 12px
}

.chat-avatar-sm {
    width: 24px;
    margin-right: 10px;
    display: inline-block;
    position: relative;
    float: left
}

.chat-avatar-sm img {
    width: 24px
}

.chat-avatar-sm.user-img .status {
    bottom: 4px;
    right: -3px
}

.files-share-list {
    border: 1px solid #eaeaea;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 15px
}

.files-share-list {
    background-color: #f9f9f9;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 15px
}

.chat-user-list {
    list-style: none;
    margin: 0;
    padding: 0
}

.chat-user-list > li {
    border-bottom: 1px solid #eaeaea;
    border-radius: inherit;
    margin: 5px 0 0;
    padding: 10px;
    background-color: #fff
}

.designation {
    color: #9e9e9e;
    font-size: 12px
}

.online-date {
    color: #9e9e9e;
    font-size: 12px
}

.drop-zone {
    width: 100%;
    height: 205px;
    border: 2px dashed #adb7be;
    text-align: center;
    padding: 25px 0 0;
    margin-bottom: 20px
}

.drop-zone .drop-zone-caption {
    font-weight: 600
}

.upload-list {
    padding: 0;
    list-style: none;
    margin: 0
}

.upload-list .file-list {
    background-color: #fff;
    border-top: 1px solid #d8e2e7;
    padding: 10px
}

.upload-list .file-list:first-child {
    border-top: none
}

.upload-list .upload-wrap {
    position: relative;
    padding: 0 20px 0 0;
    margin: 0 0 5px
}

.upload-list .file-name, .upload-list .file-size {
    display: inline-block;
    vertical-align: top;
    white-space: nowrap
}

.upload-list .file-name {
    padding-right: 15px;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis
}

.upload-list .file-size {
    color: #888
}

.upload-list .file-close {
    border: none;
    background: 0 0;
    color: #ccc;
    position: absolute;
    right: 0;
    top: 2px
}

.upload-list .file-close:hover {
    color: #f62d51
}

.upload-list .upload-process {
    font-size: 10px;
    color: #888
}

.upload-list .progress {
    margin-bottom: 5px;
    background-color: #e1e1e1;
    height: 5px
}

.upload-list .file-name i {
    color: #fda75c;
    margin: 0 5px 0 0;
    vertical-align: middle
}

.upload-drop-zone {
    height: 200px;
    border-width: 2px;
    margin-bottom: 20px;
    color: #ccc;
    border-style: dashed;
    border-color: #adb7be;
    line-height: 200px;
    text-align: center;
    background-color: #fff
}

.upload-drop-zone.drop {
    color: #222;
    border-color: #222
}

.upload-text {
    font-size: 24px;
    margin-left: 10px
}

.file-attached {
    color: #333;
    font-size: 13px
}

.file-attached i {
    font-size: 16px
}

.form-focus {
    display: flex;
    height: 50px;
    position: relative
}

.form-focus .focus-label {
    font-size: 16px;
    font-weight: 400;
    opacity: .4;
    pointer-events: none;
    position: absolute;
    -webkit-transform: translate3d(0, 22px, 0) scale(1);
    -ms-transform: translate3d(0, 22px, 0) scale(1);
    -o-transform: translate3d(0, 22px, 0) scale(1);
    transform: translate3d(0, 22px, 0) scale(1);
    transform-origin: left top;
    transition: 240ms;
    left: 12px;
    top: -8px;
    z-index: 1;
    color: #888
}

.form-focus.focused .focus-label {
    opacity: 1;
    font-weight: 300;
    top: -14px;
    font-size: 12px;
    z-index: 1
}

.form-focus .form-control {
    height: 50px;
    padding: 21px 12px 6px
}

.form-focus .form-control::-webkit-input-placeholder {
    color: transparent;
    transition: 240ms
}

.form-focus .form-control:focus::-webkit-input-placeholder {
    transition: none
}

.form-focus.focused .form-control::-webkit-input-placeholder {
    color: #bbb
}

.profile-basic .cal-icon {
    width: 100%
}

/* .select2-container .select2-selection--single  */
.form-focus {
    height: 50px;
    border-radius: 6px
}

/* .select2-container--default .select2-selection--single .select2-selection__arro */
.form-focus w {
    height: 48px;
    right: 7px
}

/*  .select2-container--default .select2-selection--single .select2-selection__arrow b */
.form-focus {
    border-color: #ccc transparent transparent;
    border-style: solid;
    border-width: 6px 6px 0;
    height: 0;
    left: 50%;
    margin-left: -10px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    width: 0
}
/*  .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b */

.form-focus {
    border-color: transparent transparent #ccc;
    border-width: 0 6px 6px
}

/* .select2-container .select2-selection--single .select2-selection__rendered */
.form-focus  {
    padding-right: 30px;
    padding-left: 12px;
    padding-top: 10px
}

/*  .select2-container--default .select2-selection--single .select2-selection__rendered */
.form-focus {
    color: #676767;
    font-size: 14px;
    font-weight: 400;
    line-height: 38px
}

/*  .select2-container--default .select2-results__option--highlighted[aria-selected]  */
.form-focus{
    background-color: #fc6075
}

.file-size {
    color: #888
}

.filter-row .btn {
    border-radius: 8px;
    padding: 9px 15px;
    text-transform: uppercase
}

.form-focus.select-focus .focus-label {
    opacity: 1;
    font-weight: 300;
    top: -20px;
    font-size: 12px;
    z-index: 1
}

.action-label .label {
    display: inline-block;
    min-width: 85px;
    padding: .5em .6em
}

.action-label i {
    margin-right: 3px
}

.action-label .dropdown-menu > li > a {
    padding: 3px 10px
}

.action-icon {
    color: #777;
    font-size: 18px;
    padding: 0 10px;
    display: inline-block
}

.table .dropdown-menu {
    font-size: 12px
}

.profile-widget {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .1);
    margin-bottom: 30px;
    padding: 20px;
    text-align: center;
    position: relative;
    overflow: hidden
}

.profile-widget .user-name > a {
    color: #333
}

.dropdown.profile-action {
    position: absolute;
    right: 5px;
    text-align: right;
    top: 10px
}

.profile-action .dropdown-toggle:after {
    display: none
}

.profile-img {
    cursor: pointer;
    height: 80px;
    margin: 0 auto;
    position: relative;
    width: 80px
}

.profile-img .avatar {
    font-size: 24px;
    height: 80px;
    line-height: 80px;
    margin: 0;
    width: 80px
}

.mobile-no > a {
    color: #777;
    display: inline-block
}

.staff-mail > a {
    color: #777;
    display: inline-block;
    margin-top: 5px
}

.staff-action-btns {
    margin-top: 10px
}

.staff-id {
    margin-top: 5px
}

.view-icons {
    float: right;
    margin-right: 10px
}

.view-icons .btn {
    color: #888;
    font-size: 18px;
    margin-right: 5px;
    padding: 4px
}

.view-icons .btn.active {
    color: #333
}

.calendar {
    float: left;
    margin-bottom: 0
}

.fc-view {
    margin-top: 30px
}

a.fc-event:not([href]) {
    color: #fff
}

.none-border .modal-footer {
    border-top: none
}

.fc-toolbar h2 {
    font-size: 18px;
    font-weight: 600;
    font-family: rubik, sans-serif;
    line-height: 30px;
    text-transform: uppercase
}

.fc-day-grid-event .fc-time {
    font-family: rubik, sans-serif
}

.fc-day {
    background: #fff
}

.fc-toolbar .fc-state-active, .fc-toolbar .ui-state-active, .fc-toolbar button:focus, .fc-toolbar button:hover, .fc-toolbar .ui-state-hover {
    z-index: 0
}

.fc th.fc-widget-header {
    background: #35446f;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 0;
    text-transform: capitalize;
    color: #fff
}

.fc-unthemed th, .fc-unthemed td, .fc-unthemed thead, .fc-unthemed tbody, .fc-unthemed .fc-divider, .fc-unthemed .fc-row, .fc-unthemed .fc-popover {
    border-color: #f3f3f3
}

.fc-button {
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), rgba(255, 255, 255, 0.2);
    border: 2px solid #fff;
    color: #797979;
    text-transform: capitalize;
    box-shadow: none !important;
    padding: 6px 12px !important;
    height: auto !important
}

.fc-text-arrow {
    font-family: inherit;
    font-size: 16px
}

.fc-state-hover {
    background: #f3f3f3
}

.fc-state-highlight {
    background: #f0f0f0
}

.fc-state-down, .fc-state-active, .fc-state-disabled {
    background: #35446f;
    color: #fff;
    text-shadow: none
}

.fc-cell-overlay {
    background: #f0f0f0
}

.fc-unthemed .fc-today {
    background: #fff
}

.fc-event {
    border-radius: 2px;
    border: none;
    cursor: move;
    font-size: 13px;
    margin: 1px 7px;
    padding: 5px;
    text-align: center
}

.fc-basic-view td.fc-week-number span {
    padding-right: 8px;
    font-weight: 700;
    font-family: rubik, sans-serif
}

.fc-basic-view td.fc-day-number {
    padding-right: 8px;
    font-weight: 700;
    font-family: rubik, sans-serif
}

.event-add .btn-primary {
    border-radius: 8px;
    min-width: 248px;
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 600
}

.card-box.profile-header {
    margin: 0
}

.profile-details {
    text-align: center
}

.personal-info li .title {
    color: #515365;
    float: left;
    font-weight: 500;
    margin-right: 30px;
    width: 30%
}

.personal-info li .text {
    color: #888da8;
    display: block;
    overflow: hidden
}

.personal-info li {
    margin-bottom: 10px
}

.personal-info {
    list-style: none;
    margin-bottom: 0;
    padding: 0
}

.personal-info .text > a {
    color: #444
}

.profile-view .profile-img-wrap {
    height: 150px;
    width: 150px
}

.profile-view .profile-img {
    width: 150px;
    height: 150px
}

.profile-view .profile-img .avatar {
    font-size: 24px;
    height: 150px;
    line-height: 150px;
    margin: 0;
    width: 150px
}

.profile-view .profile-basic {
    margin-left: 170px
}

.staff-msg {
    margin-top: 30px
}

.experience-box {
    position: relative
}

.experience-list {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative
}

.experience-list:before {
    background: #ddd;
    bottom: 0;
    content: "";
    left: 8px;
    position: absolute;
    top: 8px;
    width: 2px
}

.experience-list > li {
    position: relative
}

.experience-list > li:last-child .experience-content {
    margin-bottom: 0
}

.experience-user .avatar {
    height: 32px;
    line-height: 32px;
    margin: 0;
    width: 32px
}

.experience-list > li .experience-user {
    background: #fff;
    height: 10px;
    left: 4px;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 4px;
    width: 10px
}

.experience-list > li .experience-content {
    margin: 0 0 20px 40px;
    padding: 0;
    position: relative
}

.experience-list > li .experience-content .timeline-content {
    color: #9e9e9e
}

.experience-list > li .experience-content .timeline-content a.name {
    color: #616161;
    font-weight: 700
}

.experience-list > li .time {
    color: #bdbdbd;
    display: block;
    font-size: 12px;
    line-height: 1.35
}

.before-circle {
    background-color: #ddd;
    border-radius: 50%;
    height: 10px;
    width: 10px
}

.skills > span {
    border: 1px solid #ccc;
    border-radius: 500px;
    display: block;
    margin-bottom: 10px;
    padding: 6px 12px;
    text-align: center
}

.profile-info-left {
    border-right: 2px dashed #ccc
}

.profile-tabs .nav-tabs {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .1)
}

.notifications {
    padding: 0
}

.notifications .drop-scroll {
    height: 290px;
    width: 400px
}

.notifications.msg-noti .drop-scroll {
    width: 310px
}

.notifications .notification-heading {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.45;
    color: #616161
}

.notifications .notification-time {
    font-size: 12px;
    line-height: 1.35;
    color: #bdbdbd
}

.notification-list {
    list-style: none;
    padding: 0;
    margin: 0
}

.notifications ul.notification-list > li {
    margin-top: 0;
    border-bottom: 1px solid #f5f5f5
}

.notifications ul.notification-list > li:last-child {
    border-bottom: none
}

.notifications ul.notification-list > li a {
    display: block;
    padding: 12px;
    border-radius: 2px
}

.notifications ul.notification-list > li a:hover {
    background-color: #fafafa
}

.drop-scroll {
    overflow-y: scroll;
    position: relative
}

.topnav-dropdown-header {
    border-bottom: 1px solid #eee;
    text-align: center
}

.topnav-dropdown-header, .topnav-dropdown-footer {
    font-size: 12px;
    height: 32px;
    line-height: 32px;
    padding-left: 12px;
    padding-right: 12px;
    text-transform: capitalize
}

.topnav-dropdown-footer a {
    display: block;
    text-align: center;
    color: #333
}

.user-menu.nav > li > a .badge {
    color: #fff;
    font-size: 60%;
    font-weight: 700;
    position: absolute;
    right: 3px;
    top: 6px
}

.user-menu.nav > li > a > i {
    font-size: 16px;
    line-height: 55px
}

.noti-details {
    color: #989c9e;
    margin: 0
}

.noti-time {
    margin: 0
}

.noti-title {
    color: #333
}

.roles-menu {
    margin-top: 20px
}

.roles-menu > ul {
    background-color: #fff;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    list-style: none;
    margin: 0;
    padding: 0
}

.roles-menu > ul > li {
    display: block;
    position: relative
}

.roles-menu > ul > li > a {
    border-left: 3px solid transparent;
    color: #333;
    display: block;
    padding: 10px 15px;
    position: relative
}

.roles-menu > ul > li.active > a {
    border-color: #444;
    color: #444
}

.roles-menu > ul > li > a:hover {
    border-color: #444;
    background-color: #eee
}

.material-switch > input[type=checkbox] {
    display: none
}

.material-switch > label {
    cursor: pointer;
    height: 0;
    position: relative;
    width: 40px
}

.material-switch > label:before {
    background: #000;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, .5);
    border-radius: 8px;
    content: '';
    height: 16px;
    margin-top: -8px;
    position: absolute;
    opacity: .3;
    transition: all .4s ease-in-out;
    width: 40px
}

.material-switch > label:after {
    background: #fff;
    border-radius: 16px;
    box-shadow: 0 0 5px rgba(0, 0, 0, .3);
    content: '';
    height: 24px;
    left: -4px;
    margin-top: -8px;
    position: absolute;
    top: -4px;
    transition: all .3s ease-in-out;
    width: 24px
}

.material-switch > input[type=checkbox]:checked + label:before {
    background: inherit;
    opacity: .5
}

.material-switch > input[type=checkbox]:checked + label:after {
    background: inherit;
    left: 20px
}

.role-action {
    display: none;
    float: right;
    position: absolute;
    right: 8px;
    top: 8px
}

.roles-menu > ul > li:hover .role-action {
    display: block
}

.slide-nav .sidebar {
    margin-left: 0
}

.action-circle {
    background: 0 0;
    border: 1px solid #ccc;
    border-radius: 100%;
    height: 20px;
    width: 20px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease
}

.action-circle .material-icons {
    color: #ccc;
    font-size: 18px;
    vertical-align: -3px
}

.action-circle.completed {
    background: #35ba67;
    border: 1px solid #2fa65c
}

.action-circle.completed .material-icons {
    color: #fff
}

.action-circle.large {
    height: 24px;
    width: 24px
}

.action-circle.large .material-icons {
    font-size: 16px;
    vertical-align: -4px
}

.sidebar-overlay {
    display: none;
    position: fixed;
    z-index: 90;
    height: 100%;
    left: 0;
    top: 50px;
    width: 100%;
    z-index: 99;
    background-color: rgb(0 0 0 / 0%);
    overflow: hidden
}

.menu-opened .sidebar-overlay {
    display: block
}

.sidebar-menu.opened {
    opacity: 1;
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
    margin-left: 0
}

html.menu-opened {
    overflow: hidden
}

html.menu-opened body {
    overflow: hidden
}

.chat-profile-view.opened {
    margin-right: 0
}

.menu-opened .message-view.chat-view {
    width: 100%
}

.fixed-header .custom-menu {
    margin: 1px 0 0
}

.topnav-dropdown-footer {
    border-top: 1px solid #eee
}

.notification-box {
    bottom: 0;
    margin-right: -300px;
    position: fixed;
    right: 0;
    top: 60px;
    transition: all .4s ease 0s;
    width: 300px;
    z-index: 101;
    border-left: 1px solid #ddd
}

.open-msg-box .notification-box {
    margin-right: 0
}

.msg-sidebar {
    background-color: #fff
}

.list-box {
    list-style: none;
    padding-left: 0
}

.list-item.new-message {
    background-color: rgba(0, 0, 0, .2)
}

.list-item {
    border-bottom: 1px solid #eaeaea;
    padding: 10px;
    position: relative
}

.list-left {
    height: 48px;
    position: absolute;
    width: 48px
}

.files-icon {
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 4px;
    display: inline-block;
    height: 38px;
    line-height: 38px;
    text-align: center;
    width: 38px
}

.files-icon i {
    color: #777;
    font-size: 20px
}

.list-body {
    padding: 0 0 0 50px
}

.message-author {
    color: #333;
    float: left;
    font-weight: 700;
    width: 175px;
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.message-time {
    color: #888;
    float: right;
    font-size: 11px
}

.message-content {
    color: #333;
    font-size: 13px;
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.invoice-details, .invoice-payment-details > li span {
    float: right;
    text-align: right
}

.attach-files > ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.attach-files li {
    display: inline-block;
    margin-right: 10px;
    position: relative
}

.attach-files li img {
    width: 50px
}

.file-remove {
    color: red;
    position: absolute;
    right: -6px;
    top: -7px
}

.attach-files li:last-child {
    margin-right: 0
}

.inv-logo {
    height: auto;
    margin-bottom: 20px;
    max-height: 100px;
    width: auto
}

.form-amt {
    border: 1px solid #fff
}

.doctor-img {
    cursor: pointer;
    height: 80px;
    margin: 0 auto 15px;
    position: relative;
    width: 80px
}

.doctor-img .avatar {
    font-size: 24px;
    height: 80px;
    line-height: 80px;
    margin: 0;
    width: 80px
}

.doc-prof {
    color: #777;
    font-size: 12px;
    margin-bottom: 10px
}

.doctor-name {
    margin: 0
}

.doctor-name a {
    color: #333
}

.see-all {
    margin-top: 30px;
    text-align: center
}

.see-all .see-all-btn {
    border: 1px solid #e2e2e2;
    border-radius: 5px;
    box-shadow: 0 0 15px rgba(0, 0, 0, .1);
    color: #777;
    display: inline-block;
    padding: 10px 20px
}

.profile-upload {
    display: flex
}

.upload-img {
    margin-right: 10px
}

.upload-img img {
    border-radius: 4px;
    height: 40px;
    width: 40px
}

.upload-input {
    width: 100%
}

.gen-label {
    display: block;
    margin-bottom: 18px
}

.submit-btn {
    border-radius: 8px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    min-width: 100%;
    padding: 8px 20px;
    text-transform: capitalize
}

.delete-modal .modal-body .btn {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    width: 90px;
    color: #333;
    margin-bottom: 20px
}

.delete-modal .modal-body .btn.btn-danger {
    color: #fff
}

.delete-modal .modal-body > h3 {
    font-size: 16px;
    font-weight: 700;
    margin: 15px 0 0
}

.custom-badge {
    border-radius: 4px;
    display: inline-block;
    font-size: 12px;
    min-width: 95px;
    padding: 4px 10px;
    text-align: center;
    font-weight: 500
}

.status-red, a.status-red {
    background-color: #ffe5e6;
    border: 1px solid #fe0000;
    color: #fe0000
}

.status-green, a.status-green {
    background: rgba(13, 191, 10, .13);
    border: 1px solid #0dbf0a;
    border-radius: 5px;
    color: #0dbf0a
}

.status-orange, a.status-orange {
    background-color: #fef5e4;
    border: 1px solid #ff9b01;
    color: #ff9b01
}

.status-blue, a.status-blue {
    background-color: #fee5e5;
    border: 1px solid #ff000f;
    color: #ff0015
}

.status-purple, a.status-purple {
    background-color: #f3e7fd;
    border: 1px solid #8f13fd;
    color: #8f13fd
}

.status-pink, a.status-pink {
    background-color: #ffe5f6;
    border: 1px solid #ff01a2;
    color: #ff01a2
}

.status-grey, a.status-grey {
    background-color: #ddd;
    border: 1px solid #818181;
    color: #818181
}

.btn-grey {
    background-color: #b2b2b2;
    border: 1px solid #b2b2b2
}

.modal-backdrop {
    background-color: #f1f1f1
}

.modal-backdrop.show {
    opacity: .9
}

.invoices-view {
    width: 70%;
    float: right
}

.payslip-title {
    margin-bottom: 20px;
    text-align: center;
    text-decoration: underline;
    text-transform: uppercase
}

.half-day {
    width: 15px
}

.table-inbox input[type=radio], .table-inbox input[type=checkbox] {
    cursor: pointer
}

.mail-list {
    list-style: none;
    padding: 0
}

.mail-list > li > a {
    color: #333;
    display: block;
    padding: 10px
}

.mail-list > li.active > a {
    color: #444;
    font-weight: 700
}

.unread .name, .unread .subject, .unread .mail-date {
    color: #000;
    font-weight: 600
}

.table-inbox .fa-star {
    color: #ffd200
}

.table-inbox .starred.fa-star {
    color: #ffd200
}

.table.table-inbox > tbody > tr > td, .table.table-inbox > tbody > tr > th, .table.table-inbox > tfoot > tr > td, .table.table-inbox > tfoot > tr > th, .table.table-inbox > thead > tr > td, .table.table-inbox > thead > tr > th {
    border-bottom: 1px solid #f2f2f2;
    border-top: 0
}

.table-inbox {
    font-size: 14px;
    margin-bottom: 0
}

.note-editor.note-frame {
    border: 1px solid #fff;
    border-radius: 8px;
    box-shadow: inherit
}

.note-editor.note-frame .note-statusbar {
    background: linear-gradient(51.64deg, rgba(255, 255, 255, 0) 34.2%, rgba(255, 255, 255, 0.2) 97.35%, rgba(255, 255, 255, 0) 160.66%), rgba(255, 255, 255, 0.2);
    border-radius: 8px
}

.note-editor.note-frame.fullscreen {
    top: 60px
}

.mail-title {
    font-weight: 700;
    text-transform: uppercase
}

.form-control.search-message {
    border-radius: 4px;
    margin-left: 5px;
    width: 180px;
    padding: .375rem .75rem;
    min-height: 35px;
    margin-bottom: 5px
}

.table-inbox tr {
    cursor: pointer
}

table.table-inbox tbody tr.checked {
    background-color: #ffc
}

.mail-label {
    margin-right: 5px
}

.attachments {
    list-style: none;
    margin: 0;
    padding: 0
}

.attachments li {
    border: 1px solid #eee;
    float: left;
    margin-bottom: 10px;
    margin-right: 10px;
    width: 200px
}

.attach-file {
    color: #777;
    font-size: 70px;
    padding: 10px;
    text-align: center;
    min-height: 153px
}

.attach-file > i {
    line-height: 133px
}

.attach-info {
    background-color: #f4f4f4;
    padding: 10px
}

.attach-filename {
    color: #777;
    font-weight: 700
}

.attach-filesize {
    color: #999;
    font-size: 12px
}

.attach-file img {
    height: auto;
    max-width: 100%
}

.mailview-header {
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
    padding-bottom: 15px
}

.mailview-footer {
    border-top: 1px solid #ddd;
    margin-top: 20px;
    padding-top: 15px
}

.mailview-footer .btn-white {
    min-width: 102px
}

.sender-name {
    display: block
}

.receiver-name {
    color: #777
}

.right-action {
    text-align: right
}

.mail-view-title {
    font-weight: 500;
    font-size: 24px;
    margin: 0
}

.mail-view-action {
    float: right
}

.mail-sent-time {
    float: right
}

.blog {
    position: relative;
    margin: 0 0 50px;
    background-color: #fff;
    border: 1px solid #e7e7e7;
    border-radius: 4px;
    padding: 20px
}

.blog-image {
    overflow: hidden
}

.blog-image, .blog-image > a, .blog-image img {
    display: block;
    position: relative;
    width: 100%;
    height: auto
}

.blog-image img {
    -moz-transform: scaleY(1);
    -webkit-transform: scaleY(1);
    -o-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
    -moz-transition: all .5s ease-in-out 0s;
    -ms-transition: all .5s ease-in-out 0s;
    -o-transition: all .5s ease-in-out 0s;
    -webkit-transition: all .5s ease-in-out 0s;
    transition: all .5s ease-in-out 0s
}

.blog-image a:hover img {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1)
}

.blog-image {
    margin-bottom: 30px
}

.blog-content {
    position: relative
}

.blog-title {
    color: #000;
    font-weight: 400;
    margin: 0 0 20px
}

.blog-content p {
    color: #282628;
    font-size: 14px;
    line-height: 25px;
    margin: 0 0 25px
}

.read-more {
    color: #282628;
    display: inline-block;
    font-size: 14px;
    margin-bottom: 20px
}

.read-more:hover {
    color: #444;
    margin-left: 10px;
    -moz-transition: all .2s linear 0s;
    -ms-transition: all .2s linear 0s;
    -o-transition: all .2s linear 0s;
    -webkit-transition: all .2s linear 0s;
    transition: all .2s linear 0s
}

.read-more i {
    color: #444;
    font-size: 18px;
    margin-right: 3px
}

.blog-info {
    border: 1px solid #eaeaea;
    color: #909090;
    font-size: 12px;
    margin-bottom: 0;
    padding: 12px 20px
}

.blog-info a {
    color: #909090;
    margin-right: 5px
}

.blog-info a:last-child {
    margin-right: 0
}

.blog-info a:hover {
    color: #444
}

.blog-info i {
    color: #444;
    font-size: 18px;
    margin-right: 5px
}

.post-left {
    float: left
}

.post-right {
    float: right
}

.post-left ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.post-left ul li {
    float: left;
    margin-right: 20px
}

.post-left ul li:last-child {
    margin-right: 0
}

.video {
    position: relative;
    padding-bottom: 56%;
    height: 0;
    overflow: hidden
}

.video iframe, .video object, .video embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none
}

.widget {
    margin-bottom: 30px;
    background-color: #fff;
    padding: 20px;
    border: 1px solid #e7e7e7
}

.widget h5 {
    color: #656565;
    font-size: 21px;
    font-weight: 400;
    margin: 0 0 20px
}

.search-form .form-control {
    border: 1px solid #eaeaea;
    border-radius: 0;
    border-right: 0;
    box-shadow: inherit;
    height: 40px;
    color: #ababab
}

.search-form .btn-primary {
    background-color: transparent;
    border-color: #eaeaea;
    border-left: none;
    color: #444;
    height: 40px;
    border-radius: 0
}

.latest-posts {
    margin: 0;
    padding: 0
}

.latest-posts li {
    display: table;
    width: 100%;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #eee
}

.latest-posts li:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none
}

.post-thumb {
    width: 80px;
    float: left;
    overflow: hidden
}

.post-thumb a img {
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -moz-transition: all .3s ease-in-out 0s;
    -ms-transition: all .3s ease-in-out 0s;
    -o-transition: all .3s ease-in-out 0s;
    -webkit-transition: all .3s ease-in-out 0s;
    transition: all .3s ease-in-out 0s
}

.post-thumb a:hover img {
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2)
}

.post-info {
    margin-left: 95px
}

.post-info h4 {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin: 0 0 10px
}

.post-info h4 a {
    color: #333
}

.post-info p {
    color: #909090;
    font-size: 12px;
    margin: 0
}

.post-info p i {
    color: #444;
    font-size: 16px;
    margin-right: 4px
}

.categories {
    list-style: none;
    margin: 0;
    padding: 0
}

.categories li {
    padding: 12px 0;
    border-bottom: 1px solid #eaeaea
}

.categories li:last-child {
    padding-bottom: 0;
    border-bottom: none
}

.categories li a {
    color: #656565;
    font-size: 14px
}

.categories li a:hover {
    color: #444;
    margin-left: 10px;
    -moz-transition: all .2s linear 0s;
    -ms-transition: all .2s linear 0s;
    -o-transition: all .2s linear 0s;
    -webkit-transition: all .2s linear 0s;
    transition: all .2s linear 0s
}

.categories > li > a > i {
    color: #444;
    font-size: 18px;
    margin-right: 10px
}

.tags {
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0
}

.tags li {
    float: left
}

.tag {
    background-color: #eee;
    border-radius: 3px 0 0 3px;
    color: #999;
    display: inline-block;
    height: 26px;
    line-height: 26px;
    padding: 0 20px 0 23px;
    position: relative;
    margin: 0 10px 10px 0;
    text-decoration: none;
    -moz-transition: color .2s;
    -ms-transition: color .2s;
    -o-transition: color .2s;
    -webkit-transition: color .2s;
    transition: color .2s;
    font-size: 12px
}

.tag:before {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: inset 0 1px rgba(0, 0, 0, .25);
    content: '';
    height: 6px;
    left: 10px;
    position: absolute;
    width: 6px;
    top: 10px
}

.tag:after {
    background-color: #fff;
    border-bottom: 13px solid transparent;
    border-left: 10px solid #eee;
    border-top: 13px solid transparent;
    content: '';
    position: absolute;
    right: 0;
    top: 0
}

.tag:hover {
    background-color: #444;
    color: #fff
}

.tag:hover:after {
    border-left-color: #444
}

.comment-by {
    display: block;
    font-size: 14px;
    line-height: 21px;
    margin: 0 0 10px
}

.comments-list .comment-block p {
    font-size: 13px;
    line-height: 20px;
    margin: 0;
    text-align: justify
}

blockquote p {
    font-style: italic;
    font-weight: 400
}

.grid-blog .blog-title {
    font-size: 16px;
    margin: 0 0 10px
}

.grid-blog .blog-title a {
    color: #333
}

.grid-blog .blog-content p {
    font-size: 13px;
    margin: 0 0 15px;
    color: #909090
}

.grid-blog .blog-info {
    border: 0;
    border-top: 1px solid #eaeaea;
    margin-bottom: 0;
    padding: 12px 0
}

.grid-blog .blog-image {
    margin-bottom: 20px
}

.grid-blog .read-more {
    font-size: 13px;
    margin-bottom: 15px
}

.product-thumbnail {
    position: relative;
    margin-bottom: 0;
    margin-top: 10px
}

.product-remove {
    position: absolute;
    top: -10px;
    right: -6px;
    color: #f06060;
    cursor: pointer
}

.bootstrap-tagsinput {
    width: 100%;
    height: 40px;
    box-shadow: inherit;
    border-radius: 0;
    line-height: 30px
}

.bootstrap-tagsinput .badge [data-role=remove]:hover {
    box-shadow: inherit;
    color: red
}

.blog-view .blog-title {
    font-size: 32px
}

.blog-view .blog-info {
    border: 0;
    margin-bottom: 20px;
    padding: 0
}

.social-share {
    float: left;
    list-style: none;
    margin: 5px 0 0;
    padding: 0
}

.social-share > li {
    display: inline-block;
    float: left;
    margin-left: 10px;
    text-align: center
}

.social-share > li:first-child {
    margin-left: 0
}

.social-share > li > a {
    border: 1px solid #dfdfdf;
    color: #444;
    display: inline-block;
    font-size: 22px;
    height: 40px;
    line-height: 40px;
    width: 40px
}

.social-share > li > a:hover {
    background-color: #444;
    color: #fff;
    border-color: #444
}

.widget h3 {
    color: #656565;
    font-size: 21px;
    margin: 0 0 20px
}

.blog-reply > a {
    color: #444;
    font-size: 12px;
    font-weight: 500
}

.blog-date {
    color: #999;
    font-size: 12px
}

.comments-list {
    list-style: none;
    margin: 0;
    padding: 0
}

.comments-list li {
    clear: both;
    padding: 10px 0 0 88px
}

.comments-list li .comment {
    margin-bottom: 30px
}

.comments-list li .comment-author {
    margin-left: -88px;
    position: absolute
}

.comments-list li img.avatar {
    height: 58px;
    width: 58px;
    border-radius: 58px
}

.blog-author-name {
    color: #444;
    font-size: 18px
}

.text-red {
    color: red
}

.new-comment label {
    font-size: 14px;
    font-weight: 500
}

.new-comment input.form-control {
    border: 1px solid #e5e5e5;
    border-radius: 0;
    box-shadow: inherit;
    height: 40px
}

/* .new-comment textarea.form-control {
    border: 1px solid #e5e5e5;
    border-radius: 0;
    box-shadow: inherit
} */

.new-comment .form-group {
    margin-bottom: 20px
}

.comment-submit .btn {
    background-color: #444;
    border-color: #444;
    border-radius: 0;
    font-size: 18px;
    padding: 8px 26px;
    color: #fff
}

.about-author-img {
    background-color: #fff;
    height: 120px;
    overflow: hidden;
    position: absolute;
    width: 120px
}

.author-details {
    margin-left: 145px
}

.about-author {
    min-height: 120px
}

.author-details .blog-author-name {
    display: inline-block;
    margin-bottom: 10px
}

.blog-navigation {
    text-align: right
}

.pagination-box .pagination {
    margin-top: 0
}

.tab-content {
    padding-top: 20px
}

.error-box {
    border-radius: 5px;
    line-height: 1;
    margin: 0 auto;
    max-width: 475px;
    padding: 50px 30px 55px;
    text-align: center;
    width: 100%
}

.error-heading {
    font-size: 3.5em;
    font-weight: 700
}

.error-title {
    color: #2c2c2c;
    font-size: 22px;
    font-weight: 400;
    margin: 0 0 1.5rem
}

.error-wrapper {
    margin: 0;
    color: #4f5155;
    -moz-box-align: center;
    -moz-box-pack: center;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%
}

.error-box h1 {
    font-size: 150px;
    background-image: linear-gradient(to right, #444 0%, #6713d2 100%);
    background: #3264f5;
    background: -moz-linear-gradient(left, #444 2%, #6713d2 82%);
    background: -webkit-linear-gradient(left, #444 2%, #6713d2 82%);
    background: linear-gradient(to right, #444 2%, #6713d2 82%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#cc208e', endColorstr='#6713d2', GradientType=1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent
}

.error-box p {
    margin-bottom: 30px
}

.error-box .btn {
    text-transform: uppercase
}

.lock-user {
    margin-bottom: 20px
}

.lock-user img {
    margin-bottom: 15px;
    width: 100px
}

.voice-call-avatar {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 2
}

.voice-call-avatar .call-avatar {
    margin: 15px;
    width: 150px;
    height: 150px;
    border-radius: 100%;
    border: 1px solid rgba(0, 0, 0, .1);
    padding: 3px;
    background-color: #fff
}

.call-duration {
    display: inline-block;
    font-size: 30px;
    margin-top: 4px;
    position: absolute;
    left: 0
}

.voice-call-avatar .call-timing-count {
    padding: 5px
}

.voice-call-avatar .username {
    font-size: 18px;
    text-transform: uppercase
}

.call-icons {
    text-align: center;
    position: relative
}

.call-icons .call-items {
    border-radius: 5px;
    padding: 0;
    margin: 0;
    list-style: none;
    display: inline-block
}

.call-icons .call-items .call-item {
    display: inline-block;
    text-align: center;
    margin-right: 5px
}

.call-icons .call-items .call-item:last-child {
    margin-right: 0
}

.call-icons .call-items .call-item a {
    color: #777;
    border: 1px solid #ddd;
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50px;
    display: inline-block;
    font-size: 20px
}

.call-icons .call-items .call-item a i {
    width: 18px;
    height: 18px
}

.user-video {
    bottom: 0;
    left: 0;
    overflow: auto;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10
}

.user-video img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    margin: 0 auto
}

.user-video video {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    margin: 0 auto
}

.my-video {
    position: absolute;
    z-index: 99;
    bottom: 20px;
    right: 20px
}

.my-video ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.my-video ul li {
    float: left;
    width: 120px;
    margin-right: 10px
}

.my-video ul li img {
    border: 3px solid #fff;
    border-radius: 6px
}

.end-call {
    position: absolute;
    top: 7px;
    right: 0
}

.end-call a {
    color: #fff;
    border-radius: 50px;
    display: inline-block;
    background-color: #f06060;
    padding: 8px 25px;
    text-transform: uppercase
}

.call-users {
    position: absolute;
    z-index: 99;
    bottom: 20px;
    right: 20px
}

.call-users ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.call-users ul li {
    float: left;
    width: 80px;
    margin-left: 10px
}

.call-users ul li img {
    border-radius: 6px;
    padding: 2px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .1)
}

.call-mute {
    width: 80px;
    height: 80px;
    background-color: rgba(0, 0, 0, .5);
    position: absolute;
    display: inline-block;
    text-align: center;
    line-height: 80px;
    border-radius: 6px;
    font-size: 30px;
    color: #fff;
    display: none;
    top: 0;
    border: 3px solid transparent
}

.call-users ul li a:hover .call-mute {
    display: block
}

.call-details {
    margin: 10px 0 0;
    display: flex
}

.call-info {
    margin-left: 10px;
    width: 100%
}

.call-user-details, .call-timing {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap
}

.call-description {
    white-space: nowrap;
    vertical-align: bottom
}

.call-timing {
    color: #a0a0a2;
    display: flex;
    font-size: 14px;
    margin-top: 1px;
    overflow: hidden;
    white-space: pre
}

.content-full {
    height: 100%;
    position: relative;
    width: 100%
}

.video-window .fixed-header {
    padding: 0;
    border: 0
}

.video-window .fixed-header .nav > li > a {
    padding: 19px 15px
}

.incoming-btns {
    margin-top: 20px
}

.modal.custom-modal {
    background-color: #f5f5f5
}

.custom-modal .modal-content {
    border: 0;
    background-color: transparent
}

.custom-modal .profile-widget {
    margin-bottom: 0
}

.notification-list .list-group-item {
    padding: 15px
}

.chat-sidebar {
    background-color: #fff;
    border-left: 1px solid #eaeaea;
    width: 300px
}

.chat-sidebar .chat-contents {
    background-color: #fff
}

.chat-sidebar .chat-left .chat-content {
    border: 0;
    padding: 0;
    border-radius: 0 !important;
    background-color: transparent;
    max-width: 100%
}

.chat-sidebar .chat-left .chat-body {
    margin-left: 50px;
    padding-right: 0
}

.chat-sidebar .chat-date {
    background-color: #fff
}

.chat-sidebar .chats {
    padding: 15px 15px 30px
}

.chat-sidebar .avatar {
    width: 30px;
    height: 30px;
    line-height: 30px
}

.chat-sidebar .chat.chat-left {
    margin-bottom: 20px
}

.chat-sidebar .chat.chat-left:last-child {
    margin-bottom: 0
}

.chat-sidebar .chat-left .chat-time {
    color: #888;
    display: inline-block;
    font-size: 11px
}

.chat-sidebar .chat-content > p {
    font-size: 13px;
    margin-bottom: 0
}

.chat-sidebar.opened {
    margin-right: 0
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    -ms-overflow-style: -ms-autohiding-scrollbar
}

.lg-backdrop {
    z-index: 1041
}

#lightgallery .img-thumbnail {
    box-shadow: 0 6px 15px rgba(36, 37, 38, .08)
}

.morris-hover {
    position: absolute;
    z-index: 1000
}

.morris-hover.morris-default-style {
    border-radius: 10px;
    padding: 6px;
    color: #666;
    background: rgba(255, 255, 255, .8);
    border: solid 2px rgba(230, 230, 230, .8);
    font-family: sans-serif;
    font-size: 12px;
    text-align: center
}

.morris-hover.morris-default-style .morris-hover-row-label {
    font-weight: 700;
    margin: .25em 0
}

.morris-hover.morris-default-style .morris-hover-point {
    white-space: nowrap;
    margin: .1em 0
}

svg {
    width: 100%
}

.draggable {
    cursor: -webkit-grab;
    cursor: grab
}

.gu-unselectable .draggable {
    cursor: -webkit-grabbing;
    cursor: grabbing
}

#dd-handle .list-group, #multiple-list-group-a, #multiple-list-group-b {
    min-height: 5.714rem
}

#dd-handle .list-group .handle, .gu-mirror .handle {
    padding: 0 5px;
    margin-right: 5px;
    background-color: rgba(0, 0, 0, .1);
    cursor: move;
    font-size: 1.2rem
}

.chart-wrapper .row .card-body {
    padding: 0
}

.custom-file-container {
    box-sizing: border-box;
    position: relative;
    display: block
}

.custom-file-container label {
    color: #b900ce
}

.custom-file-container label a {
    color: #b900ce
}

.custom-file-container__custom-file {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    margin-bottom: 0;
    margin-top: 5px
}

.custom-file-container__custom-file:hover {
    cursor: pointer
}

.custom-file-container__custom-file__custom-file-input {
    box-sizing: border-box;
    min-width: 14rem;
    max-width: 100%;
    height: calc(2.25rem + 2px);
    margin: 0;
    opacity: 0
}

.custom-file-container__custom-file__custom-file-input:focus ~ span {
    outline: 1px dotted #515365;
    outline: 5px auto -webkit-focus-ring-color
}

.custom-file-container__custom-file__custom-file-control {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: auto;
    overflow: hidden;
    line-height: 1.5;
    user-select: none;
    background-clip: padding-box;
    border-radius: .25rem;
    height: auto;
    border: 1px solid #faf4fb;
    color: #3b3f5c;
    font-size: 15px;
    padding: 8px 10px;
    letter-spacing: 1px;
    background-color: #faf4fb
}

.custom-file-container__custom-file__custom-file-control__button {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 6;
    display: block;
    height: auto;
    padding: 10px 16px;
    line-height: 1.25;
    background-color: #f7e5fa;
    color: #bf00ce;
    border-left: 1px solid #e0e6ed;
    box-sizing: border-box
}

.custom-file-container__image-preview {
    box-sizing: border-box;
    transition: all .2s ease;
    margin-top: 54px;
    margin-bottom: 40px;
    height: 250px;
    width: 100%;
    border-radius: 4px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #fff;
    overflow: auto;
    padding: 15px
}

.custom-file-container__image-multi-preview {
    position: relative;
    box-sizing: border-box;
    transition: all .2s ease;
    border-radius: 6px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    float: left;
    margin: 1.858736%;
    width: 29.615861214%;
    height: 90px;
    box-shadow: 0 4px 10px 0 rgba(51, 51, 51, .25)
}

.custom-file-container__image-multi-preview__single-image-clear {
    left: -6px;
    background: #fff;
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    margin-top: -6px;
    box-shadow: 0 4px 10px 0 rgba(51, 51, 51, .25)
}

.custom-file-container__image-multi-preview__single-image-clear:hover {
    background: #cbcbbd;
    cursor: pointer
}

.custom-file-container__image-multi-preview__single-image-clear__icon {
    color: #4361ee;
    display: block;
    margin-top: -2px
}

.note-editor.card {
    padding: .5rem
}

.note-toolbar.card-header {
    padding: 0;
    padding-bottom: .5rem
}

.irs--flat .irs-from, .irs--flat .irs-to, .irs--flat .irs-single, .irs--flat .irs-bar, .irs--flat .irs-handle > i:first-child, .irs--flat .irs-handle.state_hover > i:first-child, .irs--flat .irs-handle:hover > i:first-child {
    background-color: #7014cc
}

.irs--flat .irs-from:before, .irs--flat .irs-to:before, .irs--flat .irs-single:before {
    border-top-color: #7014cc
}

.ribbon-primary {
    background: #7366ff
}

.ribbon-bookmark.ribbon-primary:before {
    border-color: #7366ff;
    border-right-color: transparent
}

.ribbon-bookmark.ribbon-right.ribbon-primary:before {
    border-right-color: #7366ff;
    border-left-color: transparent
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-primary:before, .ribbon-bookmark.ribbon-vertical-right.ribbon-primary:before {
    border-right-color: #7366ff;
    border-bottom-color: transparent
}

.ribbon-primary.ribbon-corner:before {
    border-top-color: #7366ff;
    border-left-color: #7366ff
}

.ribbon-primary.ribbon-corner.ribbon-right:before {
    border-right-color: #7366ff;
    border-left-color: transparent
}

.ribbon-primary.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #7366ff
}

.ribbon-primary.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #43f;
    border-right-color: #43f
}

.ribbon-primary.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #43f;
    border-left-color: #43f
}

.ribbon-primary.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #43f;
    border-right-color: #43f
}

.ribbon-primary.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #43f;
    border-right-color: transparent;
    border-left-color: #43f
}

.ribbon-secondary {
    background: #f73164
}

.ribbon-secondary {
    background: #f73164
}

.ribbon-bookmark.ribbon-secondary:before {
    border-color: #f73164;
    border-right-color: transparent
}

.ribbon-bookmark.ribbon-right.ribbon-secondary:before {
    border-right-color: #f73164;
    border-left-color: transparent
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-secondary:before, .ribbon-bookmark.ribbon-vertical-right.ribbon-secondary:before {
    border-right-color: #f73164;
    border-bottom-color: transparent
}

.ribbon-secondary.ribbon-corner:before {
    border-top-color: #f73164;
    border-left-color: #f73164
}

.ribbon-secondary.ribbon-corner.ribbon-right:before {
    border-right-color: #f73164;
    border-left-color: transparent
}

.ribbon-secondary.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #f73164
}

.ribbon-secondary.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #ec0944;
    border-right-color: #ec0944
}

.ribbon-secondary.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #ec0944;
    border-left-color: #ec0944
}

.ribbon-secondary.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #ec0944;
    border-right-color: #ec0944
}

.ribbon-secondary.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #ec0944;
    border-right-color: transparent;
    border-left-color: #ec0944
}

.ribbon-success {
    background: #51bb25
}

.ribbon-success {
    background: #51bb25
}

.ribbon-bookmark.ribbon-success:before {
    border-color: #51bb25;
    border-right-color: transparent
}

.ribbon-bookmark.ribbon-right.ribbon-success:before {
    border-right-color: #51bb25;
    border-left-color: transparent
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-success:before, .ribbon-bookmark.ribbon-vertical-right.ribbon-success:before {
    border-right-color: #51bb25;
    border-bottom-color: transparent
}

.ribbon-success.ribbon-corner:before {
    border-top-color: #51bb25;
    border-left-color: #51bb25
}

.ribbon-success.ribbon-corner.ribbon-right:before {
    border-right-color: #51bb25;
    border-left-color: transparent
}

.ribbon-success.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #51bb25
}

.ribbon-success.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #3f901d;
    border-right-color: #3f901d
}

.ribbon-success.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #3f901d;
    border-left-color: #3f901d
}

.ribbon-success.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #3f901d;
    border-right-color: #3f901d
}

.ribbon-success.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #3f901d;
    border-right-color: transparent;
    border-left-color: #3f901d
}

.ribbon-danger {
    background: #dc3545
}

.ribbon-danger {
    background: #dc3545
}

.ribbon-bookmark.ribbon-danger:before {
    border-color: #dc3545;
    border-right-color: transparent
}

.ribbon-bookmark.ribbon-right.ribbon-danger:before {
    border-right-color: #dc3545;
    border-left-color: transparent
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-danger:before, .ribbon-bookmark.ribbon-vertical-right.ribbon-danger:before {
    border-right-color: #dc3545;
    border-bottom-color: transparent
}

.ribbon-danger.ribbon-corner:before {
    border-top-color: #dc3545;
    border-left-color: #dc3545
}

.ribbon-danger.ribbon-corner.ribbon-right:before {
    border-right-color: #dc3545;
    border-left-color: transparent
}

.ribbon-danger.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #dc3545
}

.ribbon-danger.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #bd2130;
    border-right-color: #bd2130
}

.ribbon-danger.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #bd2130;
    border-left-color: #bd2130
}

.ribbon-danger.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #bd2130;
    border-right-color: #bd2130
}

.ribbon-danger.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #bd2130;
    border-right-color: transparent;
    border-left-color: #bd2130
}

.ribbon-info {
    background: #a927f9
}

.ribbon-info {
    background: #a927f9
}

.ribbon-bookmark.ribbon-info:before {
    border-color: #a927f9;
    border-right-color: transparent
}

.ribbon-bookmark.ribbon-right.ribbon-info:before {
    border-right-color: #a927f9;
    border-left-color: transparent
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-info:before, .ribbon-bookmark.ribbon-vertical-right.ribbon-info:before {
    border-right-color: #a927f9;
    border-bottom-color: transparent
}

.ribbon-info.ribbon-corner:before {
    border-top-color: #a927f9;
    border-left-color: #a927f9
}

.ribbon-info.ribbon-corner.ribbon-right:before {
    border-right-color: #a927f9;
    border-left-color: transparent
}

.ribbon-info.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #a927f9
}

.ribbon-info.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #9106e7;
    border-right-color: #9106e7
}

.ribbon-info.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #9106e7;
    border-left-color: #9106e7
}

.ribbon-info.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #9106e7;
    border-right-color: #9106e7
}

.ribbon-info.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #9106e7;
    border-right-color: transparent;
    border-left-color: #9106e7
}

.ribbon-light {
    background: #f4f4f4
}

.ribbon-light {
    background: #f4f4f4
}

.ribbon-bookmark.ribbon-light:before {
    border-color: #f4f4f4;
    border-right-color: transparent
}

.ribbon-bookmark.ribbon-right.ribbon-light:before {
    border-right-color: #f4f4f4;
    border-left-color: transparent
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-light:before, .ribbon-bookmark.ribbon-vertical-right.ribbon-light:before {
    border-right-color: #f4f4f4;
    border-bottom-color: transparent
}

.ribbon-light.ribbon-corner:before {
    border-top-color: #f4f4f4;
    border-left-color: #f4f4f4
}

.ribbon-light.ribbon-corner.ribbon-right:before {
    border-right-color: #f4f4f4;
    border-left-color: transparent
}

.ribbon-light.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #f4f4f4
}

.ribbon-light.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #dbdbdb;
    border-right-color: #dbdbdb
}

.ribbon-light.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #dbdbdb;
    border-left-color: #dbdbdb
}

.ribbon-light.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #dbdbdb;
    border-right-color: #dbdbdb
}

.ribbon-light.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #dbdbdb;
    border-right-color: transparent;
    border-left-color: #dbdbdb
}

.ribbon-dark {
    background: #2c323f
}

.ribbon-dark {
    background: #2c323f
}

.ribbon-bookmark.ribbon-dark:before {
    border-color: #2c323f;
    border-right-color: transparent
}

.ribbon-bookmark.ribbon-right.ribbon-dark:before {
    border-right-color: #2c323f;
    border-left-color: transparent
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-dark:before, .ribbon-bookmark.ribbon-vertical-right.ribbon-dark:before {
    border-right-color: #2c323f;
    border-bottom-color: transparent
}

.ribbon-dark.ribbon-corner:before {
    border-top-color: #2c323f;
    border-left-color: #2c323f
}

.ribbon-dark.ribbon-corner.ribbon-right:before {
    border-right-color: #2c323f;
    border-left-color: transparent
}

.ribbon-dark.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #2c323f
}

.ribbon-dark.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #171a21;
    border-right-color: #171a21
}

.ribbon-dark.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #171a21;
    border-left-color: #171a21
}

.ribbon-dark.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #171a21;
    border-right-color: #171a21
}

.ribbon-dark.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #171a21;
    border-right-color: transparent;
    border-left-color: #171a21
}

.ribbon-warning {
    background: #f8d62b
}

.ribbon-warning {
    background: #f8d62b
}

.ribbon-bookmark.ribbon-warning:before {
    border-color: #f8d62b;
    border-right-color: transparent
}

.ribbon-bookmark.ribbon-right.ribbon-warning:before {
    border-right-color: #f8d62b;
    border-left-color: transparent
}

.ribbon-bookmark.ribbon-vertical-left.ribbon-warning:before, .ribbon-bookmark.ribbon-vertical-right.ribbon-warning:before {
    border-right-color: #f8d62b;
    border-bottom-color: transparent
}

.ribbon-warning.ribbon-corner:before {
    border-top-color: #f8d62b;
    border-left-color: #f8d62b
}

.ribbon-warning.ribbon-corner.ribbon-right:before {
    border-right-color: #f8d62b;
    border-left-color: transparent
}

.ribbon-warning.ribbon-corner.ribbon-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #f8d62b
}

.ribbon-warning.ribbon-clip:before {
    border-color: transparent;
    border-top-color: #e8c308;
    border-right-color: #e8c308
}

.ribbon-warning.ribbon-clip-right:before {
    border-right-color: transparent;
    border-top-color: #e8c308;
    border-left-color: #e8c308
}

.ribbon-warning.ribbon-clip-bottom:before {
    border-top-color: transparent;
    border-bottom-color: #e8c308;
    border-right-color: #e8c308
}

.ribbon-warning.ribbon-clip-bottom-right:before {
    border-top-color: transparent;
    border-bottom-color: #e8c308;
    border-right-color: transparent;
    border-left-color: #e8c308
}

.ribbon-wrapper {
    position: relative;
    padding-top: 40px
}

.ribbon-box {
    padding: 0;
    padding-top: 20px
}

.ribbon-box p {
    margin-bottom: 0
}

.ribbon-vertical-left-wrapper .ribbon-box {
    padding: 0;
    padding-left: 20px
}

.ribbon-vertical-right-wrapper .ribbon-box {
    padding: 0;
    padding-right: 20px
}

.ribbon-wrapper .card-body, .ribbon-vertical-left-wrapper .card-body, .ribbon-vertical-right-wrapper .card-body {
    position: unset
}

.ribbon {
    padding: 0 20px;
    height: 30px;
    line-height: 30px;
    clear: left;
    position: absolute;
    top: 12px;
    left: 0;
    color: #fff;
    z-index: 1
}

.ribbon-right {
    left: auto;
    right: -2px
}

.ribbon-vertical-left-wrapper {
    padding-left: 40px;
    position: relative
}

.ribbon-vertical-left {
    clear: none;
    padding: 0 5px;
    height: 70px;
    width: 30px;
    line-height: 70px;
    text-align: center;
    left: 12px;
    top: -2px
}

.ribbon-vertical-right-wrapper {
    padding-right: 40px
}

.ribbon-vertical-right {
    clear: none;
    padding: 0 5px;
    height: 70px;
    width: 30px;
    line-height: 70px;
    text-align: center;
    top: -2px;
    left: auto;
    right: 12px
}

.timeline {
    position: relative;
    padding: 20px 0;
    list-style: none;
    max-width: 1200px;
    margin: 0 auto
}

.timeline:before {
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 3px;
    margin-left: -1.5px;
    background-color: #e9ecef
}

.timeline > li {
    position: relative;
    margin-bottom: 20px
}

.timeline > li:before, .timeline > li:after {
    content: " ";
    display: table
}

.timeline > li:after {
    clear: both
}

.timeline > li:before, .timeline > li:after {
    content: " ";
    display: table
}

.timeline > li:after {
    clear: both
}

.timeline > li > .timeline-panel {
    float: left;
    position: relative;
    width: 46%;
    padding: 20px;
    border: 1px solid #e9ecef;
    border-radius: .25rem;
    -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, .05);
    box-shadow: 0 1px 6px rgba(0, 0, 0, .05)
}

.timeline > li > .timeline-panel:before {
    content: " ";
    display: inline-block;
    position: absolute;
    top: 26px;
    right: -8px;
    border-top: 8px solid transparent;
    border-right: 0 solid #e9ecef;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #e9ecef
}

.timeline > li > .timeline-panel:after {
    content: " ";
    display: inline-block;
    position: absolute;
    top: 27px;
    right: -7px;
    border-top: 7px solid transparent;
    border-right: 0 solid #fff;
    border-bottom: 7px solid transparent;
    border-left: 7px solid #fff
}

.timeline > li > .timeline-badge {
    z-index: 1;
    position: absolute;
    top: 16px;
    left: 50%;
    width: 50px;
    height: 50px;
    margin-left: -25px;
    border-radius: 50% 50% 50% 50%;
    text-align: center;
    font-size: 1.4em;
    line-height: 50px;
    color: #fff;
    overflow: hidden
}

.timeline > li.timeline-inverted > .timeline-panel {
    float: right
}

.timeline > li.timeline-inverted > .timeline-panel:before {
    right: auto;
    left: -8px;
    border-right-width: 8px;
    border-left-width: 0
}

.timeline > li.timeline-inverted > .timeline-panel:after {
    right: auto;
    left: -7px;
    border-right-width: 7px;
    border-left-width: 0
}

.timeline-badge.primary {
    background-color: #fb9678
}

.timeline-badge.success {
    background-color: #00c292
}

.timeline-badge.warning {
    background-color: #fec107
}

.timeline-badge.danger {
    background-color: #e46a76
}

.timeline-badge.info {
    background-color: #03a9f3
}

.timeline-title {
    margin-top: 0;
    color: inherit;
    font-weight: 400
}

.timeline-body > p, .timeline-body > ul {
    margin-bottom: 0
}

.timeline-body > p + p {
    margin-top: 5px
}

.cd-horizontal-timeline .events a {
    padding-bottom: 6px;
    color: #621aff
}

.cd-horizontal-timeline .filling-line, .cd-horizontal-timeline .events a.selected::after {
    background: #621aff
}

.cd-horizontal-timeline .events a.selected::after {
    border-color: #621aff
}

.cd-horizontal-timeline .m-t-40 {
    margin-top: 25px !important
}

.cd-horizontal-timeline ol, .cd-horizontal-timeline ul {
    list-style: none
}

.cd-timeline-navigation a:hover, .cd-timeline-navigation a:focus {
    border-color: #621aff
}

.cd-horizontal-timeline a, .cd-horizontal-timeline a:hover, .cd-horizontal-timeline a:focus {
    color: #621aff
}

.cd-horizontal-timeline blockquote, .cd-horizontal-timeline q {
    quotes: none
}

.cd-horizontal-timeline blockquote:before, .cd-horizontal-timeline blockquote:after, .cd-horizontal-timeline q:before, .cd-horizontal-timeline q:after {
    content: '';
    content: none
}

.cd-horizontal-timeline table {
    border-collapse: collapse;
    border-spacing: 0
}

.cd-horizontal-timeline {
    opacity: 0;
    margin: 2em auto;
    -webkit-transition: opacity .2s;
    -moz-transition: opacity .2s;
    transition: opacity .2s
}

.cd-horizontal-timeline::before {
    content: 'mobile';
    display: none
}

.cd-horizontal-timeline.loaded {
    opacity: 1
}

.cd-horizontal-timeline .timeline {
    position: relative;
    height: 100px;
    width: 90%;
    max-width: 800px;
    margin: 0 auto
}

.cd-horizontal-timeline .events-wrapper {
    position: relative;
    height: 100%;
    margin: 0 40px;
    overflow: hidden
}

.cd-horizontal-timeline .events-wrapper::after, .cd-horizontal-timeline .events-wrapper::before {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    height: 100%;
    width: 20px
}

.cd-horizontal-timeline .events-wrapper::before {
    left: 0
}

.cd-horizontal-timeline .events-wrapper::after {
    right: 0
}

.cd-horizontal-timeline .events {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 30px;
    height: 2px;
    background: #dfdfdf;
    -webkit-transition: -webkit-transform .4s;
    -moz-transition: -moz-transform .4s;
    transition: transform .4s
}

.cd-horizontal-timeline .filling-line {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #621aff;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
    -webkit-transition: -webkit-transform .3s;
    -moz-transition: -moz-transform .3s;
    transition: transform .3s
}

.cd-horizontal-timeline .events a {
    position: absolute;
    bottom: 0;
    z-index: 2;
    text-align: center;
    font-size: 1rem;
    padding-bottom: 15px;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0)
}

.cd-horizontal-timeline .events a::after {
    content: '';
    position: absolute;
    left: 50%;
    right: auto;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: -5px;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    border: 2px solid #dfdfdf;
    background-color: #f8f8f8;
    -webkit-transition: background-color .3s, border-color .3s;
    -moz-transition: background-color .3s, border-color .3s;
    transition: background-color .3s, border-color .3s
}

.no-touch .cd-horizontal-timeline .events a:hover::after {
    background-color: #621aff;
    border-color: #621aff
}

.cd-horizontal-timeline .events a.selected {
    pointer-events: none
}

.cd-horizontal-timeline .events a.selected::after {
    background-color: #621aff;
    border-color: #621aff
}

.cd-horizontal-timeline .events a.older-event::after {
    border-color: #621aff
}

.cd-timeline-navigation a {
    position: absolute;
    z-index: 1;
    top: 50%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 34px;
    width: 34px;
    border-radius: 50%;
    border: 2px solid #dfdfdf;
    overflow: hidden;
    color: transparent;
    text-indent: 100%;
    white-space: nowrap;
    -webkit-transition: border-color .3s;
    -moz-transition: border-color .3s;
    transition: border-color .3s
}

.cd-timeline-navigation a::after {
    content: '';
    position: absolute;
    height: 16px;
    width: 16px;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    background: url(../img/arrow.svg) no-repeat 0 0
}

.cd-timeline-navigation a.prev {
    left: 0;
    -webkit-transform: translateY(-50%) rotate(180deg);
    -moz-transform: translateY(-50%) rotate(180deg);
    -ms-transform: translateY(-50%) rotate(180deg);
    -o-transform: translateY(-50%) rotate(180deg);
    transform: translateY(-50%) rotate(180deg)
}

.cd-timeline-navigation a.next {
    right: 0
}

.no-touch .cd-timeline-navigation a:hover {
    border-color: #7b9d6f
}

.cd-timeline-navigation a.inactive {
    cursor: not-allowed
}

.cd-timeline-navigation a.inactive::after {
    background-position: 0 -16px
}

.no-touch .cd-timeline-navigation a.inactive:hover {
    border-color: #dfdfdf
}

.cd-horizontal-timeline .events-content {
    position: relative;
    width: 100%;
    margin: 2em 0;
    overflow: hidden;
    -webkit-transition: height .4s;
    -moz-transition: height .4s;
    transition: height .4s
}

.cd-horizontal-timeline .events-content li {
    position: absolute;
    z-index: 1;
    width: 100%;
    left: 0;
    top: 0;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
    padding: 0 5%;
    opacity: 0;
    -webkit-animation-duration: .4s;
    -moz-animation-duration: .4s;
    animation-duration: .4s;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out
}

.cd-horizontal-timeline .events-content li.selected {
    position: relative;
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0)
}

.cd-horizontal-timeline .events-content li.enter-right, .cd-horizontal-timeline .events-content li.leave-right {
    -webkit-animation-name: cd-enter-right;
    -moz-animation-name: cd-enter-right;
    animation-name: cd-enter-right
}

.cd-horizontal-timeline .events-content li.enter-left, .cd-horizontal-timeline .events-content li.leave-left {
    -webkit-animation-name: cd-enter-left;
    -moz-animation-name: cd-enter-left;
    animation-name: cd-enter-left
}

.cd-horizontal-timeline .events-content li.leave-right, .cd-horizontal-timeline .events-content li.leave-left {
    -webkit-animation-direction: reverse;
    -moz-animation-direction: reverse;
    animation-direction: reverse
}

.cd-horizontal-timeline .events-content li > * {
    max-width: 800px;
    margin: 0 auto
}

.cd-horizontal-timeline .events-content h2 {
    font-weight: 600;
    margin-bottom: 0
}

.cd-horizontal-timeline .events-content em {
    display: block;
    font-style: italic;
    margin: 10px auto
}

.cd-horizontal-timeline .events-content em::before {
    content: '- '
}

.cd-horizontal-timeline .events-content p {
    font-size: 16px
}

.cd-horizontal-timeline .timeline:before {
    content: " ";
    display: none;
    bottom: 0;
    left: 0%;
    width: 0;
    margin-left: -1.5px;
    background-color: #eee
}

.cd-horizontal-timeline .events-content em, .cd-horizontal-timeline .events-content p {
    line-height: 30px
}

.h-250 {
    height: 250px
}

.icons-list {
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 0
}

.icons-list li {
    display: block;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 18px;
    border-radius: 50px;
    box-shadow: 0 2px 3px rgb(215 197 255);
    border: 1px solid #e6ebf1;
    color: #7014cc;
    margin: 5px
}

.countup-wrapper h6 {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 0
}

.countup-wrapper span {
    font-size: 14px;
    font-weight: 500;
    color: #565656;
    display: inline-block;
    margin-top: 7px
}

.twitter-bs-wizard .twitter-bs-wizard-pager-link {
    padding-top: 0
}

.alertify .ajs-footer .ajs-buttons .ajs-button {
    font-weight: 500
}

.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok {
    color: #5156be
}

.alertify .ajs-footer .ajs-buttons.ajs-primary {
    text-align: right
}

.alertify .ajs-body .ajs-content .ajs-input:focus-visible {
    outline: 0
}

.alertify .ajs-commands {
    right: 4px;
    left: auto;
    margin: -14px 0 0 24px
}

.alertify-notifier .ajs-message {
    background-color: #5156be;
    border-color: #5156be;
    color: #fff;
    text-shadow: none !important
}

.alertify-notifier .ajs-message.ajs-success {
    background-color: #2ab57d;
    border-color: #2ab57d
}

.alertify-notifier .ajs-message.ajs-error {
    background-color: #fd625e;
    border-color: #fd625e
}

.alertify-notifier .ajs-message.ajs-warning {
    background-color: #ffbf53;
    border-color: #ffbf53
}

.alertify-notifier.ajs-right {
    right: 10px;
    left: auto
}

.alertify-notifier.ajs-right .ajs-message {
    right: -320px;
    left: auto
}

.alertify-notifier.ajs-right .ajs-message.ajs-visible {
    right: 290px;
    left: auto
}

.alertify-notifier.ajs-left {
    left: 10px;
    right: auto
}

.alertify-notifier.ajs-left .ajs-message {
    left: -300px;
    right: auto
}

.alertify-notifier.ajs-left .ajs-message.ajs-visible {
    left: 0;
    right: auto
}

body[data-layout-mode=dark] .alertify .ajs-dialog {
    background-color: #313533
}

body[data-layout-mode=dark] .alertify .ajs-footer, body[data-layout-mode=dark] .alertify .ajs-header {
    color: #ced4da;
    background-color: #313533;
    border-color: #3b403d
}

body[data-layout-mode=dark] .alertify .ajs-body {
    color: #adb5bd
}

body[data-layout-mode=dark] .alertify .ajs-body .ajs-content .ajs-input {
    color: #adb5bd;
    background-color: #363a38;
    border: 1px solid #3b403d
}

body[data-layout-mode=dark] .alertify .ajs-footer .ajs-buttons .ajs-button {
    color: #adb5bd
}

body[data-layout-mode=dark] .alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok {
    color: #5156be
}

.alertify .ajs-footer .ajs-buttons .ajs-button {
    background-color: transparent;
    color: #000;
    border: 0;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase
}

.alertify .ajs-body .ajs-content .ajs-input {
    display: block;
    width: 100%;
    padding: 8px;
    margin: 4px;
    border-radius: 2px;
    border: 1px solid #ccc
}

.alertify .ajs-header {
    border-bottom: 1px solid #eee
}

.alertify .ajs-footer {
    border-top: 1px solid #eee
}

.swal2-actions button {
    margin-right: 10px
}

.swal2-title {
    font-size: 22px !important
}

@media only screen and (min-width: 991px) {
    #toggle_btn {
        color: #fff;
        float: left;
        font-size: 10px;
        line-height: 1.3;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center
    }

    .left-chev {
        background: #444;
        width: 17px;
        height: 17px;
        border-radius: 50px;
        position: absolute;
        top: 15px;
        left: -10px;
        z-index: 9
    }

    .left-right-menu {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }

    .mini-sidebar .header-left .logo span {
        display: none
    }

    .mini-sidebar .header .header-left {
        padding: 0 5px;
        width: 60px
    }

    .mini-sidebar .sidebar {
        width: 60px
    }

    .mini-sidebar.expand-menu .sidebar {
        width: 230px
    }

    .mini-sidebar .menu-title {
        visibility: hidden;
        white-space: nowrap
    }

    .mini-sidebar.expand-menu .menu-title {
        visibility: visible
    }

    .modal-open .mini-sidebar .sidebar {
        z-index: 1051
    }

    .mini-sidebar .sidebar .sidebar-menu ul > li > a span {
        display: none;
        transition: all .2s ease-in-out;
        opacity: 0
    }

    .mini-sidebar.expand-menu .sidebar .sidebar-menu ul > li > a span {
        display: inline;
        opacity: 1
    }

    .mini-sidebar .page-wrapper {
        margin-left: 60px
    }

    .table-responsive {
        display: block;
        width: 100%;
        overflow-x: auto;
        white-space: nowrap;
        -ms-overflow-style: -ms-autohiding-scrollbar
    }

    .exchange-form .form-group {
        margin-bottom: 15px
    }
}

@media only screen and (min-width: 768px) {
    .modal-md {
        width: 500px
    }

    .crypto-exchange .card-title h4 {
        font-size: 20px;
        font-weight: 600;
        padding: 30px 30px 15px
    }

    .table-responsive {
        display: block;
        width: 100%;
        overflow-x: auto;
        white-space: nowrap;
        -ms-overflow-style: -ms-autohiding-scrollbar
    }

    .nav-tabs.nav-justified.nav-tabs-top {
        border-bottom: 1px solid #ddd
    }

    .nav-tabs.nav-justified.nav-tabs-top > li > a, .nav-tabs.nav-justified.nav-tabs-top > li > a:hover, .nav-tabs.nav-justified.nav-tabs-top > li > a:focus {
        border-width: 2px 0 0
    }

    .nav-tabs.nav-tabs-top > li {
        margin-bottom: 0
    }

    .nav-tabs.nav-tabs-top > li > a, .nav-tabs.nav-tabs-top > li > a:hover, .nav-tabs.nav-tabs-top > li > a:focus {
        border-width: 2px 0 0
    }

    .nav-tabs.nav-tabs-top > li + li > a {
        margin-left: 1px
    }

    .nav-tabs.nav-tabs-top > li > a.active, .nav-tabs.nav-tabs-top > li > a.active:hover, .nav-tabs.nav-tabs-top > li > a.active:focus {
        border-top-color: #444
    }

    .nav-tabs.nav-tabs-bottom > li {
        margin-bottom: -1px
    }

    .nav-tabs.nav-tabs-bottom > li > a.active, .nav-tabs.nav-tabs-bottom > li > a.active:hover, .nav-tabs.nav-tabs-bottom > li > a.active:focus {
        border-bottom-width: 2px;
        border-color: transparent;
        border-bottom-color: #444;
        background-color: transparent;
        transition: none 0s ease 0s;
        -moz-transition: none 0s ease 0s;
        -o-transition: none 0s ease 0s;
        -ms-transition: none 0s ease 0s;
        -webkit-transition: none 0s ease 0s
    }

    .nav-tabs.nav-tabs-bottom > li {
        margin-bottom: -1px
    }

    .nav-tabs.nav-tabs-bottom > li > a.active, .nav-tabs.nav-tabs-bottom > li > a.active:hover, .nav-tabs.nav-tabs-bottom > li > a.active:focus {
        border-bottom-width: 2px;
        border-color: transparent;
        border-bottom-color: #444;
        background-color: transparent;
        transition: none 0s ease 0s;
        -moz-transition: none 0s ease 0s;
        -o-transition: none 0s ease 0s;
        -ms-transition: none 0s ease 0s;
        -webkit-transition: none 0s ease 0s
    }

    .nav-tabs.nav-tabs-solid {
        background-color: #fafafa;
        border: 0
    }

    .nav-tabs.nav-tabs-solid > li {
        margin-bottom: 0
    }

    .nav-tabs.nav-tabs-solid > li > a {
        border-color: transparent
    }

    .nav-tabs.nav-tabs-solid > li > a:hover, .nav-tabs.nav-tabs-solid > li > a:focus {
        background-color: #f5f5f5
    }

    .nav-tabs.nav-tabs-solid > .open:not(.active) > a {
        background-color: #f5f5f5;
        border-color: transparent
    }

    .nav-tabs-justified.nav-tabs-top {
        border-bottom: 1px solid #ddd
    }

    .nav-tabs-justified.nav-tabs-top > li > a, .nav-tabs-justified.nav-tabs-top > li > a:hover, .nav-tabs-justified.nav-tabs-top > li > a:focus {
        border-width: 2px 0 0
    }
}

@media only screen and (max-width: 1199.98px) {
    .invoices-view {
        width: 90%;
        float: right
    }

    .page-title {
        color: #000;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 30px
    }

    .crypto-exchange .card-title h4 {
        margin-bottom: 0;
        font-size: 18px
    }

    .exchange-form .form-group {
        margin-bottom: 15px
    }

    .exchange-btn, .currency-list {
        display: none
    }

    .wallet-list span {
        font-size: 14px;
        min-width: 160px
    }

    .dash-widget-blk ul {
        flex-wrap: wrap
    }

    .dash-widget-blk ul li:last-child {
        margin-right: 10px
    }

    .bit-coin-blk {
        padding: 15px
    }

    .bit-coin-blk li h4 {
        font-size: 12px
    }

    .bit-coin-blk li {
        border-right: 0;
        padding-right: 6px
    }

    .bit-coin-blk li h3 {
        font-size: 16px
    }

    .flow-time-blk {
        margin-bottom: 10px
    }

    .buy-crypto-group {
        padding: 15px
    }

    .status-blk {
        margin-bottom: 10px
    }

    .sort-by {
        max-width: 200px
    }

    .confirm-btn-grp {
        flex-wrap: wrap
    }

    .confirm-btn-grp .btn-primary {
        margin-bottom: 10px
    }

    .sell-buy-list ul {
        flex-wrap: wrap;
        padding-bottom: 10px;
        margin-bottom: 10px
    }

    .sell-buy-list ul li {
        margin-bottom: 10px
    }

    .card.chat-box {
        margin-bottom: 20px !important
    }

    .meeting .join-video {
        height: 320px
    }

    .join-video.single-user {
        height: auto
    }

    .sidebar {
        top: 80px;
        background: #f0f5ff
    }

    .ex-forms {
        margin-bottom: 20px !important
    }
}

@media only screen and (max-width: 991.98px) {
    .profile-rightbar {
        display: inline-block !important
    }

    .chat-profile-view {
        display: none;
        -webkit-transition: all .4s ease;
        -moz-transition: all .4s ease;
        transition: all .4s ease;
        right: 0;
        transform: translateX(0px);
        z-index: 1041;
        width: 300px;
        position: fixed;
        margin-right: -300px;
        display: table-cell;
        top: 0;
        padding-bottom: 60px;
        margin-top: 50px
    }

    .message-view.chat-view {
        width: 100%
    }

    .slide-nav .page-wrapper {
        left: 225px;
        margin-left: 0
    }

    .chat-main-row {
        left: 0
    }

    .header .header-left {
        position: absolute;
        width: 100%
    }

    .mobile_btn {
        display: block
    }

    .page-title-box {
        display: none
    }

    .sidebar {
        margin-left: -285px;
        width: 230px
    }

    .page-wrapper {
        margin-left: 0;
        padding-left: 0;
        padding-right: 0;
        background-attachment: scroll
    }

    .slide-nav .sidebar {
        margin-left: 0
    }

    a.mobile_btn {
        color: #171717;
        font-size: 24px;
        height: 60px;
        left: 0;
        line-height: 60px;
        padding: 0 20px;
        position: absolute;
        top: 0;
        width: 60px;
        z-index: 10
    }

    .slide-nav .page-wrapper {
        left: 225px;
        margin-left: 0
    }

    .invoices-view {
        width: 100%;
        float: none
    }

    #toggle_btn {
        display: none
    }

    .sidebar {
        -webkit-transition: all .4s ease;
        -moz-transition: all .4s ease;
        transition: all .4s ease
    }

    .page-wrapper {
        -webkit-transition: all .4s ease;
        -moz-transition: all .4s ease;
        transition: all .4s ease;
        background-attachment: initial
    }

    .sidebar {
        top: 80px;
        background: #f0f5ff
    }

    .logo {
        justify-content: left;
        -webkit-justify-content: left;
        -ms-flex-pack: left;
        margin-left: 35px;
    }

    .header-nav-blk, .top-liv-search, .live-grp, .exchange-btn, .currency-list {
        display: none
    }

    .wallet-list span {
        font-size: 15px;
        min-width: 160px
    }

    .dash-widget-blk ul {
        flex-wrap: wrap
    }

    .premium-box {
        width: 60px;
        height: 60px;
        border-radius: 15px
    }

    .dash-widget-blk ul li:last-child {
        margin-right: 10px
    }

    .bit-coin-blk li h4 {
        font-size: 12px
    }

    .bit-coin-blk li {
        border-right: 0;
        padding-right: 6px
    }

    .bit-coin-blk li h3 {
        font-size: 16px
    }

    .flow-time-blk {
        margin-bottom: 10px
    }

    .buy-form-crypto .date-border {
        margin-bottom: 10px
    }

    .represh-btn {
        margin-bottom: 10px;
        margin-top: 10px
    }

    .confirm-btn-grp {
        flex-wrap: wrap
    }

    .confirm-btn-grp .btn-primary {
        margin-bottom: 10px
    }

    .sell-buy-list ul {
        flex-wrap: wrap;
        padding-bottom: 10px;
        margin-bottom: 10px
    }

    .sell-buy-list ul li {
        margin-bottom: 10px
    }

    .meeting .join-video {
        height: 320px
    }

    .join-video.single-user {
        height: auto
    }

    .watch-head h4 {
        margin-bottom: 15px
    }
}

@media only screen and (max-width: 767.98px) {
    .profile-info-left {
        border-right: none;
        border-bottom: 2px dashed #ccc;
        margin-bottom: 20px;
        padding-bottom: 20px
    }

    .navbar-nav .open .dropdown-menu {
        float: left;
        position: absolute
    }

    .notifications {
        right: -48px
    }

    .notifications:before, .notifications:after {
        right: 60px
    }

    .modal-body.card-box {
        background-color: #fff;
        border: none;
        border-radius: inherit;
        box-shadow: unset;
        margin-bottom: 0;
        padding: 15px
    }

    .roles-menu {
        margin-bottom: 20px
    }

    .left-action {
        text-align: center;
        margin-bottom: 15px
    }

    .right-action {
        text-align: center
    }

    .top-action-left .float-left {
        float: none !important
    }

    .top-action-left .btn-group {
        margin-bottom: 15px
    }

    .top-action-right {
        text-align: center
    }

    .top-action-right a.btn.btn-white {
        margin-bottom: 15px
    }

    .mail-sent-time {
        float: left;
        margin-top: 10px;
        width: 100%
    }

    .nav-tabs.nav-justified {
        border-bottom: 1px solid #ddd
    }

    .nav-tabs.nav-justified > li > a.active, .nav-tabs.nav-justified > li > a.active:hover, .nav-tabs.nav-justified > li > a.active:focus {
        border-color: transparent transparent transparent #444;
        border-left-width: 2px
    }

    .nav-tabs {
        border-bottom: 0;
        position: relative;
        background-color: #fff;
        padding: 5px 0;
        border: 1px solid #ddd;
        border-radius: 3px
    }

    .nav-tabs .nav-item {
        margin-bottom: 0
    }

    .nav-tabs > li > a {
        border-width: 2px;
        border-left-color: transparent
    }

    .nav-tabs .nav-link {
        border-width: 2px
    }

    .nav-tabs > li > a:hover, .nav-tabs > li > a:focus {
        background-color: #fafafa
    }

    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active, .nav-tabs > li > a.active, .nav-tabs > li > a.active:hover, .nav-tabs > li > a.active:focus {
        background-color: #f5f5f5;
        border-color: transparent transparent transparent #444;
        border-left-width: 2px
    }

    .nav-tabs > li.open:not(.active) > a, .nav-tabs > li.open:not(.active) > a:hover, .nav-tabs > li.open:not(.active) > a:focus {
        background-color: #fafafa
    }

    .nav-tabs.nav-tabs-solid {
        padding: 5px
    }

    .nav-tabs.nav-tabs-solid.nav-tabs-rounded {
        border-radius: 5px
    }

    .nav-tabs.nav-tabs-solid > li > a {
        border-left-width: 0 !important
    }

    .nav-tabs-justified {
        border-bottom: 1px solid #ddd
    }

    .nav-tabs-justified > li > a.active, .nav-tabs-justified > li > a.active:hover, .nav-tabs-justified > li > a.active:focus {
        border-width: 0 0 0 2px;
        border-left-color: #444
    }

    h1 {
        font-size: 2em
    }

    h2 {
        font-size: 1.5em
    }

    h3 {
        font-size: 1.17em
    }

    h4 {
        font-size: 1.12em
    }

    h5 {
        font-size: .83em
    }

    h6 {
        font-size: .75em
    }

    .card-title {
        font-size: 1.12em;
        font-weight: 700
    }

    .blog-view .blog-title {
        font-size: 24px
    }

    .widget h3 {
        font-size: 18px
    }

    .my-video ul li {
        width: 80px
    }

    .voice-call-avatar .call-avatar {
        width: 120px;
        height: 120px
    }

    .table-responsive {
        display: block;
        width: 100%;
        overflow-x: auto;
        white-space: nowrap;
        -ms-overflow-style: -ms-autohiding-scrollbar
    }

    .header .has-arrow .dropdown-toggle > span:nth-child(2) {
        display: none
    }

    .header .has-arrow .dropdown-toggle:after {
        display: none
    }

    .btn-white {
        margin: 0
    }

    .logo {
        justify-content: left;
        -webkit-justify-content: left;
        -ms-flex-pack: left;
        margin-left: 35px;
        max-width: 150px;
    }

    .header-nav-blk {
        display: none
    }

    .header {
        height: 60px
    }

    .welcome-wallet {
        flex-wrap: wrap
    }

    .wallet-list {
        width: 100%
    }

    .wallet-list span {
        width: 100%;
        margin: 10px 0
    }

    .bookingrange {
        width: 100%
    }

    .dash-widget-blk ul {
        flex-wrap: wrap;
        justify-content: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center
    }

    .dash-widget-blk ul li {
        margin: 0 5px 10px 0
    }

    .dash-widget-blk ul li:last-child {
        margin-right: 5px
    }

    .bit-coin-blk {
        flex-wrap: wrap
    }

    .bit-coin-blk li {
        border-right: 0 solid #efecf1;
        padding-right: 15px;
        margin-bottom: 10px
    }

    .flow-time-blk {
        display: block
    }

    .flow-times {
        margin-bottom: 15px
    }

    .flow-times h5 {
        margin-bottom: 0
    }

    .time-variant li, .time-range .down-range {
        width: 30px;
        height: 30px;
        flex: none
    }

    .time-variant li a {
        font-size: 12px
    }

    .buy-sell li {
        width: 100%
    }

    .represh-btn {
        margin-bottom: 10px !important;
        margin-top: 10px
    }

    .premium-name-blk h3 {
        font-size: 16px
    }

    .premium-box {
        width: 55px;
        height: 55px;
        border-radius: 12px
    }

    .bit-coin-blk li h4 {
        font-size: 14px;
        margin-bottom: 3px
    }

    .bit-coin-blk li h3 {
        font-size: 18px;
        margin-bottom: 3px
    }

    .submit-btn {
        font-size: 14px;
        padding: 6px 20px
    }

    .credit-balance h2 {
        font-size: 18px
    }

    .credit-btn .btn-primary {
        min-width: 130px;
        padding: 6px 15px
    }

    .buy-crpto-btn .btn-primary {
        min-width: 100%
    }

    .buy-form-crypto .date-border {
        margin-bottom: 10px
    }

    .market-cap-list {
        margin: 10px 0
    }

    .transaction-confirmation ul {
        display: block
    }

    .transaction-confirmation ul li {
        padding: 0
    }

    .transaction-btns .btn-primary {
        font-size: 15px;
        padding: 7px 15px
    }

    .key-trans-list, .confirm-btn-grp {
        display: block
    }

    .confirm-btn-grp .btn-primary {
        width: 100%;
        margin-bottom: 10px
    }

    .welcome-wallet .bookingrange {
        margin: 0 !important
    }

    .wel-come-name {
        margin-bottom: 15px
    }

    .transfer-quick {
        display: block
    }

    .transfer-quick .quick-cont {
        margin-bottom: 10px
    }

    .permission-role ul, .configure-setting ul {
        display: block
    }

    .configure-setting ul li {
        width: 100%;
        margin-bottom: 15px
    }

    .configure-setting ul li:first-child a {
        margin-right: 0 !important
    }

    .permission-role ul li {
        margin-right: 0;
        margin-bottom: 15px;
        min-width: 100%
    }

    .permission-role ul li:last-child {
        margin-bottom: 0
    }

    .chat-search-group {
        display: block
    }

    .chat-search-list ul {
        margin-top: 10px
    }

    .chat-search-list ul li:first-child {
        margin-left: 0
    }

    .avatar {
        width: 30px;
        height: 30px
    }

    .meeting .join-video {
        height: 116px
    }

    .join-video.single-user {
        height: auto
    }

    .join-video.single-user {
        width: 100%
    }

    .meet-icons .meet-items .meet-item > a {
        width: 35px;
        height: 35px
    }

    .meet-icons .meet-items .meet-item, .action-items .action-item {
        margin-right: 5px
    }

    .end-call-chat .btn-primary {
        min-width: 90px;
        margin: 0 5px;
        padding: 6px 13px;
        font-size: 14px
    }

    .change-user {
        padding: 12px 18px;
        font-size: 14px;
        min-width: 132px
    }

    .mailview-header, .send-user.send-user-name h4 {
        display: block
    }

    .send-user.send-user-name h4 span {
        margin: 0
    }

    .mail-attach h2 {
        font-size: 16px
    }

    .mail-attach .msg-sub-list li {
        margin-bottom: 10px
    }

    .forward-send .btn-primary {
        margin-bottom: 10px;
        width: 100%
    }

    .watch-head h4 {
        margin-bottom: 15px
    }

    .sidebar {
        top: 50px;
        background: #f0f5ff
    }

    a.mobile_btn {
        height: 50px;
        line-height: 50px
    }

    .trad-book-grp {
        margin-left: 0 !important
    }

    .trad-book-grp ul {
        margin-bottom: 10px
    }

    .btn-down {
        margin-top: 10px
    }

    .inputgroups input[type=text] {
        padding-right: 125px
    }

    .mailview-inner p {
        font-size: 14px
    }

    .sender-info {
        margin-bottom: 10px
    }
}

@media only screen and (max-width: 575.98px) {
    .contact-cat {
        padding-right: 70px
    }

    .user-menu {
        display: none
    }

    .mobile-user-menu {
        display: block
    }

    .search-box {
        display: none
    }

    .filter-row {
        margin-bottom: 15px
    }

    .page-wrapper > .content {
        padding: 15px
    }

    .profile-view .profile-basic {
        margin-left: 0
    }

    .profile-img-wrap {
        position: relative;
        margin: 0 auto
    }

    .profile-view .profile-img-wrap {
        margin: 0 auto 10px;
        height: 100px;
        width: 100px
    }

    .profile-view .profile-img {
        height: 100px;
        width: 100px
    }

    .profile-view .profile-img .avatar {
        font-size: 24px;
        height: 100px;
        line-height: 100px;
        margin: 0;
        width: 100px
    }

    .profile-info-left {
        text-align: center
    }

    .profile-basic {
        margin-left: 0;
        margin-top: 15px
    }

    .page-title {
        font-size: 15px
    }

    .crypto-exchange .card-title h4 {
        font-size: 15px
    }

    .fc-toolbar .fc-right {
        display: inline-block;
        float: none;
        margin: 10px auto 0;
        width: 200px;
        clear: both
    }

    .fc-toolbar .fc-left {
        float: none;
        margin: 0 auto;
        width: 200px
    }

    .upload-text {
        font-size: 18px
    }

    .call-duration {
        display: block;
        margin-top: 0;
        margin-bottom: 10px;
        position: inherit
    }

    .end-call {
        margin-top: 10px;
        position: inherit
    }

    .chat-right .chat-content {
        max-width: 90%
    }

    .chat-right .chat-body {
        padding-left: 0;
        padding-right: 0
    }

    .chat-left .chat-content {
        max-width: 90%
    }

    .chat-left .chat-body {
        padding-left: 0;
        padding-right: 0
    }

    .chat-avatar .avatar {
        height: 20px;
        line-height: 20px;
        width: 20px
    }

    .account-box {
        width: 100%;
        margin-bottom: 20px
    }

    .content {
        padding: 0 1px
    }

    .custom-menu.navbar-nav > li > a {
        margin-left: 10px
    }

    .error-box h1 {
        font-size: 50px
    }

    .submit-btn {
        min-width: 150px
    }

    .ui-kit-wrapper button {
        margin-bottom: 5px
    }

    .pagination > li > a, .pagination > li > span {
        padding: .5rem !important
    }
}

@media only screen and (max-width: 479px) {
    .compose-btn button {
        padding: .365rem .5rem
    }

    .attachments li {
        float: none;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 10px
    }

    .buy-form {
        padding: 25px
    }

    .page-title {
        padding-top: 0;
        margin-bottom: 15px
    }
}



/*mes styles*/
.header-left{
    margin-right: 15px;
}

.mini-sidebar .sidebar {
    background: #eef4ff;
}

.mini-sidebar .page-wrapper {
    padding-left: 37px;
}

.mini-sidebar .menu-title {
    display: none;
}

.page-wrapper{
    padding-right: 20px;
}

.mini-sidebar .track-portfolio, .mini-sidebar .need-btn, .mini-sidebar .logout-btn{
    display: none;
}

.mini-sidebar .fav-image{
    display: block !important;
}

.dropdown-menu.show{
    transform: translate3d(9px, 52px, 0px) !important;
}

.notification-box{
    background: white;
}

table.dataTable thead>tr>th.sorting_asc, table.dataTable thead>tr>th.sorting_desc, table.dataTable thead>tr>th.sorting, table.dataTable thead>tr>td.sorting_asc, table.dataTable thead>tr>td.sorting_desc, table.dataTable thead>tr>td.sorting{
    font-size: 15px;
}

table tr{
    font-size: 15px;
}

.icone-detail{
    width: 20px;
}
.classification-label{
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 14px;
}

/* Barre de défilement verticale */
::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background-color: #737473FF;
}

::-webkit-scrollbar-track-horizontal {
    background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb-horizontal {
    background-color: #737473FF;
}

.btn-outline-paris{
    border-color: #BA99643A;
    color: #ba9964;
}

.btn-outline-paris:hover, .btn-outline-paris.active{
    background: #ba9964;
    border-color: #ffffff;
    color: #ffffff;
}

.btn-outline-medical{
    border-color: rgb(58, 142, 186)3A;
    color: rgb(58, 142, 186);
}

.btn-outline-medical:hover, .btn-outline-medical.active{
    background: rgb(58, 142, 186);
    border-color: #ffffff;
    color: #ffffff;
}

.btn-outline-service{
    border-color: #0987213A;
    color: #098721;
}

.btn-outline-service:hover, .btn-outline-service.active{
    background: #098721;
    border-color: #ffffff;
    color: #ffffff;
}

.btn-outline-admin{
    border-color: #0000003A;
    color: #000000;
}

.btn-outline-admin:hover, .btn-outline-admin.active{
    background: #000000;
    border-color: #ffffff;
    color: #ffffff;
}

.btn-outline-all{
    border-color: #FA03033A;
    color: #FA03033A;
}

.btn-outline-all:hover, .btn-outline-all.active{
    background: #FA0303;
    border-color: #ffffff;
    color: #ffffff;
}

.table.custom-table > tbody > tr > td, .table.custom-table > tbody > tr > th, .table.custom-table > tfoot > tr > td, .table.custom-table > tfoot > tr > th, .table.custom-table > thead > tr > td, .table.custom-table > thead > tr > th{
    white-space: pre-wrap;
}

.paris{
    background: #BC9B67;
    color: #000;
}

.medical{
    background: #3A8EBA;
    color: #000;
}

.service{
    background: #098721;
    color: #000;
}


::-webkit-scrollbar:horizontal{
    height: 8px;
    background-color: red;
}
::-webkit-scrollbar-thumb:horizontal{
    background: rgba(115, 116, 115, 0.22);
    border-radius: 10px;

}

.interface-icon{
    display: none;
}

@media screen and (max-width: 1111px) {
    .date-sys{
        display: none;
    }

    .heure-sys{
        display: none;
    }

    .interface-icon{
        display: block;
    }

    .interface-text{
        display: none;
    }
}

@media screen and (max-width: 658px) {
    .user-names{
        display: none;
    }
}

@media screen and (max-width: 992px) {
    .sidebar + .page-wrapper.bg-wrapper{
        margin-left: 20px;
    }
    .sidebar.opened + .page-wrapper.bg-wrapper{
        margin-left: 40px;
    }

    .user-img{
        margin-right: 15px;
    }
}

@media screen and (max-width: 490px) {
    .logo{
        display: none;
    }
}

@media screen and (max-width: 340px) {
    .user-menu .nav-item{
        margin: 2px !important;
    }
    .user-menu .nav-item button{
        margin: 2px !important;
    }
}

@media screen and (max-width: 305px) {
    .user-menu .nav-item button{
        display: none !important;
    }
}

