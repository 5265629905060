.dots {
    width: 40px;
    height: 19.2px;
    background: radial-gradient(circle closest-side, #474bff 90%, #0000) 0% 50%,
        radial-gradient(circle closest-side, #474bff 90%, #0000) 50% 50%,
        radial-gradient(circle closest-side, #474bff 90%, #0000) 100% 50%;
    background-size: calc(100%/3) 9.6px;
    background-repeat: no-repeat;
    animation: dots-7ar3yq 1s infinite linear;
}

@keyframes dots-7ar3yq {
    20% {
        background-position: 0% 0%, 50% 50%, 100% 50%;
    }

    40% {
        background-position: 0% 100%, 50% 0%, 100% 50%;
    }

    60% {
        background-position: 0% 50%, 50% 100%, 100% 0%;
    }

    80% {
        background-position: 0% 50%, 50% 50%, 100% 100%;
    }
}