.cookies-card {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #ffffff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  
    /* Set initial width for larger screens */
    width: 25%;
  
    /* Media query for tablets */
    @media (max-width: 768px) {
      width: 50%;
    }
  
    /* Media query for mobile devices */
    @media (max-width: 480px) {
      width: 100%;
      bottom: 0; /* Adjust position for full width */
      border-radius: 0; /* No border radius for full width */
    }
  }

.cookies-card button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
}
