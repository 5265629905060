
#preview-container {
  margin: 0!important;
  padding: 0!important;
  text-indent: 0!important;
  font-family: "Calibri", sans-serif!important;
}

.text-center {
  text-align: center;
}
.font-bold {
  font-weight: bold !important;
}
.border {
  border-width: 1px;
}
.relative {
  position: relative;
}
.uppercase {
  text-transform: uppercase;
}

.text-center {
text-align: center;
}
.text-left {
text-align: left;
}
.text-right {
text-align: right;
}

.s1 {
  color: black!important;
  font-family: "Calibri", sans-serif!important;
  font-style: normal!important;
  font-weight: normal!important;
  text-decoration: none!important;
  font-size: 7pt!important;
  margin-bottom: 0;
}

.s2 {
  color: black!important;
  font-family: "Calibri", sans-serif!important;
  font-style: normal!important;
  font-weight: normal!important;
  text-decoration: none!important;
  font-size: 5pt!important;
}

.s3 {
  color: black!important;
  font-family: "Calibri", sans-serif!important;
  font-style: normal!important;
  font-weight: normal!important;
  text-decoration: none!important;
  font-size: 10pt!important;
}

.s4 {
  color: black!important;
  font-family: "Calibri", sans-serif!important;
  font-style: normal!important;
  font-weight: normal!important;
  text-decoration: none!important;
  font-size: 9pt!important;
}

#preview-container > h2 {
  color: black!important;
  font-family: "Calibri", sans-serif!important;
  font-style: normal!important;
  font-weight: bold!important;
  text-decoration: none!important;
  font-size: 9pt!important;
}

#preview-container > p, #preview-container .font__sm {
  color: black!important;
  font-family: "Calibri", sans-serif!important;
  font-style: normal!important;
  font-weight: normal!important;
  text-decoration: none!important;
  font-size: 8pt!important;
  margin: 0pt!important;
  margin-bottom: 0px!important;
}

#preview-container > h1 {
  color: black!important;
  font-family: "Calibri", sans-serif!important;
  font-style: normal!important;
  font-weight: bold!important;
  text-decoration: none!important;
  font-size: 12pt!important;
}

#preview-container > tr {
  border: 0!important;
}

#preview-container .rounded-table {
  border-radius: 8px!important;
  overflow: hidden!important;
}

#preview-container .rounded-table table {
  border-collapse: separate!important; 
  border-spacing: 0!important;
  overflow: hidden!important;
}

 #preview-container .rounded-table tr:first-child th:first-child,  #preview-container .rounded-table tr:first-child td:first-child {
  border-top-left-radius: 8px!important;
}

 #preview-container .rounded-table tr:first-child th:last-child, #preview-container .rounded-table tr:first-child td:last-child {
  border-top-right-radius: 8px!important;
}
#preview-container .rounded-table tr:last-child td:first-child {
  border-bottom-left-radius: 8px!important;
}

#preview-container .rounded-table tr:last-child td:last-child {
  border-bottom-right-radius: 8px!important;
} 

#preview-container .rounded-table td:not(td:first-child), #preview-container .rounded-table th:not(th:first-child) {
  border-left-width: 0!important;
  border-bottom-width: 0!important;
}

#preview-container .rounded-table td:first-child, #preview-container .rounded-table th:first-child {
  border-bottom-width: 0!important;
}

#preview-container .rounded-table tr:last-child td, #preview-container .rounded-table tr:last-child td {
  border-bottom-width: 1px!important;
}

.s-base {
  color: black!important;
  text-decoration: none!important;
  font-size: 8pt!important;
  margin-bottom: 0;
}

.s5 {
  color: black!important;
  font-family: "Calibri", sans-serif!important;
  font-style: normal!important;
  font-weight: normal!important;
  text-decoration: none!important;
  font-size: 8pt!important;
  margin-bottom: 0;
}

.s6 {
  color: black!important;
  font-family: "Calibri", sans-serif!important;
  font-style: normal!important;
  font-weight: normal!important;
  text-decoration: none!important;
  font-size: 5.5pt!important;
}

.s7 {
  color: black!important;
  font-family: "Calibri", sans-serif!important;
  font-style: normal!important;
  font-weight: normal!important;
  text-decoration: none!important;
  font-size: 7.5pt!important;
}

.s8 {
  color: black!important;
  font-family: "Calibri", sans-serif!important;
  font-style: normal!important;
  font-weight: normal!important;
  text-decoration: none!important;
  font-size: 6.5pt!important;
  margin-bottom: 0;
}

.s9 {
  color: black!important;
  font-family: "Calibri", sans-serif!important;
  font-style: normal!important;
  font-weight: normal!important;
  text-decoration: none!important;
  font-size: 7pt!important;
  margin-bottom: 0;
}

.s10 {
  color: #8c8c8c!important;
  font-family: "Calibri", sans-serif!important;
  font-style: normal!important;
  font-weight: normal!important;
  text-decoration: none!important;
  font-size: 50pt!important;
  vertical-align: -11pt!important;
}

.s11 {
  color: #8c8c8c!important;
  font-family: "Calibri", sans-serif!important;
  font-style: normal!important;
  font-weight: normal!important;
  text-decoration: none!important;
  font-size: 50pt!important;
  vertical-align: -10pt!important;
}

.s12 {
  color: #8c8c8c!important;
  font-family: "Calibri", sans-serif!important;
  font-style: normal!important;
  font-weight: normal!important;
  text-decoration: none!important;
  font-size: 50pt!important;
}

.s13 {
  color: #8c8c8c!important;
  font-family: "Calibri", sans-serif!important;
  font-style: normal!important;
  font-weight: normal!important;
  text-decoration: none!important;
  font-size: 50pt!important;
  vertical-align: -37pt!important;
}

.s14 {
  color: #8c8c8c!important;
  font-family: "Calibri", sans-serif!important;
  font-style: normal!important;
  font-weight: normal!important;
  text-decoration: none!important;
  font-size: 50pt!important;
  vertical-align: -34pt!important;
}

.s15 {
  color: #8c8c8c!important;
  font-family: "Calibri", sans-serif!important;
  font-style: normal!important;
  font-weight: normal!important;
  text-decoration: none!important;
  font-size: 50pt!important;
  vertical-align: -35pt!important;
}

.s16 {
  color: black!important;
  font-family: "Calibri", sans-serif!important;
  font-style: normal!important;
  font-weight: normal!important;
  text-decoration: none!important;
  font-size: 6pt!important;
}

.s17 {
  color: #8c8c8c!important;
  font-family: "Calibri", sans-serif!important;
  font-style: normal!important;
  font-weight: normal!important;
  text-decoration: none!important;
  font-size: 50pt!important;
  vertical-align: -37pt!important;
}

#preview-container > h3 {
  color: black!important;
  font-family: "Calibri", sans-serif!important;
  font-style: normal!important;
  font-weight: bold!important;
  text-decoration: none!important;
  font-size: 8pt!important;
}

#preview-container > .a,
#preview-container > a {
  color: black!important;
  font-family: "Calibri", sans-serif!important;
  font-style: normal!important;
  font-weight: bold!important;
  text-decoration: none!important;
  font-size: 8pt!important;
}

.s18 {
  color: #8c8c8c!important;
  font-family: "Calibri", sans-serif!important;
  font-style: normal!important;
  font-weight: normal!important;
  text-decoration: none!important;
  font-size: 50pt!important;
}

.s19 {
  color: black!important;
  font-family: "Calibri", sans-serif!important;
  font-style: normal!important;
  font-weight: normal!important;
  text-decoration: none!important;
  font-size: 9pt!important;
}

.s20 {
  color: black!important;
  font-family: "Calibri", sans-serif!important;
  font-style: normal!important;
  font-weight: bold!important;
  text-decoration: none!important;
  font-size: 10pt!important;
}

.s21 {
  color: black!important;
  font-family: "Calibri", sans-serif!important;
  font-style: normal!important;
  font-weight: normal!important;
  text-decoration: none!important;
  font-size: 7pt!important;
}

#preview-container > table,
#preview-container > tbody {
  vertical-align: top!important;
  overflow: visible!important;
}

#preview-container > table.copy {
  position: relative;
      
}

#preview-container > table.copy::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  rotate: 5deg;
  content: "Exnet";
  font-size: 50pt;
  color: #000;
  opacity: 0.2;
}

._third {
  width: 33.3333%!important;
}

#preview-container .__header {
  width: 100%;
}

#preview-container .__header .__1__4 {
  width: 40%!important;
}

#preview-container .__header .__1__35 {
  width: 35%!important;
}

#preview-container .__header .__1__25 {
  width: 25%!important;
}

#preview-container .__1__5 {
  width: 50%!important;
}

#preview-container #header .header-item {
  float: left;
  box-sizing: border-box;
}

#preview-container #header .left-side {
  width: 40%; /* Subtracting margin and padding */
}


#preview-container #header .center-side {
  width: calc(20% - 10px); /* Subtracting margin and padding */
  margin: 0px 5px;
}


#preview-container #header .right-side {
  width: 40%; /* Subtracting margin and padding */
}

 .metadata-table {
  float: left;
  width: calc(33.33%); /* Subtracting margin and padding */
  /* margin: 10px;
  padding: 10px; 
  border: 1px solid #ccc;*/
  box-sizing: border-box;
}