.error-box h1 {
    font-size: 200px !important;
    background-image: linear-gradient(to right, #234ce3 0%, #6713d2 100%);
    background: #3264f5;
    background: linear-gradient(to right, #234ce3 2%, #6713d2 82%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#cc208e', endColorstr='#6713d2', GradientType=1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

h3, .h3 {
    font-size: 1.5rem !important;
}
.error-box p {
    margin-bottom: 30px;
    font-size: 1rem !important;
}